import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { addNotificationTopic, getNotificationTopics, resetNotificationSlice } from '../../../slices/notification_slice';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';


export default function AddEditTopicDialog({editData,onHide}) {

    const dispatch = useDispatch();
    const {addTopicLoading, addTopicData,eidtTopicLoading} = useSelector((state)=>state.notifications);




    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Name is required."),
        description: Yup.string().required("Description is required."),
     
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            title: "",
            description: "",
         
        },

        onSubmit: async (data) => {
            console.log(data);

            if(editData==null){
                dispatch(addNotificationTopic(data));

            }else{
           

            }
        
        },
    });

    useEffect(() => {
        if(addTopicData){
            onHide();
            dispatch(getNotificationTopics())
        }
    
      return () => {
        dispatch(resetNotificationSlice())
      }
    }, [addTopicData])
    

    return (
        <div>
        <form onSubmit={formik.handleSubmit}>
         <div className="col-12 md:col-6\8">
                <div className=" p-fluid">

                    <div className="field">
                        <label htmlFor="name1">Name</label>
                        <InputText id="title" name='title' value={formik.values.name} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('title') })} />
                        {getFormErrorMessage('title')}
                    </div>
                    <div className="field">
                        <label htmlFor="name1">Description</label>
                        <InputText id="description" name='description' value={formik.values.description} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('description') })} />
                        {getFormErrorMessage('description')}
                    </div>

                    <Button loading={editData==null? addTopicLoading:eidtTopicLoading} type='submit' label='Submit' className='p-button-success' />

                </div>
            </div>
         </form>


        </div>
    )
}
