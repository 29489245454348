import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { ToastContainer } from "react-toastify";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";

import Dashboard from "./components/Dashboard";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import "./assets/index.css";
import AdminUsers from "./screens/users/pages/admin_users";
import AppUsers from "./screens/users/pages/app_users";
import { useSelector } from "react-redux";
import Login from "./screens/auth/pages/login_screen";
import CentersScreen from "./screens/centers/pages/centers_screen";
import CenterScreen from "./screens/centers/pages/center_screen";
import AllGroomingScreen from "./screens/appointments/pages/all_grooming_screen";
import AllBoardingScreen from "./screens/appointments/pages/all_boarding_screen";
import CenterAppointments from "./screens/centers/pages/center_appointments";
import AddMultipleProfilesScreen from "./screens/petDate/pages/add_multiple_pet_profiles_screen";
import NotificationsScreen from "./screens/notifications/pages/notifications_screen";
import NotificationTopicsScreen from "./screens/notifications/pages/notification_topics_screen";
import DoctorsScreen from "./screens/doctors/doctors_screen";
import doctorsAvailbilityScreen from "./screens/doctors/pages/doctors_availability_screen";
import DoctorSpecializationScreen from "./screens/doctors/pages/specialization_screen";
import DoctorDetailsScreen from "./screens/doctors/pages/doctor_details_screen";
import AddPostsScreen from "./screens/posts/pages/add_posts_screen";
import PostsCategoryScreen from "./screens/posts/pages/posts_category_screen";
import PostsScreen from "./screens/posts/pages/posts_screen";
import EventOrganizersScreen from "./screens/events/screens/event_organizers_screen";
import AllEventsScreen from "./screens/events/screens/all_events_screen";
import EventDetailsScreen from "./screens/events/screens/event_details_screen";
import OrganizerEventsScreen from "./screens/events/screens/organizer_events_screen";
import DoctorAppointmentsScreen from "./screens/doctors/pages/doctor_appointments_screen";
import DoctorsPaymentsScreen from "./screens/doctors/pages/doctors_payments_screen";

const App = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef = useRef();
  const location = useLocation();

  const loginUser = useSelector((state) => state.loginUser);
  const { user } = loginUser;

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [
    {
      label: "Home",
      items: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          to: "/",
        },
      ],
    },
    {
      label: "Users",
      icon: "pi pi-fw pi-sitemap",
      items: [
        { label: "Admin", icon: "pi pi-fw pi-id-card", to: "/api/users/admin" },
        {
          label: "App",
          icon: "pi pi-fw pi-check-square",
          to: "/api/users/app",
        },
      ],
    },

    {
      label: "Management",
      icon: "pi pi-fw pi-sitemap",
      items: [
        { label: "Centers", icon: "pi pi-fw pi-id-card", to: "/api/centers" },
      ],
    },
    {
      label: "Appointments",
      icon: "pi pi-fw pi-sitemap",
      items: [
        {
          label: "Grooming",
          icon: "pi pi-fw pi-id-card",
          to: "/api/appointments/grooming/all",
        },
        {
          label: "Boarding",
          icon: "pi pi-fw pi-id-card",
          to: "/api/appointments/boarding/all",
        },
      ],
    },
    {
      label: "Doctors",
      icon: "pi pi-fw pi-sitemap",
      items: [
        {
          label: "Doctors",
          icon: "pi pi-fw pi-id-card",
          to: "/api/doctors/list",
        },
        {
          label: "Specializations",
          icon: "pi pi-fw pi-id-card",
          to: "/api/doctors/specialization",
        },
      ],
    },
    {
      label: "Events",
      icon: "pi pi-fw pi-sitemap",
      items: [
        {
          label: "Organizers",
          icon: "pi pi-fw pi-id-card",
          to: "/api/events/organziers/list",
        },
  
      ],
    },
    {
      label: "Posts",
      icon: "pi pi-fw pi-sitemap",
      items: [
        { label: "Posts", icon: "pi pi-fw pi-id-card", to: "/api/posts/list" },
        {
          label: "Add posts",
          icon: "pi pi-fw pi-id-card",
          to: "/api/posts/add",
        },
        {
          label: "Categories",
          icon: "pi pi-fw pi-id-card",
          to: "/api/posts/category",
        },
      ],
    },
    {
      label: "Manage",
      icon: "pi pi-fw pi-search",
      items: [
        {
          label: "Pet Date",
          icon: "pi pi-fw pi-bookmark",
          items: [
            {
              label: "Add Multiple Profiles",
              icon: "pi pi-fw pi-bookmark",
              to: "/api/petDate/add/multiple",
            },
          ],
        },
        {
          label: "Notifications",
          icon: "pi pi-fw pi-bell",
          items: [
            {
              label: "Topics",
              icon: "pi pi-fw pi-bell",
              to: "/api/notifications/topics",
            },
            {
              label: "Notifications",
              icon: "pi pi-fw pi-bell",
              to: "/api/notifications",
            },
          ],
        },
      ],
    },

    // {
    //     label: 'Menu Hierarchy', icon: 'pi pi-fw pi-search',
    //     items: [
    //         {
    //             label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
    //             items: [
    //                 {
    //                     label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
    //                     items: [
    //                         { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
    //                         { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
    //                         { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
    //                     ]
    //                 },
    //                 {
    //                     label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
    //                     items: [
    //                         { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' },
    //                         { label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark' }
    //                     ]
    //                 },
    //             ]
    //         },

    //     ]
    // },
    // {
    //     label: 'Get Started',
    //     items: [
    //         { label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => { window.location = "#/documentation" } },
    //         { label: 'View Source', icon: 'pi pi-fw pi-search', command: () => { window.location = "https://github.com/primefaces/sakai-react" } }
    //     ]
    // }
  ];

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  return (
    <>
      <ToastContainer />
      {(user === undefined || user == null) ? (
        <Route path="/" component={Login} />
      ) : (
        <div className={wrapperClass} onClick={onWrapperClick}>
          <Tooltip
            ref={copyTooltipRef}
            target=".block-action-copy"
            position="bottom"
            content="Copied to clipboard"
            event="focus"
          />

          <AppTopbar
            onToggleMenuClick={onToggleMenuClick}
            layoutColorMode={layoutColorMode}
            mobileTopbarMenuActive={mobileTopbarMenuActive}
            onMobileTopbarMenuClick={onMobileTopbarMenuClick}
            onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
          />

          <div className="layout-sidebar" onClick={onSidebarClick}>
            <AppMenu
              model={menu}
              onMenuItemClick={onMenuItemClick}
              layoutColorMode={layoutColorMode}
            />
          </div>

          <div className="layout-main-container">
            <div className="layout-main">
              {/* <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} /> */}
              <Route path="/" exact component={Dashboard} />

              {/* users */}
              <Route path="/api/users/admin" component={AdminUsers} />
              <Route path="/api/users/app" component={AppUsers} />
              {/* centers */}
              <Route exact path="/api/centers" component={CentersScreen} />
              <Route exact path="/api/centers/:id" component={CenterScreen} />
              <Route
                exact
                path="/api/centers/appointments/:id"
                component={CenterAppointments}
              />

              {/* appointments */}
              <Route
                exact
                path="/api/appointments/grooming/all"
                component={AllGroomingScreen}
              />
              <Route
                exact
                path="/api/appointments/boarding/all"
                component={AllBoardingScreen}
              />

              {/* notificaitions */}
              <Route
                exact
                path="/api/notifications"
                component={NotificationsScreen}
              />
              <Route
                exact
                path="/api/notifications/topics"
                component={NotificationTopicsScreen}
              />
              {/* doctors */}
              <Route exact path="/api/doctors/list" component={DoctorsScreen} />
              <Route
                exact
                path="/api/doctors/details/:id"
                component={DoctorDetailsScreen}
              />
              <Route
                exact
                path="/api/doctors/appointmetns/:id"
                component={DoctorAppointmentsScreen}
              />
              <Route
                exact
                path="/api/doctors/payments/:id"
                component={DoctorsPaymentsScreen}
              />
              <Route
                exact
                path="/api/doctors/availibility"
                component={doctorsAvailbilityScreen}
              />
              <Route
                exact
                path="/api/doctors/specialization"
                component={DoctorSpecializationScreen}
              />
              <Route exact path="/api/posts/list" component={PostsScreen} />
              <Route exact path="/api/posts/add" component={AddPostsScreen} />
              <Route
                exact
                path="/api/posts/category"
                component={PostsCategoryScreen}
              />

              {/* events */}
              <Route
                exact
                path="/api/events/organziers/list"
                component={EventOrganizersScreen}
              />
              <Route
                exact
                path="/api/events/organziers/events/:id"
                component={OrganizerEventsScreen}
              />
              <Route
                exact
                path="/api/events/list"
                component={AllEventsScreen}
              />
              <Route
                exact
                path="/api/events/:id"
                component={EventDetailsScreen}
              />
              <Route
                exact
                path="/api/events/test2"
                component={EventDetailsScreen}
              />

              <Route
                path="/api/petDate/add/multiple"
                component={AddMultipleProfilesScreen}
              />
            </div>

            <AppFooter layoutColorMode={layoutColorMode} />
          </div>

          <AppConfig
            rippleEffect={ripple}
            onRippleEffect={onRipple}
            inputStyle={inputStyle}
            onInputStyleChange={onInputStyleChange}
            layoutMode={layoutMode}
            onLayoutModeChange={onLayoutModeChange}
            layoutColorMode={layoutColorMode}
            onColorModeChange={onColorModeChange}
          />

          <CSSTransition
            classNames="layout-mask"
            timeout={{ enter: 200, exit: 200 }}
            in={mobileMenuActive}
            unmountOnExit
          >
            <div className="layout-mask p-component-overlay"></div>
          </CSSTransition>
        </div>
      )}
    </>
  );
};

export default App;
