import React, { useRef, useEffect } from "react";
import { Button } from "primereact/button";
// import { loginAction } from "../../redux/actions/authAction";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { InputText } from "primereact/inputtext";
import { classNames } from 'primereact/utils';
import { useDispatch, useSelector } from "react-redux";
import { Toast } from 'primereact/toast';
import { loginAdminUser } from "../../../actions/users_actions";


const Login = () => {
    const loginUserReducer=useSelector((state)=>state.loginUser);
    const {loading,error,success}=loginUserReducer;
    const dispatch=useDispatch();

    const toast = useRef(null);

    const validationSchema = Yup.object().shape({
    
        password: Yup.string().required("This field is required.").min(8, 'Minimum length should be 8'),
        email: Yup.string().email("Invalid email address format").required("This field is required."),
        
    });

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema:validationSchema,
        onSubmit: async (data) => {
           
            dispatch(loginAdminUser(data));
            
            console.log(data);
         
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
    if(success!==undefined){
        if(success===false){
            toast.current.show({severity:'error', summary: 'Error Message', detail:error, life: 3000});

        }
    }
    }, [success,error])
    
    return (
        <div className="login_body">
               <Toast ref={toast} />
            {/* <button onClick={testFun}>test</button> */}
            <div align="center" style={{ marginTop: "", marginBottom: "1%" }}>
                <img src='icons/logo.svg' alt="" width={"10%"} />
            </div>
            <div className="flex flex-row justify-content-center">
           <div className="col-6 md:col-6\8">
           <form onSubmit={formik.handleSubmit}>

                            <div className=" p-fluid">

                                <h3>Login</h3>
                                <div className="field">
                                    <label htmlFor="email1">Email</label>
                                    <InputText id="email1" type="email" name='email'
                                     value={formik.values.email} onChange={formik.handleChange}
                                     className={classNames({ 'p-invalid': isFormFieldValid('email') })}
                                    />
                                      {getFormErrorMessage('email')}
                                </div>
                         
                                <div className="field">
                                    <label htmlFor="name1">Password</label>
                                    <InputText id="password" name='password' value={formik.values.password} onChange={formik.handleChange} type="password"  className={classNames({ 'p-invalid': isFormFieldValid('password') })}/>
                                    {getFormErrorMessage('password')}
                                </div>
                              
                               
                                
                                <Button loading={loading} type='submit' label='Submit' className='p-button-success' />

                            </div>
                            </form>

                        </div>
         
               
            </div>
        </div>
    );
};

export default Login;
