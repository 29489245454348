const CryptoJS = require("crypto-js");

const cryptkey = CryptoJS.enc.Utf8.parse("43kl5kl34j5kl34j5lk43nn5k34n5lk34j5kl3j45");

const cryptiv = CryptoJS.enc.Utf8.parse("KL435K3L3JLKJCLJ5CJJCLK5LK6L456Kkjltl4k5j45l3");

// Encryption
// const data = JSON.stringify({ test: "123" });

module.exports = (data) => {
  var encrypt = CryptoJS.AES.encrypt(JSON.stringify(data), cryptkey, {
    iv: cryptiv,
    mode: CryptoJS.mode.CTR,
  });
  const cipher = encrypt.toString();
  return { cipher }
};

// console.log(encrypt.toString());
