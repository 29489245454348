import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetEventDetailsQuery } from '../manager/events_service';
import ImagesCarousel from '../componenets/images_carosel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDate } from '../../../app/services/time_format_service';
import { militaryTimeToLocalHHMM } from '../../../app/services/military_time_service';
import { Button } from 'primereact/button';
import { primeIcons } from '../../../app/services/icon_service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default function EventDetailsScreen() {
    const pdfRef = useRef(null);

    const params = useParams();
    const { id } = params;
    const { data: details, isLoading, error, isError } = useGetEventDetailsQuery(id);



    const generatePDF = () => {
        const pdf = new jsPDF();

        // Add event information
        pdf.text('Event Name: Test Event', 20, 20);

        // Create a table for ticket information
        const table = pdf.autoTable({
            head: [['Ticket No', 'User', 'Contact', 'Pet Name', 'Payment Mode', 'Paid']],
            body: details?.data?.tickets.map((ticket) => [
                ticket.ticketNo,
                `${ticket.user.firstName} ${ticket.user.lastName}`,
                ticket.contact,
                ticket.petName,
                ticket.paymentMode,
                ticket.paid ? 'Yes' : 'No',
            ]),
        });

        // Add the table to the PDF
        pdf.fromHTML(table, 20, 40);

        // Save or open the PDF
        pdf.save('tickets.pdf');
    };

    //   useEffect(() => {
    //     if (pdfRef.current) {
    //       html2canvas(pdfRef.current).then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF();
    //         pdf.addImage(imgData, 'PNG', 0, 0);
    //         pdf.save('tickets.pdf');
    //       });
    //     }
    //   }, [details?.data?.tickets]);


    if (isLoading) {
        return <div className='card'>
            <h3>Loading ...</h3>
        </div>
    }
    if (isError) {
        return <div className='card'>
            <h3>{error}</h3>
        </div>
    }

    console.log(details);
    return (
        <div className='card'>
            <h3>
                {details.data?.title}
            </h3>

            <div>
                <div className='card'>

                    <div>
                        <p>
                            <b>Total Seats: </b> {details.data?.totalSeats}

                        </p>
                        <p>
                            <b>Registratino Starting date: </b> {formatDate(details.data?.registrationOpeningDate)}

                        </p>
                        <p>
                            <b>Registration Ending date: </b> {formatDate(details.data?.registrationClosingDate)}

                        </p>
                        <p>
                            <b>Event Date: </b> {formatDate(details.data?.eventDate)}

                        </p>
                        <p>
                            <b>Event time: </b> {militaryTimeToLocalHHMM(details.data?.eventStartTime)} - {militaryTimeToLocalHHMM(details?.data?.eventEndTime)}

                        </p>
                        <p>
                            <b>Address:</b> {details.data?.location?.state}, {details.data?.location?.city},  {details.data?.location?.address}
                            <a target='_blank' href={`https://maps.google.com/?q=${details.data?.location?.latitude},${details.data?.location?.longitude}`}>
                                <i className={primeIcons['pi-map-marker']} style={{ fontSize: '2rem' }}></i>

                            </a>

                        </p>
                        <p>
                            <b>Fee: </b> {details.data?.fee}

                        </p>

                    </div>
                </div>
            </div>
            <ImagesCarousel images={details.data?.gallery} />

            <h3>
                Tickets
            </h3>
            <button onClick={generatePDF}>Generate PDF</button>
            <div ref={pdfRef}>

                <DataTable value={details?.data?.tickets}>
                    <Column
                        field='ticketNo'
                        header="Ticket No"
                    />

                    <Column
                        field='contact'
                        header="Contact"
                    />

                    <Column
                        field='petName'
                        header="Pet Name"
                    />

                    <Column
                        field='petDOB'
                        header="Pet DOB"
                        body={(row) => formatDate(row?.petDOB)}
                    />
                    <Column
                        field='isPetFriendly'
                        header="Is pet friendly"
                        body={(row) => {
                            if (row?.isPetFriendly) {
                                return <div>Yes</div>
                            } else {
                                return <div>No</div>
                            }
                        }}
                    />


                    <Column
                        field='transactionId'
                        header="Transaction Id"
                    />
                    <Column
                        field='status'
                        header="Status"
                        body={(row) => {
                            return <div>{row.status.toString().toUpperCase()}</div>
                        }}
                    />
                </DataTable>
            </div>


            <h3>
                Reviews
            </h3>


        </div>
    )
}
