import * as centerReducer from './reducers/center_reducers';
import * as userReducers from './reducers/user_reducers';
import * as appointmentReducers from './reducers/appointments_reducers';
import { getDashboardStatsReducer } from './reducers/dashboard_reducer';
import pet_date_slice from './slices/pet_date_slice';
import notification_slice from './slices/notification_slice';
import doctors_slice from './slices/doctors_slice';
import countries_city_slice from './slices/countries_city_slice';
import { doctorsApi } from './screens/doctors/manager/doctors_services';
import { api } from './app/api';
import { configureStore } from '@reduxjs/toolkit';
import { doctorSpecializationApi } from './screens/doctors/manager/specialization_services';


const user = localStorage.getItem("user");


const initialState = {
  loginUser: {
    user: user !== undefined ? JSON.parse(user) : undefined
  }
};


const store = configureStore({

  reducer: {
    adminUsers: userReducers.getAdminUsersReducer,
  roles: userReducers.getRolesReduces,
  addAdminUser: userReducers.addAdminUserReducer,
  editAdminUser: userReducers.editAdminUserReducer,
  appUsers: userReducers.getAppUsersReducer,
  loginUser: userReducers.loginUserReducer,
  getCenters: centerReducer.getCentersReducer,
  addCenter: centerReducer.addCenterReducer,
  editCenter: centerReducer.editCenterReducer,
  editCenterData: centerReducer.editCenterDataReducer,
  centerDetails: centerReducer.getCenterDetailsReducer,
  centerBoardingList: appointmentReducers.getCenterBoardingAppointments,
  centerGroomingList: appointmentReducers.getCenterGroomingAppointments,
  allBoardingList: appointmentReducers.getAllBoardingAppointments,
  allGroomingList: appointmentReducers.getAllGroomingAppointments,
  dashboardData: getDashboardStatsReducer,
  petDate: pet_date_slice,
  notifications: notification_slice,
  doctors: doctors_slice,
  countriesCities: countries_city_slice,
  [doctorsApi.reducerPath]: doctorsApi.reducer,
  [doctorSpecializationApi.reducerPath]: doctorSpecializationApi.reducer,
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware().concat(api.middleware);
  },
  preloadedState:initialState
},
)
export default store;

