import React, { useEffect } from 'react'

import { useGetDoctorDetailsQuery, useUpdateDoctorStatusMutation } from '../manager/doctors_services';
import { Dropdown } from 'primereact/dropdown';
import appService from '../../../app/services/app_service';
export default function DoctorStatus({ id }) {





    const { data: doctor } = useGetDoctorDetailsQuery(id);
    const [updateDoctorStatus, {status: updateStatus,error: updateError}] = useUpdateDoctorStatusMutation();
    const options = [
        {
            name: "Active",
            value: "ACTIVE"
        },
        {
            name: "InActive",
            value: "INACTIVE"
        },
        {
            name: "Block",
            value: "BLOCKED"
        },
    ]

    useEffect(()=>{
        appService.showApiResponseStatus(
            {
                status: updateStatus,
                msg: "Updated successfully!",
                error: updateError,
                successCB: () => {
                   
                }
            }
        );
    },[updateStatus])

    useEffect(() => {
        console.log(doctor);
    }, [doctor])

    const onStatusChange = (e) => {
        updateDoctorStatus({
            "id": id,
            "status": e.target.value === "ACTIVE"? 'active': e.target.value === 'INACTIVE'? 'inActive' : 'blocked'
        });
    }


    return (
        <div className='card'>
            <h4>
                Status
            </h4>

            <div className="col-12 md:col-6\8">
                <div className=" p-fluid">
                    <Dropdown placeholder="Choose status" name="status" options={options} optionLabel={"name"} optionValue={"value"} value={doctor?.data?.status} onChange={onStatusChange} />

                </div>
            </div>



        </div>
    )
}
