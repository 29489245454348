import { InputText } from 'primereact/inputtext'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addAdminUser, editAdminUser, getAdminUsers, getUserRoles } from '../../../actions/users_actions';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { RESET_ADD_ADMIN_USER, RESET_EDIT_ADMIN_USER } from '../../../constants/user_constants';





export default function AddEditAdminUserDialog({ onHide, editUser }) {

    const getRoles = useSelector((state) => state.roles);
    const addAdminUserReducer = useSelector((state) => state.addAdminUser);
    const editAdminReducer = useSelector((state) => state.editAdminUser);
    const { roles, loading } = getRoles;
    const { success: addSuccess, loading: addLoading, error: addUserError } = addAdminUserReducer;
    const { success: editSuccess, loading: editLoading, error: editUserError } = editAdminReducer;
    const dispatch = useDispatch();

    const toast = useRef(null);

    const statusList=[
            {
                'code':'active',
                'name':'active',
            },
            {
                'code':'block',
                'name':'block',
            },
    ];

    const loadEditData = () => {
        console.log(editUser);
        formik.setFieldValue("status", editUser['status']);
        formik.setFieldValue("name", editUser['name']);
        formik.setFieldValue("email", editUser['email']);
        formik.setFieldValue("mobile", editUser['mobile']);
        formik.setFieldValue("role", editUser['role']['_id']);

    }


    useEffect(() => {
 
        if (roles === undefined || roles.length === 0) {
            dispatch(getUserRoles());
        }
        if (editUser !== null) {
            loadEditData();
        }


    }, [dispatch]);



    useEffect(() => {
        console.log(addSuccess);
        console.log(addUserError);

        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.current.show({ severity: 'success', summary: 'Success Message', detail: 'User added successfully', life: 3000 });
                formik.resetForm();
                onHide();
                dispatch(getAdminUsers());


            } else {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: addUserError, life: 3000 });


            }
        }

        if (editSuccess !== undefined) {
            if (editSuccess === true) {
                toast.current.show({ severity: 'success', summary: 'Success Message', detail: 'User updated successfully', life: 3000 });
                formik.resetForm();
                onHide();
                dispatch(getAdminUsers());


            } else {
                toast.current.show({ severity: 'error', summary: 'Success Message', detail: editUserError, life: 3000 });


            }
        }

        return ()=>{
           dispatch({type:RESET_ADD_ADMIN_USER});
           dispatch({type:RESET_EDIT_ADMIN_USER});
        }


    }, [addSuccess, addUserError,editSuccess,editUserError]);
 

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required."),
        password: editUser == null ? Yup.string().required("This field is required.").min(8, 'Minimum length should be 8') : Yup.string().min(8, 'Minimum length should be 8'),
        email: Yup.string().email("Invalid email address format").required("This field is required."),
        mobile: Yup.string()
            .required("This field is required.")
            .matches(/^[A-Za-z0-9 ]+$/, "Special Character are not allowed")
            .max(100, "Maximum length 100 allowed")
            .nullable(),
        role: Yup.string().required("Role is required")
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            password: "",
            email: "",
            mobile: "",
            role: "",
            status:""
        },

        onSubmit: async (data) => {
            console.log(data);

            if(editUser==null){
                dispatch(addAdminUser(data));

            }else{
                data['userId']=editUser._id;
                dispatch(editAdminUser(data));

            }
            // setLoading(true);
            // setloadingIcon("pi pi-spin pi-spinner");
            // if (editable === true) {

            //     data["userId"] = UsersRowData;
            //     const res = await dispatch(handlePatchRequest(data, "api/v1/user/", true, true));

            //     if (res?.status === 200) {
            //         await getUserData();
            //         formik.resetForm();
            //         onHide();
            //     }
            // } else {
            //     const res = await dispatch(handlePostRequest(data, "api/v1/user/", true, true));

            //     if (res?.status === 200) {
            //         await getUserData();
            //         formik.resetForm();
            //         onHide();
            //     }
            // }
            // setLoading(false);
            // setloadingIcon("pi pi-save");
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <Toast ref={toast} />

            <form onSubmit={formik.handleSubmit}>

                {
                    loading === true ? <ProgressSpinner /> :
                        <div className="col-12 md:col-6\8">
                            <div className=" p-fluid">


                                <div className="field">

                                    <label htmlFor="name1">Name</label>
                                    <InputText id="name" name='name' value={formik.values.name} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                    {getFormErrorMessage('name')}
                                </div>
                                <div className="field">
                                    <label htmlFor="name1">Password</label>
                                    <InputText id="password" name='password' value={formik.values.password} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('password') })} />
                                    {getFormErrorMessage('password')}
                                </div>
                                <div className="field">
                                    <label htmlFor="email1">Email</label>
                                    <InputText id="email1" type="email" name='email'
                                        value={formik.values.email} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('email') })}
                                    />
                                    {getFormErrorMessage('email')}
                                </div>
                                <div className="field">
                                    <label htmlFor="age1">Mobile</label>
                                    <InputText id="age1" type="tel"
                                        name='mobile'
                                        value={formik.values.mobile} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('mobile') })}

                                    />
                                    {getFormErrorMessage('mobile')}
                                </div>
                                <h1>
                                </h1>
                                <div className="field">
                                    <label htmlFor="age1">Role</label>
                                    <Dropdown optionLabel="name" name='role' value={formik.values.role} options={[...roles.map((el) => ({ 'name': el.name, 'code': el._id, value: el._id }))]} onChange={formik.handleChange} placeholder="Select a City"
                                        className={classNames({ 'p-invalid': isFormFieldValid('role') })}
                                    />
                                    {getFormErrorMessage('role')}
                                </div>

                               {editUser!=null&& <div className="field">
                                    <label htmlFor="age1">Status</label>
                                    <Dropdown optionLabel="name" optionValue='code' name='status' value={formik.values.status} options={statusList} onChange={formik.handleChange} placeholder="Select a status"
                                        className={classNames({ 'p-invalid': isFormFieldValid('role') })}
                                    />
                                </div>}
                                <Button loading={editUser==null? addLoading:editLoading} type='submit' label='Submit' className='p-button-success' />

                            </div>


                        </div>}
            </form>

        </>
    )
}
