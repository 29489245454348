import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";


import { editCenterData } from '../../../actions/centers_actions';
import { EDIT_CENTER_DATA_RESET, GET_CENTER_DETAILS_SUCCESS } from '../../../constants/centers_constants';

export default function AddOffDaysDialog({ onHide }) {
    const editCenterDataReducer = useSelector((state) => state.editCenterData);

    const centerDetails = useSelector((state) => state.centerDetails);
    const { center } = centerDetails;
    const { loading, success, error, center: updatedCenter } = editCenterDataReducer;

    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        date: Yup.date().required("This field is required.").min(new Date(),'Date is not valid'),

    });


    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            date: "",
        },

        onSubmit: async (data) => {


            dispatch(editCenterData({ offDays: [...center.centre.offDays, data.date], centerId: center.centre._id }));

        },
    });


    useEffect(() => {
        if (success !== undefined) {
            if (success === true) {
                dispatch({ type: GET_CENTER_DETAILS_SUCCESS, payload: updatedCenter });
                onHide();
            }
        }


        return () => {
            dispatch({ type: EDIT_CENTER_DATA_RESET });
        }
    }, [success, error, dispatch])
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='p-fluid'>
                    <div className="field">

                        <label htmlFor="name1">Date</label>
                        <InputText id="date" name='date' value={formik.values.date} onChange={formik.handleChange} type="date" className={classNames({ 'p-invalid': isFormFieldValid('date') })} />
                        {getFormErrorMessage('date')}
                    </div>

                </div>

                <div className='text-right'><Button loading={loading} type='submit' label='Submit' className='p-button-success' /></div>



            </form>

        </>

    )
}
