import React, { useEffect } from 'react'

import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import CustomInputField from '../../../app/components/custom_input_field';
import { useAddSpecializationMutation, useUpdateSpecializationMutation } from '../manager/specialization_services';
import appService from '../../../app/services/app_service';

export default function AddEditDoctorSpecializationDialog({ editData, onHide }) {



    const [addSpecialization, { isLoading: addLoading, error: addError, status: addStatus }] = useAddSpecializationMutation();
    const [updateSpecialization, { isLoading: editLoading, isError: editSuccess, error: editError, status: updateStatus }] = useUpdateSpecializationMutation();



    useEffect(() => {
        appService.showApiResponseStatus(
            {
                status: addStatus,
                msg: "Added successfully!",
                error: addError,
                successCB: ()=>{
                    formik.resetForm();
                    onHide();
                }
            }
        );
        appService.showApiResponseStatus(
            {
                status: updateStatus,
                msg: "Updated successfully!",
                error: editError,
                successCB: ()=>{
                    formik.resetForm();
                    onHide();
                }
            }
        );

  

    }, [addError, editSuccess, editError, addStatus,updateStatus]);


    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required.")


    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {

            "name": "",




        },

        onSubmit: async (data) => {
            console.log(data);

            if (editData == null) {
                addSpecialization(data);
                // dispatch(addCompany(data));

            } else {
                updateSpecialization({
                    ...data,
                    "id": editData._id
                })
                // data['userId']=editUser._id;
                // dispatch(editCompany(data));

            }

        },
    });

    useEffect(() => {
        console.log(editData);
      if(editData != null){
        formik.setFieldValue("name", editData.name);
      }
    }, [])
    


    return (
        <>
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="col-12 md:col-6\8">
                        <div className=" p-fluid">
                            <CustomInputField type='text' placeHolder="Name" iden='name' formik={formik} />

                            <Button loading={editData == null ? addLoading : editLoading} type='submit' label='Submit' className='p-button-success' />

                        </div>
                    </div>

                </form>
            </div>
        </>
    )
}
