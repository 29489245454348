import { api } from "../../../app/api";

export const doctorsApi = api.injectEndpoints({
    endpoints: build => ({
        getEventsOranizers: build.query({
            query: () => 'api/organizer',
            providesTags: ['organizers']
        }),
        getOrganizerEvents: build.query({
            query: (id) => `api/event/organizer?id=${id}`,
            providesTags: ['organizer_events']
        }),
        addNewOrganizer: build.mutation({
            query: body => ({
                url: 'api/organizer',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['organizers'],
        }),
        updateOrganizer: build.mutation({
            query: body => ({
                url: `api/organizer/${body.id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['organizers'],
        }),

        getAllEvents: build.query({
            query: () => "api/event",
            providesTags: ['all_evnets']
        }),
        addNewEvent: build.mutation({
            query: body => ({
                url: 'api/event',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['organizer_events', 'all_evnets'],
        }),
        updateEvent: build.mutation({
            query: body => ({
                url:`api/event/${body.event_id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['all_evnets', 'organizer_events'],
        }),
        getEventDetails: build.query({
            query: (id) => `api/event/${id}`,
            providesTags: ['event_details']
        }),


    }),
});

export const {
    useGetEventDetailsQuery,
    useGetAllEventsQuery,
    useGetOrganizerEventsQuery,
    useGetEventsOranizersQuery,
    useAddNewOrganizerMutation,
    useAddNewEventMutation,
    useUpdateEventMutation,
    useUpdateOrganizerMutation

} = doctorsApi;

