import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import CustomInputField from '../../../app/components/custom_input_field';
import appService from '../../../app/services/app_service';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useAddNewEventMutation, useUpdateEventMutation, useUpdateOrganizerMutation } from '../manager/events_service';
import MulitiInputField from '../../../app/components/multiple_input_field';
import { toast } from 'react-toastify';
import Previews from '../../posts/components/drop_zone';
import toastService from '../../../app/services/toast_service';
import { formatDate } from '../../../app/services/time_format_service';
import { militaryTimeToLocalHHMM } from '../../../app/services/military_time_service';

export default function AddEventDialog({ editData, onHide, id }) {


    const [gallery, setGallery] = useState([]);
    const [guidelines, setGuidelines] = useState([]);


    const [addEvent, { isLoading: addLoading, error: addError, status: addStatus }] = useAddNewEventMutation();
    const [updateEvent, { isLoading: editLoading, error: editError, status: updateStatus }] = useUpdateEventMutation();


    async function readFileAsDataURL(file) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });



        return result_base64;
    }



    useEffect(() => {

        appService.showApiResponseStatus(
            {
                status: addStatus,
                msg: "Added successfully!",
                error: addError,
                successCB: () => {
                    formik.resetForm();
                    onHide();
                }
            }
        );
        appService.showApiResponseStatus(
            {
                status: updateStatus,
                msg: "Updated successfully!",
                error: editError,
                successCB: () => {
                    formik.resetForm();
                    onHide();
                }
            }
        );


    }, [addStatus, updateStatus]);


    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        eventDate: Yup.date().required("Date is required"),
        registrationOpeningDate: Yup.date().required("Opening date is required"),
        registrationClosingDate: Yup.date().required("Closing date is required"),
        totalSeats: Yup.number().min(1).required("Total seats are required"),

        eventStartTime: Yup.string().required("Start time is required"),
        eventEndTime: Yup.string().required("End time is required"),

        latitude: Yup.number().required("Latitude is required"),
        longitude: Yup.number().required("Longitude is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        address: Yup.string().required("Address is required"),

        fee: Yup.number().min(1).required("Fee is required"),
        commissionPercentage: Yup.number().min(1).required("Copet commission is required"),




    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {

            title: "",

            eventDate: "",
            registrationOpeningDate: "",
            registrationClosingDate: "",
            totalSeats: "",
            eventStartTime: "",
            eventEndTime: "",

            latitude: "",
            longitude: "",
            state: "",
            city: "",
            address: "",

            fee: "",
            commissionPercentage: "",


        },
        onSubmit: async (data) => {

            if (editData === null) {

                if (gallery.length === 0) {
                    toastService.info("Please choose at least one gallery image/video")
                    return;
                }
                let files = [];
                for (var file of gallery) {
                    let res = await readFileAsDataURL(file);
                    files.push(res);
                }


                addEvent({
                    organizer: id,
                    ...data,
                    gallery: files,
                    guidelines: guidelines,
                    eventStartTime: getUTCMiltaryTimeFromLocalTime(data.eventStartTime),
                    eventEndTime: getUTCMiltaryTimeFromLocalTime(data.eventEndTime),
                    location: {
                        "latitude": data.latitude,
                        "longitude": data.longitude,
                        "state": data.state,
                        "city": data.city,
                        "address": data.address
                    }
                });
            } else {
                updateEvent({
                    event_id: editData._id,
                    organizer: id,
                    ...data,
                    // gallery: files,
                    guidelines: guidelines,
                    eventStartTime: getUTCMiltaryTimeFromLocalTime(data.eventStartTime),
                    eventEndTime: getUTCMiltaryTimeFromLocalTime(data.eventEndTime),
                    location: {
                        "latitude": data.latitude,
                        "longitude": data.longitude,
                        "state": data.state,
                        "city": data.city,
                        "address": data.address
                    }
                })
            }

        },
    });

    useEffect(() => {
        if (editData != null) {
            formik.setFieldValue("title", editData.title);
            formik.setFieldValue("eventDate", formatDate(editData.eventDate));
            formik.setFieldValue("registrationOpeningDate", formatDate(editData.registrationOpeningDate));
            formik.setFieldValue("registrationClosingDate", formatDate(editData.registrationClosingDate));
            formik.setFieldValue("totalSeats", editData.totalSeats);
            formik.setFieldValue("eventStartTime", militaryTimeToLocalHHMM(editData.eventStartTime));
            formik.setFieldValue("eventEndTime", militaryTimeToLocalHHMM(editData.eventEndTime));
            formik.setFieldValue("latitude", editData.location?.latitude);
            formik.setFieldValue("longitude", editData.location?.longitude);
            formik.setFieldValue("state", editData.location?.state);
            formik.setFieldValue("city", editData.location?.city);
            formik.setFieldValue("address", editData.location?.address);
            formik.setFieldValue("fee", editData.fee);
            formik.setFieldValue("commissionPercentage", editData.commissionPercentage);
        }
    }, [])

    const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 200,
        height: 100,
        padding: 4,
        boxSizing: 'border-box',
        textAlign: 'center'

    };
    const img = {
        display: 'block',
        width: 'auto',
        height: '100%'
    };
    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };

    const classifyFile = (mimeType) => {
        if (mimeType.startsWith('image/')) {
            return 'photo';
        } else if (mimeType.startsWith('video/')) {
            return 'video';
        } else {
            return 'unknown';
        }
    };
    const previewBody = (file) => {

        return <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                {file.type.startsWith('image') ? (
                    <img src={file.preview} style={img} onLoad={() => URL.revokeObjectURL(file.preview)} alt="Preview" />
                ) : (
                    <video src={file.preview} style={img} controls />
                )}
            </div>
        </div>;
    }





    return (
        <>
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="col-12 md:col-6\8">
                        <div className=" p-fluid">

                            <h4>
                                Details
                            </h4>

                            <CustomInputField
                                type='text'
                                iden='title'
                                formik={formik}
                                placeHolder="Enter title" />

                            <h4>
                                Timings
                            </h4>

                            <CustomInputField
                                type='date'
                                iden='eventDate'
                                formik={formik}
                                label="Event Date"
                                placeHolder="Enter event date" />

                            <CustomInputField
                                type='date'
                                iden='registrationOpeningDate'
                                label="Registration Opening Date"
                                formik={formik}
                                placeHolder="Enter registration opening date" />
                            <CustomInputField
                                type='date'
                                iden='registrationClosingDate'
                                label="Registration Closing Date"
                                formik={formik}
                                placeHolder="Enter registration closing date" />

                            <CustomInputField
                                type='time'
                                iden='eventStartTime'
                                label="Event Start Time"
                                formik={formik}
                                placeHolder="Enter start time" />

                            <CustomInputField
                                type='time'
                                iden='eventEndTime'
                                label="Event End Time"
                                formik={formik}
                                placeHolder="Enter end time" />

                            <h4>
                                Seats and Fee
                            </h4>

                            <CustomInputField
                                type='number'
                                iden='totalSeats'
                                label="Total Seats"
                                formik={formik}
                                placeHolder="Total Seats" />
                            <CustomInputField
                                type='number'
                                iden='fee'
                                formik={formik}
                                placeHolder="Fee" />

                            <CustomInputField
                                type='number'
                                label="Copet Comission Percentage"
                                iden='commissionPercentage'
                                formik={formik}
                                placeHolder="Copet commission" />



                            <MulitiInputField
                                name="guidelines"
                                label="Guidelines"
                                placeHolder="Enter guidelines"
                                defaultValues={guidelines}
                                onChange={(values) => {

                                    setGuidelines(values)
                                }} />


                            <h4>
                                Location
                            </h4>

                            <CustomInputField
                                type='number'
                                iden='latitude'
                                formik={formik}
                                placeHolder="Latitude" />

                            <CustomInputField
                                type='number'
                                iden='longitude'
                                formik={formik}
                                placeHolder="Longitude" />

                            <CustomInputField
                                type='string'
                                iden='state'
                                formik={formik}
                                placeHolder="State" />
                            <CustomInputField
                                type='string'
                                iden='city'
                                formik={formik}
                                placeHolder="City" />
                            <CustomInputField
                                type='string'
                                iden='address'
                                formik={formik}
                                placeHolder="Adress" />

                            {
                                editData == null && <div>
                                    <h4>
                                        Gallery
                                    </h4>

                                    <div className='card'>
                                        <Previews
                                            files={gallery}
                                            setFiles={setGallery}
                                        />

                                    </div>

                                    {
                                        gallery.map((item, index) => {
                                            return <div className='flex justify-content-between'>

                                                {previewBody(item)}


                                                <div>
                                                    <Button
                                                        onClick={() => {
                                                            setGallery((prev) => {
                                                                prev.splice(index, 1);
                                                                return [...prev];
                                                            })
                                                        }} icon="pi pi-times"
                                                        className="p-button-rounded p-button-text" />

                                                </div>
                                            </div>


                                        })
                                    }
                                </div>
                            }

                            <Button
                                loading={editData === null ? addLoading : editLoading}
                                type='submit'
                                label='Submit'
                                className='p-button-success' />

                        </div>
                    </div>

                </form>
            </div>
        </>
    )
}


const getUTCMiltaryTimeFromLocalTime = (time) => {
    var localDate = new Date("2000-01-01 " + time); // Use a common date for parsing
    var utcTime = localDate.toUTCString().match(/(\d{2}:\d{2}:\d{2})/)[0];
    var militaryTime = "";

    var hours = parseInt(utcTime.substr(0, 2));
    var minutes = parseInt(utcTime.substr(3, 2));

    if (hours < 10) {
        militaryTime += "0";
    }
    militaryTime += hours.toString();

    if (minutes < 10) {
        militaryTime += "0";
    }
    militaryTime += minutes.toString();
    return parseInt(militaryTime);
}