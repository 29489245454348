import { api } from "../../../app/api";

export const doctorSpecializationApi = api.injectEndpoints({
    endpoints: build => ({
        getSpecialization: build.query({
            query: () => 'api/doctors/specialization',
            providesTags: ['specialization']
        }),
        addSpecialization: build.mutation({
            query: body => ({
                url: `api/doctors/specialization?name=${body.name}`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['specialization'],
        }),
        updateSpecialization: build.mutation({
            query: body => ({
                url: 'api/doctors/specialization',
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['specialization'],
        }),
        deleteSpecialization: build.mutation({
            query: id => ({
                url: `api/doctors/specialization?id=${id}`,
                method: 'DELETE',
              
            }),
            invalidatesTags: ['specialization'],
        })

    }),
});

export const {
     useGetSpecializationQuery,
     useAddSpecializationMutation, 
     useUpdateSpecializationMutation, 
     useDeleteSpecializationMutation
   } = doctorSpecializationApi;

