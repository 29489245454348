import axios from 'axios';
import { getUniqueId } from './uuid_serivce';

const bunnyService = {
    "api_base_url": "https://video.bunnycdn.com/",
    "api_key": "2d284095-6858-40c1-a04d88992e8c-0800-4dee",
    "storage_key": "1f90b699-3bf5-4319-988bb4c3df4a-34ca-45f0",
    "lib_key": "145631",
    "createVideo": async () => {

        const body = {
            "title": getUniqueId()
        }

        const headers = {
            headers: {
                AccessKey: bunnyService.api_key
            }
        };
        const response = await axios.post(`${bunnyService.api_base_url}library/${bunnyService.lib_key}/videos`, body, headers);
        return response;
    },
    "uploadVideo": async ({ guid, data }) => {
        const headers = {
            headers: {
                AccessKey: bunnyService.api_key,
                'Content-Type': 'application/octet-stream'
            },
           
        };
        const response = await axios.put(`${bunnyService.api_base_url}library/${bunnyService.lib_key}/videos/${guid}`, data, headers);
        return response;
    },
    "uploadImage": async ({ fileName, data }) => {
        let url = `https://sg.storage.bunnycdn.com/copet/images/${fileName}`;
        const headers = {
            headers: {
                AccessKey: bunnyService.storage_key,
                'Content-Type': 'application/octet-stream'
            },
           
        };
        const response = await axios.put(url, data, headers);
        return response;
    },
    "addPost": async({post})=>{
        const response = await axios.post(process.env.REACT_APP_BASE_URL+"api/videoPost",post);
    }

}

export {
    bunnyService
}