import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getNotificationTopics } from '../../../slices/notification_slice';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import AddEditTopicDialog from '../components/add_edit_topic_dialog';

export default function NotificationTopicsScreen() {

    const dispatch=useDispatch();
    const {topics,loading}=useSelector((state)=>state.notifications);

    const [showAddEditTopcDialog,setAddEditTopicDialog]=useState(false);

    const [editData,setEditData]=useState(null);

    useEffect(() => {
        dispatch(getNotificationTopics());
    }, [dispatch])


    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    
    const dialogFuncMap = {
        'showAddEditTopcDialog': setAddEditTopicDialog,
     
    }
    
  return (
    <div className='card'>
          <div className='flex flex-row justify-content-between align-content-center align-items-center pb-3'>
                    <h5>Topics</h5>
                    <Button label='Add new' className='p-button-success p-button-rounded p-button-sm' onClick={() =>{
                           setEditData(null);
                        onClick('showAddEditTopcDialog')}} />



                </div>
        
        <div>

        <Dialog header={editData==null?'Add topic':'Edit topic'} visible={showAddEditTopcDialog} onHide={() => onHide('showAddEditTopcDialog')}  breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '70vw' }}>
                <AddEditTopicDialog editData={editData} onHide={()=>onHide('showAddEditTopcDialog')} />
            </Dialog>
        <DataTable value={topics} paginator className="p-datatable-gridlines" showGridlines rows={10}
                    dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll"
                    emptyMessage="No topics found.">
                    <Column field="title" header="Title" style={{ minWidth: '12rem' }} />
                    <Column field="description" header="Description" style={{ minWidth: '12rem' }} />
            
                </DataTable>
        </div>

    </div>
  )
}
