import { Button } from 'primereact/button'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as  xlsx from 'xlsx';
import { addMultipleProfiles, resetPetDateSlice } from '../../../slices/pet_date_slice';
import moment from 'moment';
import toastService from '../../../app/services/toast_service';
import httpClient, { requestType } from '../../../app/services/http_client';
import { fileToBase64 } from '../../../app/services/file_services';

export default function AddMultipleProfilesScreen() {
    const [loading, setLoading] = useState(false);
    const [imageFiles, setImageFiles] = useState([]);

    //api call
    const uploadFiles = async () => {
        setLoading(true);
        let base64Files = [];
        for (var file of imageFiles) {
            base64Files.push({
                "id": "1",
                "fileData": await fileToBase64(file)
            })
        }

        try {
            const { data } = await httpClient({
                method: requestType.post,
                body: base64Files,
                url: "api/pet/uploadFiles"
            });
            console.log(data);

    
            const r2 = await httpClient({
                method: requestType.post,
                body: {
                    "userId": "6650d4f1b9b5ed65c069cb2d",
                    "pets": pets.map((e, index) => {
                        e["photo"] = data[index].fileData;
                        return e;
                    },)
                },
                url: "api/pet/multiple"
            });

            setLoading(false);
            setPets([]);
            setImageFiles([]);
            toastService.success("Pets added successfully");

        } catch (error) {
            setLoading(false);
            toastService.error(error);

        }

    }





    //end
    const dispatch = useDispatch();

    const [allowUpload, setAllowUpload] = useState(false);

    const [pets, setPets] = useState([]);

    useEffect(() => {
        if (pets.length == 0) {
            setAllowUpload(false);
        } else if ((imageFiles.length < pets.length)) {
            if (imageFiles.length > 0) {
                toastService.error("Provided images are less than total provided pets");

            }
        } else {
            setAllowUpload(true);
        }
    }, [pets, imageFiles])


    const onFileChange = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                var json = xlsx.utils.sheet_to_json(worksheet);
                json = json.map((item) => {
                    return {
                        ...item,
                        dob: moment('1900-01-01').add(item.dob, 'days').format('YYYY-MM-DD')
                    }

                })
                setPets(json);
            };


            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    const onImagesFileChage = (e) => {
        e.preventDefault();
        setImageFiles(e.target.files);
        console.log(e.target.files);
    }


    const onUploadClick = (e) => {
        e.preventDefault();
        uploadFiles();
    }

    return (
        <div className='card'>
            <div>
                <h3>
                    Add Multiple Pet Profiles
                </h3>
            </div>
            <div className='flex justify-content-between'>
                <div className="field">
                    <label className='mr-2'>
                        Choose Pets
                    </label>

                    <InputText placeholder='Choose file' type='file' accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={onFileChange} />


                </div>
                {pets.length !== 0 && <div className="field">
                    <label className='mr-2'>
                        Choose Images
                    </label>

                    <InputText placeholder='Choose images' type='file' accept="image/*" multiple={true} onChange={onImagesFileChage} />


                </div>}

                <div>
                    {pets.length > 0 && allowUpload == true && <Button onClick={onUploadClick} label='Upload' loading={loading} />}
                </div>

            </div>


            <div>
                <DataTable value={pets} paginator className="p-datatable-gridlines" showGridlines rows={20}
                    dataKey="id" filterDisplay="menu" responsiveLayout="scroll"
                    emptyMessage="No data found.">
                    <Column field="name" header="Name" style={{ minWidth: '12rem' }} />
                    <Column field="breed" header="Breed" style={{ minWidth: '12rem' }} />
                    <Column field="gender" header="Gender" style={{ minWidth: '12rem' }} />
                    <Column field="petType" header="Type" style={{ minWidth: '12rem' }} />
                    <Column field="location" header="Location" style={{ minWidth: '12rem' }} />
                    <Column field="story" header="Story" style={{ minWidth: '12rem' }} />
                    <Column field="dob" header="DOB" style={{ minWidth: '12rem' }} />
                    {/* <Column field="profileImage" header="Profile Image" style={{ minWidth: '12rem' }} /> */}

                    {/* <Column header="Actions" body={getActionBody} style={{ minWidth: '12rem' }} /> */}



                </DataTable>
            </div>
        </div>
    )
}
