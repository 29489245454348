import { BASE_URL } from '../config';
import { axiosApi } from '../app/services/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const notificationSlice = createSlice({
    name: 'notificationSlice',
    initialState: {},
    reducers: {
        resetNotificationSlice(state, action) {
            return {
                ...state,
                addTopicData: undefined,
                sendNotificationData: undefined
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNotificationTopics.pending, (state, action) => {
                return { ...state, loading: true, topics: undefined }
            })
            .addCase(getNotificationTopics.fulfilled, (state, action) => {


                return { ...state, loading: false, topics: action.payload }
            })
            .addCase(getNotificationTopics.rejected, (state, action) => {
                return {
                    loading: false,
                    error: action.payload,
                    topics: undefined
                }
            });

        builder
            .addCase(addNotificationTopic.pending, (state, action) => {
                return { ...state, addTopicLoading: true, addTopicData: undefined }
            })
            .addCase(addNotificationTopic.fulfilled, (state, action) => {


                return { ...state, addTopicLoading: false, addTopicData: action.payload }
            })
            .addCase(addNotificationTopic.rejected, (state, action) => {
                return {
                    addTopicLoading: false,
                    error: action.payload,
                    addTopicData: undefined

                }
            });
        builder
            .addCase(deleteNotificationTopic.pending, (state, action) => {
                return { ...state, addTopicLoading: true, deleteTopicData: undefined }
            })
            .addCase(deleteNotificationTopic.fulfilled, (state, action) => {


                return { ...state, addTopicLoading: false, deleteTopicData: action.payload }
            })
            .addCase(deleteNotificationTopic.rejected, (state, action) => {
                return {
                    addTopicLoading: false,
                    error: action.payload,
                    deleteTopicData: undefined

                }
            });
        builder
            .addCase(sendNotification.pending, (state, action) => {
                return { ...state, sendNotificationLoading: true, sendNotificationData: undefined }
            })
            .addCase(sendNotification.fulfilled, (state, action) => {


                return { ...state, sendNotificationLoading: false, sendNotificationData: action.payload }
            })
            .addCase(sendNotification.rejected, (state, action) => {
                return {
                    sendNotificationLoading: false,
                    sendNotificationError: action.payload,
                    sendNotificationData: undefined

                }
            });
        builder
            .addCase(getNotifications.pending, (state, action) => {
                return { ...state, notificationsLoading: true, notifications: undefined }
            })
            .addCase(getNotifications.fulfilled, (state, action) => {


                return { ...state, notificationsLoading: false, notifications: action.payload }
            })
            .addCase(getNotifications.rejected, (state, action) => {
                return {
                    notificationsLoading: false,
                    sendNotificationError: action.payload,
                    notifications: undefined

                }
            });
    },
});

export default notificationSlice.reducer;
export const { resetNotificationSlice } = notificationSlice.actions;


// Thunks
export const getNotificationTopics = createAsyncThunk('notificationsTopics/fetch', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const url = BASE_URL + 'api/topic/';
        const { data } = await axiosApi.get(url);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});
export const addNotificationTopic = createAsyncThunk('notification/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const url = BASE_URL + 'api/topic/';
        const { data } = await axiosApi.post(url, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});
export const deleteNotificationTopic = createAsyncThunk('notification/delete', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const url = BASE_URL + 'api/topic?topicId=' + id;
        const { data } = await axiosApi.delete(url);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});

export const sendNotification = createAsyncThunk('sendNotification/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const url = BASE_URL + 'api/notification/sendNotificationToTopics';
        const { data } = await axiosApi.post(url, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});
export const getNotifications = createAsyncThunk('getNotifcations/fetch', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const url = BASE_URL + 'api/topic/generalNotifications';
        const { data } = await axiosApi.get(url);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});

