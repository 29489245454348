import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { MultiSelect } from 'primereact/multiselect';

import { Button } from 'primereact/button';
import { getNotificationTopics, getNotifications, resetNotificationSlice, sendNotification } from '../../../slices/notification_slice';
export default function SendNotificationDialog({onHide}) {

  const dispatch = useDispatch();
  const { topics,  sendNotificationLoading, sendNotificationData } = useSelector((state) => state.notifications);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required."),
    body: Yup.string().required("Body is required."),
    topics: Yup.array()
    .required('Please select at least one item')
    .min(1, 'Please select at least one item'),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      title: "",
      body: "",
      topics: ""

    },

    onSubmit: async (data) => {
      data={
        "topics": data.topics,
        "notificationData":{
          "title": data.title,
          "body": data.body,
          "data":{},
          "image":""
        }
    }
 
      dispatch(sendNotification(data));


    },
  });

  useEffect(() => {
    if(sendNotificationData){
      onHide();
      dispatch(getNotifications());

    }
    return ()=>{
      dispatch(resetNotificationSlice());
    }
  }, [sendNotificationData])
  

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };
  useEffect(() => {
    dispatch(getNotificationTopics());
  }, [])

  return (
    <>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className="col-12 md:col-6\8">
            <div className=" p-fluid">

              <div className="field">
                <label htmlFor="name1">Name</label>
                <InputText id="title" name='title' value={formik.values.name} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('title') })} />
                {getFormErrorMessage('title')}
              </div>
              <div className="field">
                <label htmlFor="name1">Description</label>
                <InputText id="body" name='body' value={formik.values.body} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('body') })} />
                {getFormErrorMessage('body')}
              </div>
              <div className="field">
                <label htmlFor="name1">Description</label>
                <MultiSelect name='topics' value={formik.values.topics} onChange={formik.handleChange} options={topics ?? []} optionLabel="title" optionValue='_id'  className={classNames({ 'p-invalid': isFormFieldValid('topics') })}
                  placeholder="Select topics"  />

                {getFormErrorMessage('topics')}
              </div>

              <Button loading={sendNotificationLoading} type='submit' label='Send' className='p-button-success' />

            </div>
          </div>
        </form>


      </div>
    </>
  )
}
