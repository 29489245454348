import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";

import { editCenterData } from '../../../actions/centers_actions';
import { EDIT_CENTER_DATA_RESET, GET_CENTER_DETAILS_SUCCESS } from '../../../constants/centers_constants';

export default function EditDogGroomingDialog({ grooming, onHide }) {
    const editCenterDataReducer = useSelector((state) => state.editCenterData);

    const centerDetails = useSelector((state) => state.centerDetails);
    const { center } = centerDetails;
    const { loading, success, error, center: updatedCenter } = editCenterDataReducer;

    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("This field is required."),
        description: Yup.string().required("This field is required."),
        smallRange: Yup.string().required("This field is required."),
        mediumRange: Yup.string().required("This field is required."),
        largeRange: Yup.string().required("This field is required."),

    });


    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            largeRange: "",
            mediumRange: "",
            smallRange: "",
            title: "",
            description: "",
            enable: true
        },

        onSubmit: async (data) => {
         

            let groomings = center.grooming.dogGrooming;
            groomings = groomings.map((item) => {
                if (item._id === grooming._id) {
                    return { ...item, dogPrice:{
                        smallRange:data.smallRange,
                        mediumRange:data.mediumRange,
                        largeRange:data.largeRange,
                    },
                title:data.title,
                description:data.description,
                enable:data.enable
                }
                }
                return item;
            });
            dispatch(editCenterData({ dogGrooming: groomings, centerId: center.centre._id }));

        },
    });
    useEffect(() => {
        formik.setFieldValue('largeRange', grooming.dogPrice.largeRange);
        formik.setFieldValue('mediumRange', grooming.dogPrice.mediumRange);
        formik.setFieldValue('smallRange', grooming.dogPrice.smallRange);
        formik.setFieldValue('title', grooming.title);
        formik.setFieldValue('description', grooming.title);
        formik.setFieldValue('enable', grooming.enable);



    }, [])

    useEffect(() => {
        if (success !== undefined) {
            if (success === true) {
                console.log(updatedCenter);
                dispatch({ type: GET_CENTER_DETAILS_SUCCESS, payload: updatedCenter });
                onHide();
            }
        }


        return () => {
            dispatch({ type: EDIT_CENTER_DATA_RESET });
        }
    }, [success, error, dispatch])
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='p-fluid'>
                    <div className="field">

                        <label htmlFor="name1">Title</label>
                        <InputText id="title" name='title' value={formik.values.title} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('title') })} />
                        {getFormErrorMessage('title')}
                    </div>
                    <div className="field">

                        <label htmlFor="name1">Description</label>
                        <InputText id="description" name='description' value={formik.values.description} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('description') })} />
                        {getFormErrorMessage('description')}
                    </div>
                    <div className="field">

                        <label htmlFor="name1">Small Price</label>
                        <InputText id="title" name='smallRange' value={formik.values.smallRange} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('smallRange') })} />
                        {getFormErrorMessage('smallRange')}
                    </div>
                    <div className="field">

                        <label htmlFor="name1">Medium</label>
                        <InputText id="cat" name='mediumRange' value={formik.values.mediumRange} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('mediumRange') })} />
                        {getFormErrorMessage('mediumRange')}
                    </div>
                    <div className="field">

                        <label htmlFor="name1">Large</label>
                        <InputText id="cat" name='largeRange' value={formik.values.largeRange} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('largeRange') })} />
                        {getFormErrorMessage('largeRange')}
                    </div>

                    <div className='flex flex-row justify-content-between'>
                        <h3>Is slot available?</h3>
                        <Checkbox name='enable' checked={formik.values.enable} onChange={formik.handleChange} />

                    </div>
                </div>

                <div className='text-right'><Button loading={loading} type='submit' label='Submit' className='p-button-success' /></div>



            </form>

        </>

    )
}
