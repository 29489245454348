import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const UserDetailsDialog = ({ selectedUser, onHide }) => {
  return (
    <div className="card">
      {selectedUser && (
        <DataTable value={[selectedUser]} className="p-datatable-sm">
          <Column
            field="firstName"
            header="First Name"
            body={(rowData) => <span>{rowData.user.firstName}</span>}
          />
          <Column
            field="lastName"
            header="Last Name"
            body={(rowData) => <span>{rowData.user.lastName}</span>}
          />
          <Column
            field="gender"
            header="Gender"
            body={(rowData) => <span>{rowData.user.gender}</span>}
          />
          <Column
            field="userFeedback"
            header="Feedback"
            body={(rowData) => <span>{rowData.userFeedback}</span>}
          />
        </DataTable>
      )}
    </div>
  );
};

export default UserDetailsDialog;
