import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import CustomInputField from '../../../app/components/custom_input_field';
import { useAddDoctorAvailbilityMutation } from '../manager/doctors_services';
import appService from '../../../app/services/app_service';
import toastService from '../../../app/services/toast_service';
export default function AddEditDoctorAvailabilityDialog({ onHide, id }) {


  const [addDoctorAvailbility, { isLoading: addLoading, error: addError, status: addStatus }] = useAddDoctorAvailbilityMutation();



  const validationSchema = Yup.object().shape({
    startDay: Yup.string().required("Start day is required"),
    endDay: Yup.string().required("End day is required"),


  });
  useEffect(() => {
    appService.showApiResponseStatus(
      {
        status: addStatus,
        msg: "Added successfully!",
        error: addError,
        successCB: () => {
          formik.resetForm();
          onHide();
        }
      }
    );
  }, [addStatus])




  const days = [
    {
      "name": "Sunday",
      "value": 0
    },
    {
      "name": "Monday",
      "value": 1
    },
    {
      "name": "Tuesday",
      "value": 2
    },
    {
      "name": "Wednesday",
      "value": 3
    },
    {
      "name": "Thrusday",
      "value": 4
    },
    {
      "name": "Friday",
      "value": 5
    },
    {
      "name": "Saturday",
      "value": 6
    }
  ];
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      "startDay": "",
      "endDay": "",
      "morningStartTime": "",
      "morningEndTime": "",
      "eveningStartTime": "",
      "eveningEndTime": ""
    },
    onSubmit: async (data) => {

      if (data.eveningStartTime.length == 0 && data.eveningEndTime.length == 0 && data.morningStartTime.length == 0 && data.morningEndTime.length == 0) {
        toastService.error("Please select at least either morning or evening time");
        return;
      }
      if((data.morningStartTime.length>0 || data.morningEndTime.length > 0) && (data.morningStartTime.length == 0 || data.morningEndTime.length == 0)){
        toastService.error("Please provide complete morning timings");
        return;
      }
      if((data.eveningStartTime.length>0 || data.eveningEndTime.length > 0) && (data.eveningStartTime.length == 0 || data.eveningEndTime.length == 0)){
        toastService.error("Please provide complete evening timings");
        return;
      }
 
      addDoctorAvailbility(
        {
          "id": id,
          "newTimeFrame": {
            "startDay": data.startDay,
            "endDay": data.endDay,
            "morning": {
              "startTime": getUTCMiltaryTimeFromLocalTime(data.morningStartTime),
              "endTime": getUTCMiltaryTimeFromLocalTime(data.morningEndTime),
            },
            "evening": {
              "startTime": getUTCMiltaryTimeFromLocalTime(data.eveningStartTime),
              "endTime": getUTCMiltaryTimeFromLocalTime(data.eveningEndTime),
            }
          }
        }
      )
    },
  });
  return (
    <>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className="col-12 md:col-6\8">
            <div className=" p-fluid">
              <CustomInputField type='dropdown' iden='startDay' formik={formik} options={days} placeHolder="Select start day" label="Select start day" />
              <CustomInputField type='dropdown' iden='endDay' formik={formik} options={days} placeHolder="Select end day" label="Select end day" />
              <CustomInputField type='time' min="09:00" max="21:00" iden='morningStartTime' formik={formik} placeHolder="Morning start time" label="Morning start time" />
              <CustomInputField type='time' min="09:00" max="21:00" iden='morningEndTime' formik={formik} placeHolder="Morning end time" label="Morning end time" />
              <CustomInputField type='time' min="21:00" max="09:00" iden='eveningStartTime' formik={formik} placeHolder="Evening start time" label="Evening start time" />
              <CustomInputField type='time' min="21:00" max="09:00" iden='eveningEndTime' formik={formik} placeHolder="Evening end time" label="Evening end time" />
              <Button loading={addLoading} type='submit' label='Submit' className='p-button-success' />

            </div>
          </div>

        </form>
      </div>
    </>
  )
}


const getUTCMiltaryTimeFromLocalTime = (time) => {
  var localDate = new Date("2000-01-01 " + time); // Use a common date for parsing
  var utcTime = localDate.toUTCString().match(/(\d{2}:\d{2}:\d{2})/)[0];
  var militaryTime = "";

  var hours = parseInt(utcTime.substr(0, 2));
  var minutes = parseInt(utcTime.substr(3, 2));

  if (hours < 10) {
    militaryTime += "0";
  }
  militaryTime += hours.toString();

  if (minutes < 10) {
    militaryTime += "0";
  }
  militaryTime += minutes.toString();
  return parseInt(militaryTime);
}