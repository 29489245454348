import { axiosApi } from "../app/services/axios_api";
import { GET_DASHBOARD_STATS_FAILURE, GET_DASHBOARD_STATS_REQUEST, GET_DASHBOARD_STATS_SUCCESS } from "../constants/dashboard_constants";
import { BASE_URL } from './../config';
const getDashboardStats = () => async (dispatch, getState) => {
    dispatch({ type: GET_DASHBOARD_STATS_REQUEST });

    const url = BASE_URL + 'api/admin/dashboard';

    try {
        const { data } = await axiosApi.get(url,
        );
        dispatch({ type: GET_DASHBOARD_STATS_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: GET_DASHBOARD_STATS_FAILURE, payload: error.response && error.response.data.msg
                ? error.response.data.msg
                : error.message
        });
    }
}

export {getDashboardStats}