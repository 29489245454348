import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";

import { editCenterData } from '../../../actions/centers_actions';
import { EDIT_CENTER_DATA_RESET, GET_CENTER_DETAILS_SUCCESS } from '../../../constants/centers_constants';

export default function EditCatBoardingDialog({ boarding, onHide }) {
    const editCenterDataReducer = useSelector((state) => state.editCenterData);

    const centerDetails = useSelector((state) => state.centerDetails);
    const { center } = centerDetails;
    const { loading, success, error, center: updatedCenter } = editCenterDataReducer;

    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("This field is required."),
        cat: Yup.string().required("This field is required."),
        kitten: Yup.string().required("This field is required."),

    });


    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            kitten: "",
            cat: "",
            title: "",
            enable: true
        },

        onSubmit: async (data) => {
         

            let boardings = center.boarding.catBoarding;
            boardings = boardings.map((item) => {
                if (item._id === boarding._id) {
                    return { ...item, catPrice:{
                        kitten:data.kitten,
                        cat:data.cat
                    },
                title:data.title,
                enable:data.enable
                }
                }
                return item;
            });
            dispatch(editCenterData({ catBoarding: boardings, centerId: center.centre._id }));

        },
    });
    useEffect(() => {
        formik.setFieldValue('kitten', boarding.catPrice.kitten);
        formik.setFieldValue('cat', boarding.catPrice.cat);
        formik.setFieldValue('title', boarding.title);
        formik.setFieldValue('enable', boarding.enable);



    }, [])

    useEffect(() => {
        if (success !== undefined) {
            if (success === true) {
                dispatch({ type: GET_CENTER_DETAILS_SUCCESS, payload: updatedCenter });
                onHide();
            }
        }


        return () => {
            dispatch({ type: EDIT_CENTER_DATA_RESET });
        }
    }, [success, error, dispatch])
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='p-fluid'>
                    <div className="field">

                        <label htmlFor="name1">Title</label>
                        <InputText id="title" name='title' value={formik.values.title} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('title') })} />
                        {getFormErrorMessage('title')}
                    </div>
                    <div className="field">

                        <label htmlFor="name1">Kitten Price</label>
                        <InputText id="title" name='kitten' value={formik.values.kitten} onChange={formik.handleChange} type="number" className={classNames({ 'p-invalid': isFormFieldValid('kitten') })} />
                        {getFormErrorMessage('kitten')}
                    </div>
                    <div className="field">

                        <label htmlFor="name1">Title</label>
                        <InputText id="cat" name='cat' value={formik.values.cat} onChange={formik.handleChange} type="number" className={classNames({ 'p-invalid': isFormFieldValid('cat') })} />
                        {getFormErrorMessage('cat')}
                    </div>

                    <div className='flex flex-row justify-content-between'>
                        <h3>Is slot available?</h3>
                        <Checkbox name='enable' checked={formik.values.enable} onChange={formik.handleChange} />

                    </div>
                </div>

                <div className='text-right'><Button loading={loading} type='submit' label='Submit' className='p-button-success' /></div>



            </form>

        </>

    )
}
