import { api } from "../../../app/api";

export const postsAPI = api.injectEndpoints({
    endpoints: build => ({
        getPostCategories: build.query({
            query: () => 'api/category',
            providesTags: ['postCats']
        }),
        getAllPosts: build.query({
            query: () => 'api/videoPost',
            providesTags: ['allPosts']
        }),
        deletePost: build.mutation({
            query: id => ({
                url: `api/videoPost/${id}`,
                method: 'DELETE',

            }),
            invalidatesTags: ['allPosts'],
        }),

    
        updatePostCategory: build.mutation({
            query: body => ({
                url: 'api/category',
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['posts'],
        }),
        deletePostCategory: build.mutation({
            query: body => ({
                url: `api/category/${body}`,
                method: 'DELETE',

            }),
            invalidatesTags: ['postCats'],
        }),
        addPost: build.mutation({
            query: body => ({
                url: 'api/doctors/',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['posts'],
        }),
        addPostCategory: build.mutation({
            query: body => ({
                url: 'api/category',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['postCats'],
        }),

        getAllPostsByCategory: build.mutation({
            query: body => ({
                url: `api/videoPost?page=${body.page}`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['allPosts'],
        }),
        




    }),
});

export const {
    useAddPostCategoryMutation,
    useGetPostCategoriesQuery,
    useGetAllPostsQuery,
    useDeletePostCategoryMutation,
    useUpdatePostCategoryMutation,
    useGetAllPostsByCategoryMutation,
    useDeletePostMutation
} = postsAPI;

