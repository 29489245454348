import { axiosApi } from '../app/services/axios_api';
import { BASE_URL } from '../config';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const doctorsSlice = createSlice({
    name: 'doctorsSlice',
    initialState: {},
    reducers: {
        resetDoctorsSlice(state, action) {
            return {
                ...state,
            
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDoctorsCategories.pending, (state, action) => {
                return { ...state, categoryLoading: true, topics: undefined }
            })
            .addCase(getDoctorsCategories.fulfilled, (state, action) => {


                return { ...state, categoryLoading: false, topics: action.payload }
            })
            .addCase(getDoctorsCategories.rejected, (state, action) => {
                return {
                    categoryLoading: false,
                    error: action.payload,
                    topics: undefined
                }
            });
        builder
            .addCase(getDoctors.pending, (state, action) => {
                return { ...state, loading: true, doctors: undefined }
            })
            .addCase(getDoctors.fulfilled, (state, action) => {


                return { ...state, loading: false, doctors: action.payload }
            })
            .addCase(getDoctors.rejected, (state, action) => {
                return {
                    loading: false,
                    error: action.payload,
                    doctors: undefined

                }
            });
    },
});

export default doctorsSlice.reducer;
export const { resetdoctorsSlice } = doctorsSlice.actions;


// Thunks
export const getDoctorsCategories = createAsyncThunk('doctorsCategory/fetch', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        //TODO: add url
        const url = BASE_URL + '';
        const { data } = await axiosApi.get(url);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});

export const getDoctors = createAsyncThunk('doctors/fetch', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        //TODO: ADD URl
        const url = BASE_URL + '';
        const { data } = await axiosApi.get(url);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});

