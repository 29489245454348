import React, { useState } from 'react'
import PageHeader from '../../../app/components/page_header';
import { useGetEventsOranizersQuery } from '../manager/events_service';
import AddEditOrganizerDialog from '../componenets/add_organizer_dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FILE_BASE_URL } from '../../../config';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { primeIcons } from '../../../app/services/icon_service';

export default function EventOrganizersScreen() {

    const [editData, setEditData] = useState(null);
    const [showAddOrganizerDialog, setShowAddOranizerDilog] = useState(false);
    const history = useHistory();


    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const dialogFuncMap = {
        'showAddOrganizerDialog': setShowAddOranizerDilog,

    }

    const { data: list, isLoading, error, isError } = useGetEventsOranizersQuery();


    const profileImageBody = (row) => {
        return <div ><img alt='Not found' style={{ width: '100px', height: '60px', objectFit: 'contain' }} src={FILE_BASE_URL + row.profile} /> </div>;
    }
    const actionsBody = (rowData) => {
        return <div className='flex'>
            <Button 
            onClick={()=>onGoToOrganizerEventClick(rowData._id)} 
            type="button" 
            tooltip='Events' 
            icon="pi pi-box"
            className='mx-2'
            >
            </Button>
            <Button 
            onClick={()=>editActionOnClick(rowData)} 
            type="button" 
            tooltip='Edit' 
            icon={primeIcons['pi-pencil']}
            className='mx-2'
            >
            </Button>
        </div>
    }
    const onGoToOrganizerEventClick = (id) => {
        history.push(`/api/events/organziers/events/${id}`);
    }

    const editActionOnClick = (data)=>{
        setEditData(data);
        onClick('showAddOrganizerDialog');
    }

    return (
        <div className='card'>
            <PageHeader buttonTitle={"Add Organizer"} onClick={onClick} onHide={onHide} showDialog={showAddOrganizerDialog} dialogHeader={"Organizer"} title={"Organizers"} dialogHook={"showAddOrganizerDialog"} buttonOnClick={() => {
                setEditData(null);
            }}>
                <AddEditOrganizerDialog editData={editData} onHide={() => onHide('showAddOrganizerDialog')} />
            </PageHeader>



            <div>
                <DataTable value={list?.data} paginator className="p-datatable-gridlines" showGridlines rows={10}
                    dataKey="id" filterDisplay="menu" loading={isLoading} responsiveLayout="scroll"
                    emptyMessage="No data found.">
                    <Column 
                        body={profileImageBody} 
                        header="Image" style={{ minWidth: '12rem' }} />
                    <Column field="name" header="Name" style={{ minWidth: '12rem' }} />
                    <Column field="email" header="Email" style={{ minWidth: '12rem' }} />
                    <Column field="phone" header="Phone" style={{ minWidth: '12rem' }} />
                    <Column header="Actions" style={{ minWidth: '12rem' }} body={actionsBody} />
                </DataTable>
            </div>



        </div>
    )
}
