import { DataTable } from 'primereact/datatable'
import React, { useState } from 'react'
import { useGetDoctorPaymentDataQuery } from '../manager/doctors_services';
import { Column } from 'primereact/column';
import { primeIcons } from '../../../app/services/icon_service';
import { Button } from 'primereact/button';
import { formatDate } from '../../../app/services/time_format_service';
import PageHeader from '../../../app/components/page_header';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AddDoctorPaymentDialog from '../components/add_doctor_payment_dialog';
import UpdatePaymentStatusDialog from '../components/update_payment_status_dialog';

export default function DoctorsPaymentsScreen() {
    const params = useParams();
    const { id } = params;
    const [updatedLog, setUpdatedLog] = useState(null);
    const [updatedLogStatus, setUpdatedLogStatus] = useState(null);

    const [addNewPaymentDialog, setAddNewPaymentDialog] = useState(false);
    const [updateLogStatus, setUpdateLogStatus] = useState(false);
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const dialogFuncMap = {
        'addNewPaymentDialog': setAddNewPaymentDialog,
        'updateLogStatus': setUpdateLogStatus,

    }

    const { data: paymentData, isLoading, error, isError } = useGetDoctorPaymentDataQuery({
        doctor: id,
        endDate: (formatDate(new Date()))
    });

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>

               {rowData.status != "completed" && rowData.status != "cancelled" && <Button tooltip='Update Status' icon={primeIcons['pi-pencil']} className="p-button-rounded p-button-warning m-1" onClick={() => {
                    setUpdatedLog(rowData._id);
                    setUpdatedLogStatus(rowData.status)
                    onClick("updateLogStatus")
                }} />}
            </React.Fragment>
        );
    }

    const fromDayBody = (rowData) => {
        return <div>{formatDate(rowData.startDate)}</div>
    };
    const dateBody = (rowData) => {
        return <div>{formatDate(rowData.date)}</div>
    };
    const toDayBody = (rowData) => {
        return <div>{formatDate(rowData.endDate)}</div>
    };
    const createAtBody = (rowData) => {
        return <div>{formatDate(rowData.createdAt)}</div>
    };

    return (
        <div className='card'>
            <PageHeader buttonTitle={"Add new payment request"} onClick={onClick} onHide={onHide} showDialog={addNewPaymentDialog} dialogHeader={"New payment request"} title={"Add New Request"} dialogHook={"addNewPaymentDialog"} buttonOnClick={() => {

            }}>

                <AddDoctorPaymentDialog doctorId={id} onHide={onHide}   >

                </AddDoctorPaymentDialog>

            </PageHeader>


            <PageHeader showButton={false} buttonTitle={""} onClick={onClick} onHide={()=>onHide("updateLogStatus")}  showDialog={updateLogStatus} dialogHeader={""} title={""} dialogHook={"updateLogStatus"} buttonOnClick={() => {

            }}>

                <UpdatePaymentStatusDialog id={updatedLog} onHide={()=>onHide("updateLogStatus")} status={updateLogStatus}  >

                </UpdatePaymentStatusDialog>

            </PageHeader>


            <h3>
                Total Pending Payment :
            </h3>
            <h2>Pending Appointments  </h2>

            <DataTable value={paymentData?.data?.pendingAppointments} paginator className="p-datatable-gridlines" showGridlines rows={10}
                dataKey="id" filterDisplay="menu" loading={isLoading} responsiveLayout="scroll"
                emptyMessage="No data found.">
                <Column body={dateBody} header="Date" style={{ minWidth: '12rem' }} />

                <Column field="amount" header="Amount" style={{ minWidth: '12rem' }} />

                <Column field="status" header="Status" style={{ minWidth: '12rem' }} />
                <Column field="transactionId" header="txnToken" style={{ minWidth: '12rem' }} />
                <Column body={createAtBody} header="Date" style={{ minWidth: '12rem' }} />


            </DataTable>

            <h2>Payments</h2>
            <DataTable value={paymentData?.data?.logs} paginator className="p-datatable-gridlines" showGridlines rows={10}
                dataKey="id" filterDisplay="menu" loading={isLoading} responsiveLayout="scroll"
                emptyMessage="No data found.">
                <Column body={fromDayBody} header="From date" style={{ minWidth: '12rem' }} />
                <Column body={toDayBody} header="To date" style={{ minWidth: '12rem' }} />
                <Column field="totalAmount" header="Total Amount" style={{ minWidth: '12rem' }} />
                <Column field="copetShare" header="Coept Share" style={{ minWidth: '12rem' }} />
                <Column field="status" header="Status" style={{ minWidth: '12rem' }} />
                <Column field="transactionId" header="TransactionId" style={{ minWidth: '12rem' }} />
                <Column body={createAtBody} header="Date" style={{ minWidth: '12rem' }} />
                <Column header="Action" body={actionBodyTemplate} style={{ minWidth: '12rem' }} />

            </DataTable>

        </div>
    )
}
