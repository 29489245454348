import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";

import { editCenterData } from '../../../actions/centers_actions';
import { EDIT_CENTER_DATA_RESET, GET_CENTER_DETAILS_SUCCESS } from '../../../constants/centers_constants';

export default function EditDogBoardingDialog({ boarding, onHide }) {
    const editCenterDataReducer = useSelector((state) => state.editCenterData);

    const centerDetails = useSelector((state) => state.centerDetails);
    const { center } = centerDetails;
    const { loading, success, error, center: updatedCenter } = editCenterDataReducer;

    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("This field is required."),
        description: Yup.string().required("This field is required."),
        small: Yup.string().required("This field is required."),
        medium: Yup.string().required("This field is required."),
        large: Yup.string().required("This field is required."),

    });


    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            large: "",
            medium: "",
            small: "",
            title: "",
            description: "",
            enable: true
        },

        onSubmit: async (data) => {
         

            let boardings = center.boarding.dogBoarding;
            boardings = boardings.map((item) => {
                if (item._id === boarding._id) {
                    return { ...item, dogPrice:{
                        small:data.small,
                        medium:data.medium,
                        large:data.large,
                    },
                title:data.title,
                description:data.description,
                enable:data.enable
                }
                }
                return item;
            });
            dispatch(editCenterData({ dogBoarding: boardings, centerId: center.centre._id }));

        },
    });
    useEffect(() => {
        formik.setFieldValue('large', boarding.dogPrice.large);
        formik.setFieldValue('medium', boarding.dogPrice.medium);
        formik.setFieldValue('small', boarding.dogPrice.small);
        formik.setFieldValue('title', boarding.title);
        formik.setFieldValue('description', boarding.title);
        formik.setFieldValue('enable', boarding.enable);



    }, [])

    useEffect(() => {
        if (success !== undefined) {
            if (success === true) {
                console.log(updatedCenter);
                dispatch({ type: GET_CENTER_DETAILS_SUCCESS, payload: updatedCenter });
                onHide();
            }
        }


        return () => {
            dispatch({ type: EDIT_CENTER_DATA_RESET });
        }
    }, [success, error, dispatch])
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='p-fluid'>
                    <div className="field">

                        <label htmlFor="name1">Title</label>
                        <InputText id="title" name='title' value={formik.values.title} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('title') })} />
                        {getFormErrorMessage('title')}
                    </div>
                    <div className="field">

                        <label htmlFor="name1">Description</label>
                        <InputText id="description" name='description' value={formik.values.description} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('description') })} />
                        {getFormErrorMessage('description')}
                    </div>
                    <div className="field">

                        <label htmlFor="name1">Small Price</label>
                        <InputText id="title" name='small' value={formik.values.small} onChange={formik.handleChange} type="number" className={classNames({ 'p-invalid': isFormFieldValid('small') })} />
                        {getFormErrorMessage('small')}
                    </div>
                    <div className="field">

                        <label htmlFor="name1">Medium</label>
                        <InputText id="cat" name='medium' value={formik.values.medium} onChange={formik.handleChange} type="number" className={classNames({ 'p-invalid': isFormFieldValid('medium') })} />
                        {getFormErrorMessage('medium')}
                    </div>
                    <div className="field">

                        <label htmlFor="name1">Large</label>
                        <InputText id="cat" name='large' value={formik.values.large} onChange={formik.handleChange} type="number" className={classNames({ 'p-invalid': isFormFieldValid('large') })} />
                        {getFormErrorMessage('large')}
                    </div>

                    <div className='flex flex-row justify-content-between'>
                        <h3>Is slot available?</h3>
                        <Checkbox name='enable' checked={formik.values.enable} onChange={formik.handleChange} />

                    </div>
                </div>

                <div className='text-right'><Button loading={loading} type='submit' label='Submit' className='p-button-success' /></div>



            </form>

        </>

    )
}
