import React from 'react'
import { Image } from 'primereact/image';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDate } from '@fullcalendar/core';
import { Fieldset } from 'primereact/fieldset';

import { FILE_BASE_URL } from '../../../config';


export default function GroomingDetailsDialog({ grooming, onHide, isCentered }) {



    function checkInBody(row) {
        return formatDate(row.date);
    }

    function paymentStatusBody(row) {
        return <div>{row.isPaid === true ? 'Paid' : 'Not paid'}</div>;

    }
    function centerProfileImageBody(row) {
        return <Image width='50px' height='50px' src={`${FILE_BASE_URL}${row.centre.profileImage}`} alt='Profile Image' template='Preview' />;

    }

    return (
        <>

            {/* <h3>
                Pet
            </h3>
            <DataTable value={[grooming]} responsiveLayout="scroll">
                <Column field="pet.petName" header="Name"></Column>
                <Column field="pet.petAge" header="Age"></Column>
                <Column field="pet.petWeight" header="Weight"></Column>
                <Column field="pet.vaccinationDate" header="Vaccination date"></Column>
                <Column field="pet.medicalHistory" header="Medical History"></Column>
                <Column field="pet.about" header="About"></Column>
                <Column field="contact.guardian" header="Guardian Contact"></Column>
                <Column field="contact.emergency" header="Emergency Contact"></Column>
            </DataTable> */}


            <Fieldset legend="User info">
                <DataTable value={[grooming]} responsiveLayout="scroll">
                    <Column field="user.firstName" header="First name"></Column>
                    <Column field="user.lastName" header="Last name"></Column>
                    <Column field="user.phone" header="Phone"></Column>

                </DataTable>
            </Fieldset>






            <h3>
                Grooming details
            </h3>
            <DataTable value={[grooming]} responsiveLayout="scroll">
                <Column field="package.title" header="Package title"></Column>
                <Column field="package.description" header="Package desc"></Column>
                <Column field="package.size" header="Package size"></Column>
                <Column field="package.price" header="Package price"></Column>
                <Column field="date" body={checkInBody} header="Date"></Column>
                <Column field="time" header="Time"></Column>

                <Column field="" header="Payment Status" body={paymentStatusBody}></Column>
                <Column field="paymentMethod" header="Method"></Column>

            </DataTable>

            {isCentered === true && <h3>
                Center

            </h3>}
            {isCentered === true && <DataTable value={[grooming]} responsiveLayout="scroll">
                <Column header="Profile" body={centerProfileImageBody}></Column>
                <Column field="centre.name" header="Name"></Column>
                <Column field="centre.phone" header="Phone"></Column>


            </DataTable>}

        </>
    )
}
