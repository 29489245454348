export const GET_ALL_GROOMING_APPOINTMENTS_REQUEST = 'GET_ALL_GROOMING_APPOINTMENTS_REQUEST';
export const GET_ALL_GROOMING_APPOINTMENTS_SUCCESS = 'GET_ALL_GROOMING_APPOINTMENTS_SUCCESS';
export const GET_ALL_GROOMING_APPOINTMENTS_FAILURE = 'GET_ALL_GROOMING_APPOINTMENTS_FAILURE';


export const GET_ALL_BOARDING_APPOINTMENTS_REQUEST = 'GET_ALL_BOARDING_APPOINTMENTS_REQUEST';
export const GET_ALL_BOARDING_APPOINTMENTS_SUCCESS = 'GET_ALL_BOARDING_APPOINTMENTS_SUCCESS';
export const GET_ALL_BOARDING_APPOINTMENTS_FAILURE = 'GET_ALL_BOARDING_APPOINTMENTS_FAILURE';

export const GET_CENTER_GROOMING_APPOINTMENTS_REQUEST = 'GET_CENTER_GROOMING_APPOINTMENTS_REQUEST';
export const GET_CENTER_GROOMING_APPOINTMENTS_SUCCESS = 'GET_CENTER_GROOMING_APPOINTMENTS_SUCCESS';
export const GET_CENTER_GROOMING_APPOINTMENTS_FAILURE = 'GET_CENTER_GROOMING_APPOINTMENTS_FAILURE';


export const GET_CENTER_BOARDING_APPOINTMENTS_REQUEST = 'GET_CENTER_BOARDING_APPOINTMENTS_REQUEST';
export const GET_CENTER_BOARDING_APPOINTMENTS_SUCCESS = 'GET_CENTER_BOARDING_APPOINTMENTS_SUCCESS';
export const GET_CENTER_BOARDING_APPOINTMENTS_FAILURE = 'GET_CENTER_BOARDING_APPOINTMENTS_FAILURE';




