import toastService from "./toast_service";

const appService = {

    showApiResponseStatus: ({status,msg, error,successCB, failCB})=>{
        if (status !== "uninitialized") {
            if (status === "fulfilled") {
                toastService.success(msg);
                if(successCB){
                    successCB();
                }
            } else {
                toastService.error(error);
                if(failCB){
                    failCB();
                }
            }
        }
    },


};

export default appService;
