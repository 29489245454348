import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import React, { useEffect, useRef, useState } from 'react'
import AddOffDaysDialog from './add_offdays_dialog'
import { Toast } from 'primereact/toast';

import {  confirmDialog } from 'primereact/confirmdialog';
import { useDispatch, useSelector } from 'react-redux'
import { EDIT_CENTER_DATA_RESET, GET_CENTER_DETAILS_SUCCESS } from '../../../constants/centers_constants'
import { editCenterData } from '../../../actions/centers_actions'
import { formatDate } from '../../../app/services/time_format_service'


export default function OffDaysTab({offDays}) {
    const editCenterDataReducer = useSelector((state) => state.editCenterData);



    const centerDetails = useSelector((state) => state.centerDetails);
    const { center } = centerDetails;
    const {  success, error, center: updatedCenter } = editCenterDataReducer;

    const dispatch = useDispatch();

    const toast = useRef(null);
    const [offDay,setOffDay]=useState("");



    const [addDialog,setAddDialog]=useState(false);



    const dialogFuncMap = {
      'addDialog': setAddDialog,
   
  }
  const onClick = (name, position) => {
      dialogFuncMap[`${name}`](true);
  
    
  }
  
  
  const onHide = (name) => {
      dialogFuncMap[`${name}`](false);
  }

    const getActionBody=(row)=>{
        console.log(row);
        return <div className='flex flex-row'>
            <Button icon="pi pi-trash" className="p-button-rounded p-button-primary" onClick={()=>{
                 setOffDay(row.day);
                deleteConfirm()}} aria-label="Edit" />
          
        </div>
    }

    useEffect(() => {
        if (success !== undefined) {
            if (success === true) {
                toast.current.show({ severity: 'info', summary: 'Success', detail: 'You have deleted the off day', life: 3000 });
                dispatch({ type: GET_CENTER_DETAILS_SUCCESS, payload: updatedCenter });
            }
        }


        return () => {
            dispatch({ type: EDIT_CENTER_DATA_RESET });
        }
    }, [success, error, dispatch])
 

    const deleteConfirm = () => {
       
        confirmDialog({
            message: 'Do you want to delete this off day?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };


    const accept = () => {    
       
        dispatch(editCenterData({ offDays: center.centre.offDays.filter((item)=>item!==offDay), centerId: center.centre._id }));

    }

    const reject = () => {
    }
    

  return (
  
   <div>
                   <Toast ref={toast} />

           <div className='flex flex-row justify-content-between align-content-center align-items-center pb-3'>
                    <h5></h5>
                    <Button label='Add new' className='p-button-success p-button-rounded p-button-sm' onClick={() => onClick('addDialog')} />



                </div>

                <Dialog header='Edit' visible={addDialog} onHide={() => onHide('addDialog')}  breakpoints={{ '960px': '40vw', '640px': '100vw' }} style={{ width: '70vw' }}>
                <AddOffDaysDialog  onHide={()=>onHide('addDialog')} />
            </Dialog>


        <DataTable value={offDays.map((e,i)=>({day:e,index:i+1}))} paginator className="p-datatable-gridlines" showGridlines rows={10}
    dataKey="id" filterDisplay="menu" responsiveLayout="scroll"
    emptyMessage="No data found.">
    <Column field="index" header="Sr."   style={{ minWidth: '12rem' }} />
    <Column field="day" header="Date" body={(row)=><div>{formatDate(row.day)}</div>} filter filterPlaceholder="Search by date" style={{ minWidth: '12rem' }} />

    <Column  header="Actions" body={getActionBody}  style={{ minWidth: '12rem' }} />



</DataTable>
   </div>

  )
}
