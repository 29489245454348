import { GET_DASHBOARD_STATS_FAILURE, GET_DASHBOARD_STATS_REQUEST, GET_DASHBOARD_STATS_SUCCESS } from "../constants/dashboard_constants";

function getDashboardStatsReducer(state = {}, action) {
    switch (action.type) {
      case GET_DASHBOARD_STATS_REQUEST:
        return { loading: true };
      case GET_DASHBOARD_STATS_SUCCESS:
          console.log(action.payload);
        return { loading: false, data: action.payload };
      case GET_DASHBOARD_STATS_FAILURE:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

  export {getDashboardStatsReducer}