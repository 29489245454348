const primeIcons = {
    "pi-align-center": "pi pi-align-center",
    "pi-align-justify": "pi pi-align-justify",
    "pi-align-left": "pi pi-align-left",
    "pi-align-right": "pi pi-align-right",
    "pi-amazon": "pi pi-amazon",
    "pi-android": "pi pi-android",
    "pi-angle-double-down": "pi pi-angle-double-down",
    "pi-angle-double-left": "pi pi-angle-double-left",
    "pi-angle-double-right": "pi pi-angle-double-right",
    "pi-angle-double-up": "pi pi-angle-double-up",
    "pi-angle-down": "pi pi-angle-down",
    "pi-angle-left": "pi pi-angle-left",
    "pi-angle-right": "pi pi-angle-right",
    "pi-angle-up": "pi pi-angle-up",
    "pi-apple": "pi pi-apple",
    "pi-arrow-circle-down": "pi pi-arrow-circle-down",
    "pi-arrow-circle-left": "pi pi-arrow-circle-left",
    "pi-arrow-circle-right": "pi pi-arrow-circle-right",
    "pi-arrow-circle-up": "pi pi-arrow-circle-up",
    "pi-arrow-down": "pi pi-arrow-down",
    "pi-arrow-down-left": "pi pi-arrow-down-left",
    "pi-arrow-down-right": "pi pi-arrow-down-right",
    "pi-arrow-left": "pi pi-arrow-left",
    "pi-arrow-right": "pi pi-arrow-right",
    "pi-arrow-up": "pi pi-arrow-up",
    "pi-arrow-up-left": "pi pi-arrow-up-left",
    "pi-arrow-up-right": "pi pi-arrow-up-right",
    "pi-arrows-h": "pi pi-arrows-h",
    "pi-arrows-v": "pi pi-arrows-v",
    "pi-at": "pi pi-at",
    "pi-backward": "pi pi-backward",
    "pi-ban": "pi pi-ban",
    "pi-bars": "pi pi-bars",
    "pi-bell": "pi pi-bell",
    "pi-bolt": "pi pi-bolt",
    "pi-book": "pi pi-book",
    "pi-bookmark": "pi pi-bookmark",
    "pi-bookmark-fill": "pi pi-bookmark-fill",
    "pi-box": "pi pi-box",
    "pi-briefcase": "pi pi-briefcase",
    "pi-building": "pi pi-building",
    "pi-calendar": "pi pi-calendar",
    "pi-calendar-minus": "pi pi-calendar-minus",
    "pi-calendar-plus": "pi pi-calendar-plus",
    "pi-calendar-times": "pi pi-calendar-times",
    "pi-camera": "pi pi-camera",
    "pi-car": "pi pi-car",
    "pi-caret-down": "pi pi-caret-down",
    "pi-caret-left": "pi pi-caret-left",
    "pi-caret-right": "pi pi-caret-right",
    "pi-caret-up": "pi pi-caret-up",
    "pi-chart-bar": "pi pi-chart-bar",
    "pi-chart-line": "pi pi-chart-line",
    "pi-chart-pie": "pi pi-chart-pie",
    "pi-check": "pi pi-check",
    "pi-check-circle": "pi pi-check-circle",
    "pi-check-square": "pi pi-check-square",
    "pi-chevron-circle-down": "pi pi-chevron-circle-down",
    "pi-chevron-circle-left": "pi pi-chevron-circle-left",
    "pi-chevron-circle-right": "pi pi-chevron-circle-right",
    "pi-chevron-circle-up": "pi pi-chevron-circle-up",
    "pi-chevron-down": "pi pi-chevron-down",
    "pi-chevron-left": "pi pi-chevron-left",
    "pi-chevron-right": "pi pi-chevron-right",
    "pi-chevron-up": "pi pi-chevron-up",
    "pi-circle": "pi pi-circle",
    "pi-circle-fill": "pi pi-circle-fill",
    "pi-clock": "pi pi-clock",
    "pi-clone": "pi pi-clone",
    "pi-cloud": "pi pi-cloud",
    "pi-cloud-download": "pi pi-cloud-download",
    "pi-cloud-upload": "pi pi-cloud-upload",
    "pi-code": "pi pi-code",
    "pi-cog": "pi pi-cog",
    "pi-comment": "pi pi-comment",
    "pi-comments": "pi pi-comments",
    "pi-compass": "pi pi-compass",
    "pi-copy": "pi pi-copy",
    "pi-credit-card": "pi pi-credit-card",
    "pi-database": "pi pi-database",
    "pi-desktop": "pi pi-desktop",
    "pi-directions": "pi pi-directions",
    "pi-directions-alt": "pi pi-directions-alt",
    "pi-discord": "pi pi-discord",
    "pi-dollar": "pi pi-dollar",
    "pi-download": "pi pi-download",
    "pi-eject": "pi pi-eject",
    "pi-ellipsis-h": "pi pi-ellipsis-h",
    "pi-ellipsis-v": "pi pi-ellipsis-v",
    "pi-envelope": "pi pi-envelope",
    "pi-euro": "pi pi-euro",
    "pi-exclamation-circle": "pi pi-exclamation-circle",
    "pi-exclamation-triangle": "pi pi-exclamation-triangle",
    "pi-external-link": "pi pi-external-link",
    "pi-eye": "pi pi-eye",
    "pi-eye-slash": "pi pi-eye-slash",
    "pi-facebook": "pi pi-facebook",
    "pi-fast-backward": "pi pi-fast-backward",
    "pi-fast-forward": "pi pi-fast-forward",
    "pi-file": "pi pi-file",
    "pi-file-excel": "pi pi-file-excel",
    "pi-file-pdf": "pi pi-file-pdf",
    "pi-filter": "pi pi-filter",
    "pi-filter-fill": "pi pi-filter-fill",
    "pi-filter-slash": "pi pi-filter-slash",
    "pi-flag": "pi pi-flag",
    "pi-flag-fill": "pi pi-flag-fill",
    "pi-folder": "pi pi-folder",
    "pi-folder-open": "pi pi-folder-open",
    "pi-forward": "pi pi-forward",
    "pi-github": "pi pi-github",
    "pi-globe": "pi pi-globe",
    "pi-google": "pi pi-google",
    "pi-hashtag": "pi pi-hashtag",
    "pi-heart": "pi pi-heart",
    "pi-heart-fill": "pi pi-heart-fill",
    "pi-history": "pi pi-history",
    "pi-home": "pi pi-home",
    "pi-id-card": "pi pi-id-card",
    "pi-image": "pi pi-image",
    "pi-images": "pi pi-images",
    "pi-inbox": "pi pi-inbox",
    "pi-info": "pi pi-info",
    "pi-info-circle": "pi pi-info-circle",
    "pi-instagram": "pi pi-instagram",
    "pi-key": "pi pi-key",
    "pi-link": "pi pi-link",
    "pi-linkedin": "pi pi-linkedin",
    "pi-list": "pi pi-list",
    "pi-lock": "pi pi-lock",
    "pi-lock-open": "pi pi-lock-open",
    "pi-map": "pi pi-map",
    "pi-map-marker": "pi pi-map-marker",
    "pi-microsoft": "pi pi-microsoft",
    "pi-minus": "pi pi-minus",
    "pi-minus-circle": "pi pi-minus-circle",
    "pi-mobile": "pi pi-mobile",
    "pi-money-bill": "pi pi-money-bill",
    "pi-moon": "pi pi-moon",
    "pi-palette": "pi pi-palette",
    "pi-paperclip": "pi pi-paperclip",
    "pi-pause": "pi pi-pause",
    "pi-paypal": "pi pi-paypal",
    "pi-pencil": "pi pi-pencil",
    "pi-percentage": "pi pi-percentage",
    "pi-phone": "pi pi-phone",
    "pi-play": "pi pi-play",
    "pi-plus": "pi pi-plus",
    "pi-plus-circle": "pi pi-plus-circle",
    "pi-pound": "pi pi-pound",
    "pi-power-off": "pi pi-power-off",
    "pi-prime": "pi pi-prime",
    "pi-print": "pi pi-print",
    "pi-qrcode": "pi pi-qrcode",
    "pi-question": "pi pi-question",
    "pi-question-circle": "pi pi-question-circle",
    "pi-reddit": "pi pi-reddit",
    "pi-refresh": "pi pi-refresh",
    "pi-replay": "pi pi-replay",
    "pi-reply": "pi pi-reply",
    "pi-save": "pi pi-save",
    "pi-search": "pi pi-search",
    "pi-search-minus": "pi pi-search-minus",
    "pi-search-plus": "pi pi-search-plus",
    "pi-send": "pi pi-send",
    "pi-server": "pi pi-server",
    "pi-share-alt": "pi pi-share-alt",
    "pi-shield": "pi pi-shield",
    "pi-shopping-bag": "pi pi-shopping-bag",
    "pi-shopping-cart": "pi pi-shopping-cart",
    "pi-sign-in": "pi pi-sign-in",
    "pi-sign-out": "pi pi-sign-out",
    "pi-sitemap": "pi pi-sitemap",
    "pi-slack": "pi pi-slack",
    "pi-sliders-h": "pi pi-sliders-h",
    "pi-sliders-v": "pi pi-sliders-v",
    "pi-sort": "pi pi-sort",
    "pi-sort-alpha-down": "pi pi-sort-alpha-down",
    "pi-sort-alpha-down-alt": "pi pi-sort-alpha-down-alt",
    "pi-sort-alpha-up": "pi pi-sort-alpha-up",
    "pi-sort-alpha-up-alt": "pi pi-sort-alpha-up-alt",
    "pi-sort-alt": "pi pi-sort-alt",
    "pi-sort-alt-slash": "pi pi-sort-alt-slash",
    "pi-sort-amount-down": "pi pi-sort-amount-down",
    "pi-sort-amount-down-alt": "pi pi-sort-amount-down-alt",
    "pi-sort-amount-up": "pi pi-sort-amount-up",
    "pi-sort-amount-up-alt": "pi pi-sort-amount-up-alt",
    "pi-sort-down": "pi pi-sort-down",
    "pi-sort-numeric-down": "pi pi-sort-numeric-down",
    "pi-sort-numeric-down-alt": "pi pi-sort-numeric-down-alt",
    "pi-sort-numeric-up": "pi pi-sort-numeric-up",
    "pi-sort-numeric-up-alt": "pi pi-sort-numeric-up-alt",
    "pi-sort-up": "pi pi-sort-up",
    "pi-spinner": "pi pi-spinner",
    "pi-star": "pi pi-star",
    "pi-star-fill": "pi pi-star-fill",
    "pi-step-backward": "pi pi-step-backward",
    "pi-step-backward-alt": "pi pi-step-backward-alt",
    "pi-step-forward": "pi pi-step-forward",
    "pi-step-forward-alt": "pi pi-step-forward-alt",
    "pi-stop": "pi pi-stop",
    "pi-stop-circle": "pi pi-stop-circle",
    "pi-sun": "pi pi-sun",
    "pi-sync": "pi pi-sync",
    "pi-table": "pi pi-table",
    "pi-tablet": "pi pi-tablet",
    "pi-tag": "pi pi-tag",
    "pi-tags": "pi pi-tags",
    "pi-telegram": "pi pi-telegram",
    "pi-th-large": "pi pi-th-large",
    "pi-thumbs-down": "pi pi-thumbs-down",
    "pi-thumbs-up": "pi pi-thumbs-up",
    "pi-ticket": "pi pi-ticket",
    "pi-times": "pi pi-times",
    "pi-times-circle": "pi pi-times-circle",
    "pi-trash": "pi pi-trash",
    "pi-twitter": "pi pi-twitter",
    "pi-undo": "pi pi-undo",
    "pi-unlock": "pi pi-unlock",
    "pi-upload": "pi pi-upload",
    "pi-user": "pi pi-user",
    "pi-user-edit": "pi pi-user-edit",
    "pi-user-minus": "pi pi-user-minus",
    "pi-user-plus": "pi pi-user-plus",
    "pi-users": "pi pi-users",
    "pi-video": "pi pi-video",
    "pi-vimeo": "pi pi-vimeo",
    "pi-volume-down": "pi pi-volume-down",
    "pi-volume-off": "pi pi-volume-off",
    "pi-volume-up": "pi pi-volume-up",
    "pi-wallet": "pi pi-wallet",
    "pi-whatsapp": "pi pi-whatsapp",
    "pi-wifi": "pi pi-wifi",
    "pi-window-maximize": "pi pi-window-maximize",
    "pi-window-minimize": "pi pi-window-minimize",
    "pi-youtube": "pi pi-youtube"
  }

  export {primeIcons}