import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL, // Replace with your API base URL
    timeout: 5000, // Set a timeout for requests (optional)
});

const handleLoading = (loadingCallback) => (isLoading) => {
    if (loadingCallback) {
        loadingCallback(isLoading);
    }
};

// Function to handle error state
const handleError = (isErrorCallback, errorCallback) => (error) => {
    if (isErrorCallback) {
        isErrorCallback(true);
    }
    if (errorCallback) {
        errorCallback(error);
    }
};

// GET request
const apiGet = async (url, loadingCallback, isErrorCallback, errorCallback) => {
    handleLoading(loadingCallback)(true);
    try {
        const response = await apiClient.get(url);
        return response.data;
    } catch (error) {
        handleError(isErrorCallback, errorCallback)(error);
        throw error;
    } finally {
        handleLoading(loadingCallback)(false);
    }
};

// POST request
const apiPost = async (url, data, loadingCallback, isErrorCallback, errorCallback) => {
    handleLoading(loadingCallback)(true);
    try {
        const response = await apiClient.post(url, data);
        return response.data;
    } catch (error) {
        handleError(isErrorCallback, errorCallback)(error);
        throw error;
    } finally {
        handleLoading(loadingCallback)(false);
    }
};

const apiPut = async (url, data, loadingCallback, isErrorCallback, errorCallback) => {
    handleLoading(loadingCallback)(true);
    try {
        const response = await apiClient.put(url, data);
        return response.data;
    } catch (error) {
        handleError(isErrorCallback, errorCallback)(error);
        throw error;
    } finally {
        handleLoading(loadingCallback)(false);
    }
};

// DELETE request
const apiDelete = async (url, loadingCallback, isErrorCallback, errorCallback) => {
    handleLoading(loadingCallback)(true);
    try {
        const response = await apiClient.delete(url);
        return response.data;
    } catch (error) {
        handleError(isErrorCallback, errorCallback)(error);
        throw error;
    } finally {
        handleLoading(loadingCallback)(false);
    }
};

export { apiGet, apiPost, apiDelete, apiPut };
