import * as consts from './../constants/appointments_constants';

function getAllGroomingAppointments(state = {}, action) {
    switch (action.type) {
      case consts.GET_ALL_GROOMING_APPOINTMENTS_REQUEST:
        return { loading: true };
      case consts.GET_ALL_GROOMING_APPOINTMENTS_SUCCESS:
        return { loading: false, list: action.payload };
      case consts.GET_ALL_GROOMING_APPOINTMENTS_FAILURE:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }
function getAllBoardingAppointments(state = {}, action) {
    switch (action.type) {
      case consts.GET_ALL_BOARDING_APPOINTMENTS_REQUEST:
        return { loading: true };
      case consts.GET_ALL_BOARDING_APPOINTMENTS_SUCCESS:
        return { loading: false, list: action.payload };
      case consts.GET_ALL_BOARDING_APPOINTMENTS_FAILURE:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }
function getCenterGroomingAppointments(state = {}, action) {
    switch (action.type) {
      case consts.GET_CENTER_GROOMING_APPOINTMENTS_REQUEST:
        return { loading: true };
      case consts.GET_CENTER_GROOMING_APPOINTMENTS_SUCCESS:
        return { loading: false, list: action.payload };
      case consts.GET_CENTER_GROOMING_APPOINTMENTS_FAILURE:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }
function getCenterBoardingAppointments(state = {}, action) {
    switch (action.type) {
      case consts.GET_CENTER_BOARDING_APPOINTMENTS_REQUEST:
        return { loading: true };
      case consts.GET_CENTER_BOARDING_APPOINTMENTS_SUCCESS:
        return { loading: false, list: action.payload };
      case consts.GET_CENTER_BOARDING_APPOINTMENTS_FAILURE:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

export {getAllBoardingAppointments,getAllGroomingAppointments,getCenterGroomingAppointments,getCenterBoardingAppointments}