import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react'
import EditTimingDialog from './edit_timing_dialog';

export default function TimingsTabs({timings}) {
  const [editDialog,setEditDialog]=useState(false);
  const [slot,setSlot]=useState(null);
 
  

  const dialogFuncMap = {
      'editDialog': setEditDialog,
   
  }
  const onClick = (name, position) => {
      dialogFuncMap[`${name}`](true);

    
  }


  const onHide = (name) => {
      dialogFuncMap[`${name}`](false);
  }



    const getActiveStatusBody=(row)=>{
        return <span>{row.active===true?'Active':'Inactive'}</span>;
    }
   
    const getActionBody=(row)=>{
        return <div className='flex flex-row'>
            <Button icon="pi pi-user-edit" className="p-button-rounded p-button-primary" onClick={()=>{
              setSlot(row);
              onClick('editDialog');
             
                         
                            }} aria-label="Details" />
          
        </div>
    }

    

   

  return (
    <div>

<Dialog header='Edit' visible={editDialog} onHide={() => onHide('editDialog')}  breakpoints={{ '960px': '40vw', '640px': '100vw' }} style={{ width: '70vw' }}>
                <EditTimingDialog slot={slot} onHide={()=>onHide('editDialog')} />
            </Dialog>
            <DataTable value={timings} paginator className="p-datatable-gridlines" showGridlines rows={10}
    dataKey="id" filterDisplay="menu" responsiveLayout="scroll"
    emptyMessage="No centers found.">
    <Column field="slot" header="Name" filter filterPlaceholder="Search by slot" style={{ minWidth: '12rem' }} />
    <Column field="active" header="Active" filter filterPlaceholder="Search by timing" body={getActiveStatusBody} style={{ minWidth: '12rem' }} />
    <Column  header="Actions" body={getActionBody}  style={{ minWidth: '12rem' }} />



</DataTable>


    </div>
  
   

  )
}
