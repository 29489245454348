import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';

import React, { useState } from 'react'
import EditDogGroomingDialog from './edit_dog_grooming_dialog';

export default function DogGroomingTab({ groomings }) {
  const [grooming, setGrooming] = useState(null);
  const [editDialog, setEditDialog] = useState(false);



  const dialogFuncMap = {
    'editDialog': setEditDialog,

  }
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);


  }


  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  }


  const getActiveStatusBody = (row) => {
    return <span>{row.enable === true ? 'Active' : 'Inactive'}</span>;
  }

  const getActionBody = (row) => {
    return <div className='flex flex-row'>
      <Button icon="pi pi-user-edit" className="p-button-rounded p-button-primary" onClick={() => {

        setGrooming(row);
        onClick('editDialog');
      }} aria-label="Edit" />

    </div>
  }

  return (
    <div>
      <Dialog header='Edit' visible={editDialog} onHide={() => onHide('editDialog')} breakpoints={{ '960px': '40vw', '640px': '100vw' }} style={{ width: '70vw' }}>
        <EditDogGroomingDialog grooming={grooming} onHide={() => onHide('editDialog')} />
      </Dialog>
      <DataTable value={groomings} paginator className="p-datatable-gridlines" showGridlines rows={10}
        dataKey="id" filterDisplay="menu" responsiveLayout="scroll"
        emptyMessage="No data found.">
        <Column field="title" header="Name" filter filterPlaceholder="Search by slot" style={{ minWidth: '12rem' }} />
        <Column field="description" header="Description" filter filterPlaceholder="Search by description" style={{ minWidth: '12rem' }} />
        <Column field="dogPrice.smallRange" header="Small dog price" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
        <Column field="dogPrice.mediumRange" header="Medium dog price" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
        <Column field="dogPrice.largeRange" header="Large dog price" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
        <Column field="enable" header="Active" filter filterPlaceholder="Search by timing" body={getActiveStatusBody} style={{ minWidth: '12rem' }} />
        <Column header="Actions" body={getActionBody} style={{ minWidth: '12rem' }} />



      </DataTable>
    </div>



  )
}
