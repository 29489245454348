import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import CustomInputField from '../../../app/components/custom_input_field';
import toastService from '../../../app/services/toast_service';
import appService from '../../../app/services/app_service';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useAddNewOrganizerMutation, useUpdateOrganizerMutation } from '../manager/events_service';
import MulitiInputField from '../../../app/components/multiple_input_field';
import { toast } from 'react-toastify';

export default function AddEditOrganizerDialog({ editData, onHide }) {


    const [profileImage, setProfileImage] = useState(null);
    const [photos, setPhotos] = useState([]);


    const [addOrganizer, { isLoading: addLoading, error: addError, status: addStatus }] = useAddNewOrganizerMutation();
    const [updateOrganizer, { isLoading: editLoading, error: editError, status: updateStatus }] = useUpdateOrganizerMutation();


    const [phones, setPhones] = useState([]);

    async function readFileAsDataURL(file) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });



        return result_base64;
    }



    useEffect(() => {

        appService.showApiResponseStatus(
            {
                status: addStatus,
                msg: "Added successfully!",
                error: addError,
                successCB: () => {
                    formik.resetForm();
                    onHide();
                }
            }
        );
        appService.showApiResponseStatus(
            {
                status: updateStatus,
                msg: "Updated successfully!",
                error: editError,
                successCB: () => {
                    formik.resetForm();
                    onHide();
                }
            }
        );


    }, [addStatus, updateStatus]);


    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string().email().required("Email is required"),
        walletNumber: Yup.string().required("Wallet number is required"),
        walletTitle: Yup.string().required("Wallet title is required"),



    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            email: "",
            description: "",
            walletNumber: "",
            walletTitle: ""

        },
        onSubmit: async (data) => {

            if (editData === null) {

                if (profileImage === null) {
                    toastService.info("Please choose a profile image")
                    return;
                }
                let profileImgBase64 = await readFileAsDataURL(profileImage);
                data['profile'] = profileImgBase64;



                addOrganizer({
                    "phone": phones,
                    "wallet": [
                        {
                            "walletNumber": data.walletNumber,
                            "title": data.walletTitle
                        }
                    ],
                    ...data,
                });
            } else {
                if (profileImage != null) {
                    let profileImgBase64 = await readFileAsDataURL(profileImage);
                    data['profile'] = profileImgBase64;
                }

                updateOrganizer({
                    ...data,
                    "phone": phones,
                    "wallet": [
                        {
                            "walletNumber": data.walletNumber,
                            "title": data.walletTitle
                        }
                    ],
                    "id": editData._id
                })
            }

        },
    });

    useEffect(() => {
        if (editData != null) {
            console.log(editData.wallet);
            formik.setFieldValue("name", editData.name);
            formik.setFieldValue("email", editData.email);
            formik.setFieldValue("description", editData.description);
            formik.setFieldValue("walletTitle", editData.wallet[0]?.title);
            formik.setFieldValue("walletNumber", editData.wallet[0]?.walletNumber);
            setPhones(editData?.phone ?? []);
        }
    }, [])





    return (
        <>
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="col-12 md:col-6\8">
                        <div className=" p-fluid">

                            <CustomInputField
                                type='text'
                                iden='name'
                                formik={formik}
                                placeHolder="Enter name" />

                            <CustomInputField
                                type='text'
                                iden='email'
                                formik={formik}
                                placeHolder="Enter email" />

                            <CustomInputField
                                type='textarea'
                                iden='description'
                                formik={formik}
                                placeHolder="Details" />

                            <MulitiInputField
                                name="phone"
                                placeHolder={"Provide phone numbers"}
                                defaultValues={editData?.phone ?? []}
                                onChange={(values) => {
                                    console.log(values);
                                    setPhones(values)
                                }} />

                            <CustomInputField
                                type='file'
                                iden='profile'
                                accept={"image/png, image/jpeg, image/jpg"}
                                placeHolder="Details"
                                onChange={(e) => {
                                    setProfileImage(e.target.files[0]);
                                }}
                            />

                            <CustomInputField
                                type='text'
                                iden='walletTitle'
                                formik={formik}
                                placeHolder="Enter wallet title" />

                            <CustomInputField
                                type='text'
                                iden='walletNumber'
                                formik={formik}
                                placeHolder="Enter wallet number" />


                            <Button
                                loading={editData === null ? addLoading : editLoading}
                                type='submit'
                                label='Submit'
                                className='p-button-success' />

                        </div>
                    </div>

                </form>
            </div>
        </>
    )
}
