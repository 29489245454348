import React, { useEffect } from 'react'
import CustomInputField from '../../../app/components/custom_input_field';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import { useGetDoctorDetailsQuery, useUpdateDoctorPricingMutation } from '../manager/doctors_services';
import appService from '../../../app/services/app_service';
export default function DoctorRates({id}) {



    const [updateDoctor, {error: updateError, status : updateStatus,isLoading}] = useUpdateDoctorPricingMutation();

    const { data: doctor} = useGetDoctorDetailsQuery(id);

    const validationSchema = Yup.object().shape({
        morning_audio: Yup.number().required("Morning audio rate is required"),
        evening_audio: Yup.number().required("Evening audio rate is required"),
        morning_video: Yup.number().required("Morning video rate is required"),
        evening_video: Yup.number().required("Evening video rate is required"),
    });

    useEffect(()=>{
        appService.showApiResponseStatus(
            {
                status: updateStatus,
                msg: "Updated successfully!",
                error: updateError,
                successCB: () => {
                 
                }
            }
        );
    },[updateStatus])

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            morning_audio: 0,
            evening_audio: 0,
            morning_video: 0,
            evening_video: 0
        },
        onSubmit: async (data) => {
            let docId = doctor.data._id;
            let newData = {
                "id": docId,
                "pricing": {
                    "sessions": {
                        "morning": {
                            "audioPrice": data.morning_audio,
                            "videoPrice": data.morning_video
                        },
                        "evening": {
                            "audioPrice": data.evening_audio,
                            "videoPrice": data.evening_video
                        }
                    }
                }
            };

            updateDoctor(newData);


        },
    });

    useEffect(() => {
        if (doctor != null) {
            let pricing = doctor.data.pricing[0]?.sessions;
            if(pricing){
                formik.setFieldValue("morning_audio", pricing.morning.audioPrice)
                formik.setFieldValue("evening_audio", pricing.evening.audioPrice)
                formik.setFieldValue("morning_video", pricing.morning.videoPrice)
                formik.setFieldValue("evening_video", pricing.evening.videoPrice)
            }

            console.log(pricing);
        }
    }, [doctor])
  return (
    <div className='card'>

    <form onSubmit={formik.handleSubmit}>
        <div className="col-12 md:col-6\8">
            <div className=" p-fluid">


                <div className='flex flex-row justify-content-between align-content-center align-items-center pb-3'>
                    <h4>
                        Pricings
                    </h4>
                    <div>
                        <Button loading={isLoading} label="Update" className='p-button-success p-button-rounded p-button-sm' type="submit" />

                    </div>

                </div>
                <h5>Audio calling Rates</h5>
                <div className="p-fluid formgrid grid  justify-content-center align-content-center align-items-center">
                    <CustomInputField type='number' formik={formik} label="Morning" iden="morning_audio" placeHolder="Enter morning audio call rate" className="col-12 md:col-6" />
                    <CustomInputField type='number' formik={formik} label="Evening"  iden="evening_audio" placeHolder="Enter morning audio call rate" className="col-12 md:col-6" />
                </div>
                <h5>Video calling Rates</h5>
                <div className="p-fluid formgrid grid  justify-content-center align-content-center align-items-center">
                    <CustomInputField type='number' formik={formik} label="Morning"  iden="morning_video" placeHolder="Enter morning audio call rate" className="col-12 md:col-6" />
                    <CustomInputField type='number' formik={formik} label="Evening" iden="evening_video" placeHolder="Enter morning audio call rate" className="col-12 md:col-6" />
                </div>

            </div>
        </div>

    </form>


</div>
  )
}
