import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Column } from 'primereact/column';
import { useHistory } from "react-router-dom";

import { getCentersList } from '../../../actions/centers_actions';
import { Dialog } from 'primereact/dialog';
import AddEditCenterDialog from '../componenets/add_center_dialog';
import { Button } from 'primereact/button';
import { FILE_BASE_URL } from '../../../config';

export default function CentersScreen() {

    let history = useHistory();

    const getCenters = useSelector((state) => state.getCenters);
    const { centers, loading } = getCenters;
    const dispatch = useDispatch();
    const [addDialog, setAddDialog] = useState(false);
    const [editCenter, setEditCenter] = useState(null);

    useEffect(() => {
        dispatch(getCentersList());
    }, [dispatch])


    const dialogFuncMap = {
        'showAddDialog': setAddDialog,

    }
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);


    }


    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const profileImageBody = (row) => {
        return <div ><img alt='Not found' style={{ width: '100px', height: '60px', objectFit: 'contain' }} src={FILE_BASE_URL + row.profileImage} /> </div>;
    }

    const centerActionsBody = (row) => {
        return <div className='flex flex-row'>
            <Button icon="pi pi-user-edit" className="p-button-rounded p-button-primary" onClick={() => {
                setEditCenter(row);
                onClick('showAddDialog');


            }} aria-label="Details" />
            <Button icon="pi pi-arrow-right" className="ml-2 p-button-rounded p-button-primary" onClick={() => {
                history.push(`/api/centers/${row._id}`)

            }} aria-label="Details" />
            <Button tooltip='Appointments' icon="pi pi-table" className="ml-2 p-button-rounded p-button-primary" onClick={() => {
                history.push(`/api/centers/appointments/${row._id}`)

            }} aria-label="Details" />
        </div>
    }
    return (
        <>
            <div className='card'>

                <Dialog header='Add new center' visible={addDialog} onHide={() => onHide('showAddDialog')} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '70vw' }}>
                    <AddEditCenterDialog editCenter={editCenter} onHide={() => onHide('showAddDialog')} />
                </Dialog>

                <div className='flex flex-row justify-content-between align-content-center align-items-center pb-3'>
                    <h5>Centers</h5>
                    <Button label='Add new' className='p-button-success p-button-rounded p-button-sm' onClick={() => {
                        setEditCenter(null);
                        onClick('showAddDialog')

                    }

                    } />



                </div>
                <DataTable value={centers} paginator className="p-datatable-gridlines" showGridlines rows={10}
                    dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll"
                    emptyMessage="No centers found.">
                    <Column field="name" header="Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="owner" header="Owner" filter filterPlaceholder="Search by owner" style={{ minWidth: '12rem' }} />
                    <Column field="phone" header="Phone" filter filterPlaceholder="Search by phone" style={{ minWidth: '12rem' }} />
                    <Column header="Profile" filterPlaceholder="Search by owner" body={profileImageBody} style={{ minWidth: '12rem' }} />
                    <Column header="Actions" filterPlaceholder="Search by owner" body={centerActionsBody} style={{ minWidth: '12rem' }} />


                </DataTable>
            </div>

        </>
    )
}
