import { GET_CENTER_DETAILS_FAILURE, GET_CENTER_DETAILS_REQUEST, GET_CENTER_DETAILS_SUCCESS } from "../constants/centers_constants";
import { ADD_ADMIN_USER_FAILURE, ADD_ADMIN_USER_REQUEST, ADD_ADMIN_USER_SUCCESS, EDIT_ADMIN_USER_FAILURE, EDIT_ADMIN_USER_REQUEST, EDIT_ADMIN_USER_SUCCESS, GET_ADMIN_USERS_FAILURE, GET_ADMIN_USERS_REQUEST, GET_ADMIN_USERS_SUCCESS, GET_APP_USERS_FAILURE, GET_APP_USERS_REQUEST, GET_APP_USERS_SUCCESS, GET_ROLES_FAILURE, GET_ROLES_REQUEST, GET_ROLES_SUCCESS, LOGIN_USER_FAILURE, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGOUT_USER } from "../constants/user_constants";
import { BASE_URL } from './../config';
import { axiosApi } from "../app/services/axios_api";


const getAdminUsers = () => async (dispatch, getState) => {
    dispatch({ type: GET_ADMIN_USERS_REQUEST });

    const url = BASE_URL + 'api/admin/user/all';
    console.log(url);
    try {
        const { data } = await axiosApi.get(url
        );
        dispatch({ type: GET_ADMIN_USERS_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({ type: GET_ADMIN_USERS_FAILURE, payload: error.message });
    }
}
const loginAdminUser = (body) => async (dispatch, getState) => {
    dispatch({ type: LOGIN_USER_REQUEST });

    const url = BASE_URL + 'api/admin/user/login';
    console.log(url);
    try {
        const { data } = await axiosApi.post(url,
            body
        );
        console.log("HERE IAM");
        console.log(data);

        localStorage.setItem("user",JSON.stringify(data.data ));
        // localStorage.setItem("accessToken",data.data.token);
        dispatch({ type: LOGIN_USER_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: LOGIN_USER_FAILURE, payload: error.response && error.response.data.msg
                ? error.response.data.msg
                : error.message
        });
    }
}
const getUserRoles = () => async (dispatch, getState) => {
    dispatch({ type: GET_ROLES_REQUEST });

    const url = BASE_URL + 'api/admin/role/all';
    console.log(url);
    try {
        const { data } = await axiosApi.get(url
        );
        dispatch({ type: GET_ROLES_SUCCESS, payload: data.data });
    } catch (error) {
        dispatch({ type: GET_ROLES_FAILURE, payload: error.message });
    }
}

const addAdminUser = (body) => async (dispatch, getState) => {
    dispatch({ type: ADD_ADMIN_USER_REQUEST });

    const url = BASE_URL + 'api/admin/user';
    console.log(url);
    try {
        const { data } = await axiosApi.post(url,
            body
        );
        console.log(data);

        dispatch({ type: ADD_ADMIN_USER_SUCCESS, payload: data.data });
    } catch (error) {
        console.log(error.message);
        dispatch({ type: ADD_ADMIN_USER_FAILURE, payload: error.message });
    }
}
const editAdminUser = (body) => async (dispatch, getState) => {
    dispatch({ type: EDIT_ADMIN_USER_REQUEST });

    const url = BASE_URL + 'api/admin/user';
    console.log(url);
    try {
        const { data } = await axiosApi.put(url,
            body
        );
        console.log(data);

        dispatch({ type: EDIT_ADMIN_USER_SUCCESS, payload: data.data });
    } catch (error) {
        console.log(error.message);
        dispatch({ type: EDIT_ADMIN_USER_FAILURE, payload: error.message });
    }
}
const getAppUsers = () => async (dispatch, getState) => {
    dispatch({ type: GET_APP_USERS_REQUEST });

    const url = BASE_URL + 'api/user/all';
    console.log(url);
    try {
        const { data } = await axiosApi.get(url,

        );
        console.log(data);

        dispatch({ type: GET_APP_USERS_SUCCESS, payload: data.data });
    } catch (error) {
        console.log(error.message);
        dispatch({ type: GET_APP_USERS_FAILURE, payload: error.message });
    }
}
const getCenterDetails = (centerId) => async (dispatch, getState) => {
    dispatch({ type: GET_CENTER_DETAILS_REQUEST });

    const url = BASE_URL + 'api/centre/?centreId='+centerId;
    console.log(url);
    try {
        const { data } = await axiosApi.get(url,

        );
        console.log(data);

        dispatch({ type: GET_CENTER_DETAILS_SUCCESS, payload: data.data });
    } catch (error) {
        console.log(error.message);
        dispatch({ type: GET_CENTER_DETAILS_FAILURE, payload: error.message });
    }
}

// const update = ({ userId, name, email, password }) => async (dispatch, getState) => {
//   const { userSignin: { userInfo } } = getState();
//   dispatch({ type: USER_UPDATE_REQUEST, payload: { userId, name, email, password } });
//   try {
//     const { data } = await Axios.put("/api/users/" + userId,
//       { name, email, password }, {
//       headers: {
//         Authorization: 'Bearer ' + userInfo.token
//       }
//     });
//     dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
//     Cookie.set('userInfo', JSON.stringify(data));
//   } catch (error) {
//     dispatch({ type: USER_UPDATE_FAIL, payload: error.message });
//   }
// }

// const signin = (email, password) => async (dispatch) => {
//   dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
//   try {
//     const { data } = await Axios.post("/api/users/signin", { email, password });
//     dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
//     Cookie.set('userInfo', JSON.stringify(data));
//   } catch (error) {
//     dispatch({ type: USER_SIGNIN_FAIL, payload: error.message });
//   }
// }

// const register = (name, email, password) => async (dispatch) => {
//   dispatch({ type: USER_REGISTER_REQUEST, payload: { name, email, password } });
//   try {
//     const { data } = await Axios.post("/api/users/register", { name, email, password });
//     dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
//     Cookie.set('userInfo', JSON.stringify(data));
//   } catch (error) {
//     dispatch({ type: USER_REGISTER_FAIL, payload: error.message });
//   }
// }

const logout = () => (dispatch) => {
  localStorage.removeItem("user");
  localStorage.removeItem("accessToken");
  dispatch({ type: LOGOUT_USER })
}
export {
    getAdminUsers,
    getUserRoles,
    addAdminUser,
    getAppUsers,
    loginAdminUser,
    editAdminUser,
    getCenterDetails,
    logout
};