import { api } from "../../../app/api";

export const doctorsApi = api.injectEndpoints({
    endpoints: build => ({
        getDoctors: build.query({
            query: () => 'api/doctors/all',
            providesTags: ['doctors']
        }),
        getDoctorsAppointments: build.query({
            query: (data) => `api/doctors/admin/appointments?doctorId=${data.id}&startDate=${data.startDate}&endDate=${data.endDate}`,
            providesTags: ['doctor_appointements']
        }),
        getDoctorDetails: build.query({
            query: (id) => `api/doctors/doctorDetails?doctor=${id}`,
            providesTags: ['doctorDetails']
        }),
        getDoctorCities: build.query({
            query: () => "api/location/city?country=648c0af648db1505dbbb0945",
            providesTags: ['doctorCities']
        }),
        addDoctor: build.mutation({
            query: body => ({
                url: 'api/doctors/',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['doctors'],
        }),
        updateDoctor: build.mutation({
            query: body => ({
                url: 'api/doctors',
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['doctors'],
        }),
        updateDoctorStatus: build.mutation({
            query: body => ({
                url: 'api/doctors/doctorStatus',
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['doctorDetails'],
        }),
        getDoctorAvailbility: build.query({
            query: () => 'doctors',
            providesTags: ['doctorsAvailbility']
        }),
        addDoctorAvailbility: build.mutation({
            query: body => ({
                url: 'api/doctors/availibility',
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['doctorDetails'],
        }),
        updateDoctorAvailbility: build.mutation({
            query: body => ({
                url: 'api/doctors/availibility',
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['doctorDetails'],
        }),
        deleteDoctorAvailbility: build.mutation({
            query: body => ({
                url: 'api/doctors/availibility',
                method: 'DELETE',
                body,
            }),
            invalidatesTags: ['doctorDetails'],
        }),
        getDoctorPricing: build.query({
            query: () => 'doctors',
            providesTags: ['doctorsPricing']
        }),
        updateDoctorPricing: build.mutation({
            query: body => ({
                url: 'api/doctors/rates',
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['doctorDetails'],
        }),
        //payments

        getDoctorPaymentData: build.query({
            // query: (data) => `api/web/payment/log/doctor?doctor=64a4597b43e433882b2c3f2b&endDate=2023-12-02`,
            query: (data) => `api/web/payment/log/doctor?doctor=${data.doctor}&endDate=${data.endDate}`,
            providesTags: ['doctorspayments']
        }),
        getPendingAppointmentsByDate: build.query({
            query: (data) => `api/doctors/pending/payment/appointments?doctor=${data.doctor}&endDate=${data.endDate}`,
            providesTags: ['doctorsPendingPayments']
        }),
        initiateDoctorPayment: build.mutation({
            query: body => ({
                  url: 'api/web/payment/log',
                method: 'POST',
                body,
                
            }),
            invalidatesTags: ['doctorspayments'],
        }),
        updateDoctorPaymentRequestStatus: build.mutation({
            query: body => ({
                  url: 'api/web/payment/log',
                method: 'PUT',
                body,
                
            }),
            invalidatesTags: ['doctorspayments'],
        })
    
    }),
});

export const {
    useGetDoctorsAppointmentsQuery,
    useGetDoctorsQuery,
    useGetDoctorDetailsQuery,
    useAddDoctorMutation,
    useUpdateDoctorMutation,
    useGetDoctorAvailbilityQuery,
    useAddDoctorAvailbilityMutation,
    useUpdateDoctorAvailbilityMutation,
    useGetDoctorPricingQuery,
    useUpdateDoctorPricingMutation,
    useGetDoctorCitiesQuery,
    useDeleteDoctorAvailbilityMutation,
    useUpdateDoctorStatusMutation,
    //payment
    useGetDoctorPaymentDataQuery,
    useGetPendingAppointmentsByDateQuery,
    useInitiateDoctorPaymentMutation,
    useUpdateDoctorPaymentRequestStatusMutation

} = doctorsApi;

