import React, { useEffect, useState } from 'react'
import CustomInputField from '../../../app/components/custom_input_field'
import { InputText } from 'primereact/inputtext';
import { apiGet } from '../../../app/services/api_client';
import { Button } from 'primereact/button';
import { useInitiateDoctorPaymentMutation } from '../manager/doctors_services';
import appService from '../../../app/services/app_service';
import { useFormik } from 'formik';
import { formatDate } from '../../../app/services/time_format_service';

export default function AddDoctorPaymentDialog({ doctorId, onHide }) {

  const [endDate, setEndDate] = useState('');
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() - 2);
  const [paymentData, setAppointmentData] = useState(null);
  const [copetShare, setCopetShare] = useState(0);
  const [accountTitle, setAccountTitle] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const [addPayment, { isLoading: addLoading, error: addError, status: addStatus }] = useInitiateDoctorPaymentMutation();


  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  }

  useEffect(() => {

    if (endDate.length > 0) {
      makeRequest();
    }
  }, [endDate]);

  const makeRequest = async () => {
    try {
      const response = await apiGet(`api/doctors/pending/payment/appointments?doctor=${doctorId}&endDate=${endDate}`);
      setAppointmentData(response.data);
    } catch (error) {

    }

  }

  useEffect(() => {

    appService.showApiResponseStatus(
      {
        status: addStatus,
        msg: "Added successfully!",
        error: addError,
        successCB: () => {

          onHide();
        }
      }
    );



  }, [addStatus]);

  const formik = useFormik({
    initialValues: {
      copetShare: 0,
      accountTitle: "",
      accountNumber: "",
      totalAmount: "",


    },
    onSubmit: async (data) => {

      addPayment({
        "doctor": doctorId,
        "startDate": formatDate(paymentData?.appointments[0]['date']),
        "endDate": endDate,
        "copetShare": copetShare,
        "account_info": {
          "title": accountTitle,
          "account_number": accountNumber
        }
      })

    },
  });

  return (

    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className='field'>
          <div className="col-12 md:col-6\8">
            <div className=" p-fluid">
              <InputText
                name='endDate'
                placeholder='Select date'
                type='date'
                value={endDate}
                onChange={handleEndDateChange}
                max={maxDate.toISOString().split('T')[0]}
              />
            </div></div>

        </div>

        {paymentData != null && <div className='field'>
          <div className="col-12 md:col-6\8">
            <div className=" p-fluid">
              <InputText
                name='totalAmount'
                placeholder='Total Amount'
                type='text'
                value={paymentData?.totalPendingPayment}
                disabled={true}

              />
            </div></div>

        </div>}
        {paymentData != null && <div className='field'>
          <div className="col-12 md:col-6\8">
            <div className=" p-fluid">
              <InputText
                name='copetShare'
                placeholder='Copet share'
                type='number'
                min={0}
                max={100}
                required={true}
                value={copetShare}
                onChange={(e) => setCopetShare(e.target.value)}


              />
            </div></div>

        </div>}
        {paymentData != null && <div className='field'>
          <div className="col-12 md:col-6\8">
            <div className=" p-fluid">
              <InputText
                placeholder='Account title'
                type='text'
                name='accountTitle'
                required={true}
                value={accountTitle}
                onChange={(e) => setAccountTitle(e.target.value)}


              />
            </div></div>

        </div>}
        {paymentData != null && <div className='field'>
          <div className="col-12 md:col-6\8">
            <div className=" p-fluid">
              <InputText
                placeholder='Account number'
                type='text'
                name='accountNumber'
                required={true}
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}


              />
            </div></div>

        </div>}
        {
          paymentData != null && <Button
            disabled={paymentData?.totalPendingPayment == 0}

            type='submit'
            label='Add Request'
            className='p-button-success'

          />}



      </form>
    </div>
  )
}
