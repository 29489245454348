import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { editCenterData } from '../../../actions/centers_actions';
import { EDIT_CENTER_DATA_RESET, GET_CENTER_DETAILS_SUCCESS } from '../../../constants/centers_constants';

export default function EditTimingDialog({ slot, onHide }) {
    const editCenterDataReducer = useSelector((state) => state.editCenterData);
 
    const centerDetails = useSelector((state) => state.centerDetails);
    const {center}=centerDetails;
    const { loading, success, error, center:updatedCenter } = editCenterDataReducer;

    const dispatch = useDispatch();



    const formik = useFormik({
        initialValues: {
            active: true
        },

        onSubmit: async (data) => {
        
            let timings=center.centre.timeSlots;
            timings=timings.map((item)=>{
                if(item._id===slot._id){
                    return {...item,active:data.active}
                }
                return item;
            });
            dispatch(editCenterData({timeSlots:timings,centerId:center.centre._id}));

        },
    });
    useEffect(() => {
        formik.setFieldValue('active', slot.active);



    }, [])

    useEffect(() => {
        if (success !== undefined) {
            if (success === true) {
                console.log(updatedCenter);
                dispatch({ type: GET_CENTER_DETAILS_SUCCESS, payload: updatedCenter });
                onHide();
            }
        }


        return () => {
            dispatch({ type: EDIT_CENTER_DATA_RESET });
        }
    }, [success, error, dispatch])


    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='flex flex-row justify-content-between'>
                    <h3>Is slot available?</h3>
                    <Checkbox name='active' checked={formik.values.active} onChange={formik.handleChange} />

                </div>
                <div className='text-right'><Button loading={loading} type='submit' label='Submit' className='p-button-success' /></div>



            </form>

        </>

    )
}
