import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useGetOrganizerEventsQuery } from '../manager/events_service';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDate } from '../../../app/services/time_format_service';
import { militaryTimeToLocal } from '../../../app/services/military_time_service';
import PageHeader from '../../../app/components/page_header';
import { Button } from 'primereact/button';
import AddEventDialog from '../componenets/add_event_dialog';
import { primeIcons } from '../../../app/services/icon_service';


export default function OrganizerEventsScreen() {


    const [editData, setEditData] = useState(null);
    const [showAddOrganizerDialog, setShowAddOranizerDilog] = useState(false);

    const history = useHistory();


    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const dialogFuncMap = {
        'showAddOrganizerDialog': setShowAddOranizerDilog,

    }

    const actionsBody = (rowData) => {
        return <div className='flex'>
            <Button
                onClick={() => detailsActionOnClick(rowData._id)}
                type="button"
                tooltip='Details'
                icon="pi-chevron-right"
                className='mx-2'
            >
            </Button>
            <Button
                onClick={() => editActionOnClick(rowData)}
                type="button"
                tooltip='Edit'
                icon={primeIcons['pi-pencil']}
            >
            </Button>
        </div>
    }

    const detailsActionOnClick = (id) => {
        history.push(`/api/events/${id}`);
    }

    const editActionOnClick = (data)=>{
        setEditData(data);
        onClick('showAddOrganizerDialog');
    }

    const params = useParams();
    const { id } = params;

    const { data: list, isLoading, error, isError } = useGetOrganizerEventsQuery(id);


    const registrationOpeningStartDateBody = (rowData) => {
        return <div>
            {formatDate(rowData.registrationOpeningDate)}
        </div>
    }
    const registrationOpeningCloseDateBody = (rowData) => {
        return <div>
            {formatDate(rowData.registrationClosingDate)}
        </div>
    }
    const eventDateBody = (rowData) => {
        return <div>
            {formatDate(rowData.eventDate)}
        </div>
    }

    const startTimeBody = (rowData) => {
        return <div>
            {militaryTimeToLocal(rowData.eventStartTime)}
        </div>
    }
    const endTimeBody = (rowData) => {
        return <div>
            {militaryTimeToLocal(rowData.eventEndTime)}
        </div>
    }



    return (
        <div className='card'>

            <PageHeader buttonTitle={"Add Event"} onClick={onClick} onHide={onHide} showDialog={showAddOrganizerDialog} dialogHeader={"Event"} title={"Events"} dialogHook={"showAddOrganizerDialog"} buttonOnClick={() => {
                setEditData(null);
            }}>
                <AddEventDialog editData={editData} onHide={() => onHide('showAddOrganizerDialog')} id={id} />
            </PageHeader>

            <DataTable value={list?.data} paginator className="p-datatable-gridlines" showGridlines rows={10}
                dataKey="id" filterDisplay="menu" loading={isLoading} responsiveLayout="scroll"
                emptyMessage="No data found.">

                <Column
                    field="title"
                    header="Title"
                    style={{ minWidth: '12rem' }} />

                <Column
                    field="totalSeats"
                    header="TotalSeats"
                    style={{ minWidth: '12rem' }} />

                <Column
                    header="Registration Opening Date"
                    style={{ minWidth: '12rem' }}
                    body={registrationOpeningStartDateBody}
                />

                <Column

                    header="Registration Closing Date"
                    style={{ minWidth: '12rem' }}
                    body={registrationOpeningCloseDateBody}
                />
                <Column

                    header="Date"
                    style={{ minWidth: '12rem' }}
                    body={eventDateBody}
                />
                <Column

                    header="Start time"
                    style={{ minWidth: '12rem' }}
                    body={startTimeBody}
                />
                <Column

                    header="End time"
                    style={{ minWidth: '12rem' }}
                    body={endTimeBody}
                />
                <Column
                    header="Actions"
                    style={{ minWidth: '12rem' }}
                    body={actionsBody} />



            </DataTable>
        </div>
    )
}
