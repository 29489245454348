import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAppUsers } from '../../../actions/users_actions';

export default function AppUsers() {
    const getAppUsersReducer=useSelector((state)=>state.appUsers);
    const {loading,users}=getAppUsersReducer;
    const dispatch=useDispatch();
    useEffect(() => {
        dispatch(getAppUsers());
    
    }, [dispatch])
    
  return (
      <>
      <div className='card'>
          

      <h5>Users</h5>
      <DataTable value={users} paginator className="p-datatable-gridlines" showGridlines rows={10}
                    dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll"
                    emptyMessage="No users found.">
                    <Column field="firstName" header="First Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="lastName" header="Last Name" filter filterPlaceholder="Search by last name" style={{ minWidth: '12rem' }} />
                    <Column field="phone" header="Mobile" filter filterPlaceholder="Search by mobile" style={{ minWidth: '12rem' }} />
                    <Column field="selectedPet.name" header="Pet Name" filter filterPlaceholder="Search by role" style={{ minWidth: '12rem' }} />

                    {/* <Column header="Date" field="createdAt" dataType="date" style={{ minWidth: '10rem' }}
                        filter />
                    <Column field="status" header="Status" filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} body={statusBodyTemplate} filter />
                    <Column  header="Actions" filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} body={actionsBodyTemplate}  /> */}
                </DataTable>
      </div>
      
      </>

  )
}
