import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getNotifications } from '../../slices/notification_slice';
import { Dialog } from 'primereact/dialog';
import PageHeader from '../../app/components/page_header';
import AddEditDoctorDialog from './components/add_edit_doctor_dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { primeIcons } from '../../app/services/icon_service';
import { useAppHistory } from '../../app/hooks';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetDoctorsQuery } from './manager/doctors_services';

export default function DoctorsScreen() {
    const dispatch = useDispatch();
    const [showAddDoctorDialog, setShowAddEditDialog] = useState(false);

    const { data: doctors, isLoading, error, isError } = useGetDoctorsQuery();
    const history = useHistory();

    const [editData, setEditData] = useState(null);


    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const dialogFuncMap = {
        'showAddDoctorDialog': setShowAddEditDialog,

    }
    useEffect(() => {
        dispatch(getNotifications());
    }, [dispatch]);

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon={primeIcons['pi-pencil']} tooltip='Edit' className="p-button-rounded p-button-success m-1" onClick={() => {
                    setEditData(rowData);
                    onClick("showAddDoctorDialog")
                }} />
                <Button tooltip='Appointments' icon={primeIcons['pi-calendar-times']} className="p-button-rounded p-button-success  m-1" onClick={() => {
                    history.push(`/api/doctors/appointmetns/${rowData._id}`)
                }} />
                <Button tooltip='Details' icon={primeIcons['pi-user']} className="p-button-rounded p-button-warning m-1" onClick={() => {
                    history.push(`/api/doctors/details/${rowData._id}`)
                }} />
                <Button tooltip='Payments' icon={primeIcons['pi-money-bill']} className="p-button-rounded p-button-warning m-1" onClick={() => {
                    history.push(`/api/doctors/payments/${rowData._id}`)
                }} />
            </React.Fragment>
        );
    }




    return (
        <div className='card'>
            <PageHeader buttonTitle={"Add Doctor"} onClick={onClick} onHide={onHide} showDialog={showAddDoctorDialog} dialogHeader={"Doctors"} title={"Doctors"} dialogHook={"showAddDoctorDialog"} buttonOnClick={() => {
                setEditData(null);
            }}>
                <AddEditDoctorDialog editData={editData} onHide={() => onHide('showAddDoctorDialog')} />
            </PageHeader>



            <div>

                <DataTable value={doctors?.data} paginator className="p-datatable-gridlines" showGridlines rows={10}
                    dataKey="id" filterDisplay="menu" loading={isLoading} responsiveLayout="scroll"
                    emptyMessage="No data found.">
                    <Column field="firstName" header="First Name" style={{ minWidth: '12rem' }} />
                    <Column field="lastName" header="Last Name" style={{ minWidth: '12rem' }} />
                    <Column field="email" header="Email" style={{ minWidth: '12rem' }} />
                    <Column field="experience" header="Experience" style={{ minWidth: '12rem' }} />
                    <Column field="qualification" header="Qualification" style={{ minWidth: '12rem' }} />
                    <Column header="Action" body={actionBodyTemplate} style={{ minWidth: '12rem' }} />

                </DataTable>
            </div>
        </div>
    )
}
