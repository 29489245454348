import { axiosApi } from '../app/services/axios_api';
import { BASE_URL } from '../config';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const petDateSlice = createSlice({
    name: 'petDates',
    initialState: {},
    reducers:{
        resetPetDateSlice(state, action) {
            return {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addMultipleProfiles.pending, (state, action) => {
                return { loading: true }
            })
            .addCase(addMultipleProfiles.fulfilled, (state, action) => {
        

                return { loading: false, user: action.payload }
            })
            .addCase(addMultipleProfiles.rejected, (state, action) => {
                return {
                    loading: false,
                    error: action.payload
                }
            });
    },
});

export default petDateSlice.reducer;
export const { resetPetDateSlice } = petDateSlice.actions;


// Thunks
export const addMultipleProfiles = createAsyncThunk('petDate/fetch', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const url = BASE_URL + 'api/pet/multiple';
        const { data } = await axiosApi.post(url, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});

