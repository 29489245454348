import React, { useState } from 'react'
import { useDeletePostMutation, useGetAllPostsQuery } from '../manager/posts_service';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { primeIcons } from '../../../app/services/icon_service';
import { Button } from 'primereact/button';
import PageHeader from '../../../app/components/page_header';
import ViewPostDialog from '../components/view_post_screen';
import { confirmDialog } from 'primereact/confirmdialog';

export default function PostsScreen() {

  const { data: doctors, isLoading} = useGetAllPostsQuery();

  const [showPreviewPostDialog, setPreviewPostDialog] = useState(false);
  const [url, setUrl] = useState("");
  const [type, setType] = useState("");
  const dialogFuncMap = {
    'showPreviewPostDialog': setPreviewPostDialog,

  }

  var deleteId;

  const [deletePost] = useDeletePostMutation();
  const accept = () => {
    deletePost(deleteId);
}

const reject = () => {
}

const deleteConfirmation = () => {
  confirmDialog({
      message: 'Do you want to delete?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept,
      reject
  });
};

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  }
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  }

  const thumbnailBody = (rowData) => {
    if (rowData.type === 'video') {
      return <img width="60px" height="auto" src={rowData.thumbnail} />
    } else {
      return <img width="60px" height="auto" src={rowData.links[0]} />
    }
  }
  const returnActionsBody = (rowData) => {
    return (
      <React.Fragment>
        <Button icon={primeIcons['pi-play']} className="p-button-rounded p-button-success mr-2" onClick={() => {
          let { type } = rowData;
          setType(type);
          setUrl(rowData?.links[0]);
          onClick('showPreviewPostDialog');
        }} />
        <Button icon={primeIcons['pi-trash']} className="p-button-rounded p-button-danger mr-2" onClick={() => {
     deleteId = rowData._id;
     deleteConfirmation();
        }} />

      </React.Fragment>
    );
  }
  return (
    <div className='card'>
      <PageHeader showButton={false} buttonTitle={"Post"} onClick={onClick} onHide={onHide} showDialog={showPreviewPostDialog} dialogHeader={"Posts"} title={"Post"} dialogHook={"showPreviewPostDialog"} buttonOnClick={() => {

      }}>
        <ViewPostDialog url={url} type={type} onHide={() => onHide('showPreviewPostDialog')} />
      </PageHeader>
      <DataTable paginator value={doctors?.data} loading={isLoading} className="p-datatable-gridlines" showGridlines rows={10}>
        <Column sortable field='title' header="title" />
        <Column sortable field='type' header="Type" />
        <Column body={thumbnailBody} header="Thumbnail" />
        <Column body={returnActionsBody} header="Actions" />
      </DataTable>
    </div>
  )
}
