import React, { useState } from 'react'
import { useDeleteDoctorAvailbilityMutation, useGetDoctorDetailsQuery } from '../manager/doctors_services';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { Button } from 'primereact/button';
import PageHeader from '../../../app/components/page_header';
import AddEditDoctorAvailabilityDialog from '../components/add_edit_doctor_availbility_dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DoctorRates from '../components/doctor_rates';
import DoctorStatus from '../components/doctor_status';
import { confirmDialog } from 'primereact/confirmdialog';
import { militaryTimeToLocal } from '../../../app/services/military_time_service';
export default function DoctorDetailsScreen() {

    var deleteAvailbilityId;

    const [deleteAvailbility] = useDeleteDoctorAvailbilityMutation();


    const params = useParams();
    const { id } = params;
    const { data: doctor } = useGetDoctorDetailsQuery(id);

    const [showAddDoctorDialog, setShowAddEditDialog] = useState(false);

    const [editData, setEditData] = useState(null);

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    const dialogFuncMap = {
        'showAddDoctorDialog': setShowAddEditDialog,

    }





    const getStartTime = (data) => {
        return <div>
        {
            data.morning != undefined ?
            <div>
            {militaryTimeToLocal(data.morning.startTime)}  -   {militaryTimeToLocal(data.morning.endTime)}
        </div>

                :
                <div></div>
        }
    </div>

    }

    const getEndTime = (data) => {
        return <div>
            {
                data.evening != undefined ?
                    <div>
                        {militaryTimeToLocal(data.evening.startTime)}  -   {militaryTimeToLocal(data.evening.endTime)}
                    </div>

                    :
                    <div></div>
            }
        </div>
    }
    const actionsBody = (data) => {
        return (
            <React.Fragment>

                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning  mr-2" onClick={() => {
                    deleteAvailbilityId = data._id;
                    deleteConfirm();

                }} />

            </React.Fragment>
        );
    }


    const deleteConfirm = () => {

        confirmDialog({
            message: 'Do you want to delete this off day?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };


    const accept = () => {

        deleteAvailbility({
            "id": id,
            "availibilityId": deleteAvailbilityId
        });
    }

    const reject = () => {
    }
    const days = [
        {
            "name": "Sunday",
            "value": 0
        },
        {
            "name": "Monday",
            "value": 1
        },
        {
            "name": "Tuesday",
            "value": 2
        },
        {
            "name": "Wednesday",
            "value": 3
        },
        {
            "name": "Thrusday",
            "value": 4
        },
        {
            "name": "Friday",
            "value": 5
        },
        {
            "name": "Saturday",
            "value": 6
        }
    ];

    const returnDayNameFromNumber = (num) => {
        return days.filter((e) => e.value === num)[0].name;
    }
    const startDayBody = (data) => {
        return <div>
            {returnDayNameFromNumber(data.startDay)}
        </div>
    }
    const endDayBody = (data) => {
        return <div>
            {returnDayNameFromNumber(data.endDay)}
        </div>
    }
    return (
        <div>

            <DoctorStatus id={id} />
            <DoctorRates id={id} />

            <div className='card'>


                <PageHeader buttonTitle={"Add availbility"} onClick={onClick} onHide={onHide} showDialog={showAddDoctorDialog} dialogHeader={"Doctors"} title={"Doctors"} dialogHook={"showAddDoctorDialog"} buttonOnClick={() => {
                    setEditData(null);
                }}>
                    <AddEditDoctorAvailabilityDialog editData={editData} onHide={() => onHide('showAddDoctorDialog')} id={id} />
                </PageHeader>

                <DataTable value={doctor?.data?.availibility}>
                    <Column body={startDayBody} header="Start day" />
                    <Column body={endDayBody} header="End day" />
                    <Column body={getStartTime} header="Morning time" />
                    <Column field={getEndTime} header="Evening time" />
                    <Column header="Action" body={actionsBody} />
                </DataTable>
            </div>
        </div>
    )


}
