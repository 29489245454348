import { TabPanel, TabView } from 'primereact/tabview'
import React from 'react'
import CenterBoardingAppointmentsTab from '../../appointments/pages/center_boarding_screen'
import CenterGroomingTab from '../../appointments/pages/center_grooming_screen'

export default function CenterAppointments() {
  return (
    <>
      <TabView>
                    <TabPanel header="Grooming">
                    <CenterGroomingTab/>
                    </TabPanel>
                    <TabPanel header="Boarding">
                   <CenterBoardingAppointmentsTab/>
                    </TabPanel>
                   
                </TabView>

    </>
  )
}
