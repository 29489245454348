import { InputText } from 'primereact/inputtext'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { addCenter, editCenter, getCentersList } from '../../../actions/centers_actions';
import { InputTextarea } from 'primereact/inputtextarea';
import { ADD_CENTER_RESET, EDIT_CENTER_RESET } from '../../../constants/centers_constants';
import { FILE_BASE_URL } from '../../../config';
import { Image } from 'primereact/image';
import { InputSwitch } from 'primereact/inputswitch';
import toastService from '../../../app/services/toast_service';





export default function AddEditCenterDialog({ onHide, editCenter: centre }) {

    const [profileImage, setProfileImage] = useState(null);
    const [photos, setPhotos] = useState([]);


    const addCenteerRedicer = useSelector((state) => state.addCenter);
    const { success: addSuccess, loading, error: addUserError } = addCenteerRedicer;

    const editCenteerRedicer = useSelector((state) => state.editCenter);
    const { success: editSuccess, error: editError ,loading : editLoading} = editCenteerRedicer;
    const dispatch = useDispatch();

    const toast = useRef(null);
    const [editCenterPhotos, setEditCenterPhotos] = useState([]);



    useEffect(() => {


        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.current.show({ severity: 'success', summary: '', detail: 'Center added successfully', life: 3000 });
                formik.resetForm();
                onHide();
                dispatch(getCentersList());


            } else {
                toast.current.show({ severity: 'error', summary: 'Success Message', detail: addUserError, life: 3000 });


            }
        }

        if (editSuccess !== undefined) {
            if (editSuccess === true) {
                toastService.success("Center updated");
                formik.resetForm();
                onHide();
                dispatch(getCentersList());


            } else {
                toastService.error(editError)


            }
        }


        return () => {


            dispatch({ type: ADD_CENTER_RESET });
            dispatch({ type: EDIT_CENTER_RESET });
        }



    }, [addSuccess, addUserError,editSuccess]);


    useEffect(() => {


        if (centre !== null) {

            formik.setFieldValue("name", centre.name);
            formik.setFieldValue("latitude", centre.location.latitude);
            formik.setFieldValue("longitude", centre.location.longitude);
            formik.setFieldValue("place", centre.location.place);
            formik.setFieldValue("link", centre.link);
            formik.setFieldValue("owner", centre.owner);
            formik.setFieldValue("information", centre.information);
            formik.setFieldValue("phone", centre.phone);
            formik.setFieldValue("emergencyContact", centre.emergencyContact);
            formik.setFieldValue("certificate", centre.certificate);
            formik.setFieldValue("isActive", centre?.isActive ?? false);
            setEditCenterPhotos(centre.photos);
        }

        return () => {

        }
    }, [])


    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required."),
        place: Yup.string().required("This field is required."),
        link: Yup.string().required("This field is required."),
        owner: Yup.string().required("This field is required."),
        certificate: Yup.string().required("This field is required."),
        information: Yup.string().required("This field is required."),
        phone: Yup.string().required("This field is required."),
        emergencyContact: Yup.string().required("This field is required."),
        longitude: Yup.number().required("This field is required"),
        latitude: Yup.number().required("This field is required"),

    });
    const SUPPORTED_FORMATS = ["image/jpg", "image/png", "image/jpeg", "image/gif"];

    async function readFileAsDataURL(file) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });



        return result_base64;
    }


    const formik = useFormik({
        validationSchema: validationSchema,

        initialValues: {
            name: "",
            longitude: "",
            latitude: "",
            place: "",
            link: "",
            owner: "",
            information: "",
            phone: "",
            emergencyContact: "",
            certificate: "",
            isActive: false
        },


        onSubmit: async (data) => {

            if (centre === null) {
                if (profileImage === null) {
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Please choose a profile image", life: 3000 });
                    return;
                }
                let profileImgBase64 = await readFileAsDataURL(profileImage);
                data['profileImage'] = profileImgBase64;
                console.log(photos);
                let photosBase64 = [];
                for (let i = 0; i < photos.length; i++) {
                    let res = await readFileAsDataURL(photos[i]);
                    photosBase64.push(res);
                }

                data['photos'] = photosBase64;
                dispatch(addCenter(data));
            } else {
                data['previousPhotos'] = editCenterPhotos;
                if (profileImage !== null) {
                    let profileImgBase64 = await readFileAsDataURL(profileImage);
                    data['profileImage'] = profileImgBase64;
                }
                let photosBase64 = [];
                for (let i = 0; i < photos.length; i++) {
                    let res = await readFileAsDataURL(photos[i]);
                    photosBase64.push(res);
                }
                data['photos'] = photosBase64;
                data['centreId'] = centre._id;
           
                dispatch(editCenter(data));





            }

        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>
    };

    return (
        <>
            <Toast ref={toast} />

            <form onSubmit={formik.handleSubmit}>

                {

                    <div className="col-12 md:col-6\8">
                        {
                            centre !== null && <div className='flex flex-row justify-content-center'><Image className='px-2' preview={true} src={FILE_BASE_URL + centre.profileImage} width='150px' height='150px' template="Preview" alt="Image Text" /></div>

                        }
                        <div className=" p-fluid formgrid grid">


                            {/* name */}
                            <div className="field col-12">

                                <label htmlFor="name1">Name</label>
                                <InputText id="name" name='name' value={formik.values.name} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                {getFormErrorMessage('name')}
                            </div>
                            <div className="field col-12">

                                <label htmlFor="name1">Owner Name</label>
                                <InputText id="name" name='owner' value={formik.values.owner} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('owner') })} />
                                {getFormErrorMessage('owner')}
                            </div>
                            {/* location */}


                            <div className="field col-12 md:col-4">

                                <label htmlFor="name1">longitude</label>
                                <InputText id="longitude" name='longitude' value={formik.values.longitude} onChange={formik.handleChange} type="number" className={classNames({ 'p-invalid': isFormFieldValid('longitude') })} />
                                {getFormErrorMessage('longitude')}
                            </div>
                            <div className="field col-12  md:col-4">

                                <label htmlFor="name1">Latitude</label>
                                <InputText id="latitude" name='latitude' value={formik.values.latitude} onChange={formik.handleChange} type="number" className={classNames({ 'p-invalid': isFormFieldValid('latitude') })} />
                                {getFormErrorMessage('latitude')}
                            </div>
                            <div className="field col-12  md:col-4">

                                <label htmlFor="name1">Place</label>
                                <InputText id="place" name='place' value={formik.values.place} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('place') })} />
                                {getFormErrorMessage('place')}
                            </div>
                            {/* phone */}




                            <div className="field col-12 md:col-6">
                                <label htmlFor="name1">Phone</label>
                                <InputText id="phone" name='phone' value={formik.values.phone} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('phone') })} />
                                {getFormErrorMessage('phone')}
                            </div>
                            <div className="field col-12 md:col-6">
                                <label htmlFor="name1">Emergency Contact</label>
                                <InputText id="emergencyContact" name='emergencyContact' value={formik.values.emergencyContact} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('emergencyContact') })} />
                                {getFormErrorMessage('emergencyContact')}
                            </div>
                            {/* information */}
                            <div className="field col-12 md:col-6">
                                <label htmlFor="name1">Information</label>
                                <InputTextarea id="information" name='information' value={formik.values.information} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('information') })} />
                                {getFormErrorMessage('information')}
                            </div>
                            <div className="field col-12 md:col-6">
                                <label htmlFor="name1">Certificate</label>
                                <InputTextarea id="certificate" name='certificate' value={formik.values.certificate} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('certificate') })} />
                                {getFormErrorMessage('certificate')}
                            </div>
                            <div className="field col-12 md:col-12">
                                <label htmlFor="name1">Link</label>
                                <InputTextarea id="link" name='link' value={formik.values.link} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('link') })} />
                                {getFormErrorMessage('link')}
                            </div>
                            {
                                centre != null && <div className='my-3 field col-12 md:col-6'>
                                    <div className='flex flex-space-between'>
                                        <div className='mr-5'>
                                            <label htmlFor="name1">Status</label>
                                        </div>
                                        <InputSwitch name="isActive" onChange={formik.handleChange} checked={formik.values.isActive} />
                                    </div>
                                </div>
                            }
                            {/* images */}
                            <div className="field col-12 md:col-6">
                                <label htmlFor="name1">Profile Images</label>
                                <InputText accept="image/png, image/jpeg, image/jpg" id="profileImage" name='profileImage' onChange={(e) => setProfileImage(e.target.files[0])} type="file" />
                            </div>

                            <div className="field col-12 md:col-6">
                                <label htmlFor="name1">Photos</label>
                                <InputText accept="image/png, image/jpeg, image/jpg" id="photos" name='photos' onChange={(e) => setPhotos(e.target.files)} multiple type="file" />
                            </div>



                            <div className='flex flex-row flex-wrap py-3 '>
                                {
                                    centre !== null && editCenterPhotos.map((item) => (<div key={item} className='edit_center_image_wrapper'><Image className='px-2' preview={true} src={FILE_BASE_URL + item} width='170px' height='200px' template="Preview" alt="Image Text" />
                                        <Button
                                    
                                   
                                        icon="pi pi-trash" className="p-button-rounded p-button-primary image-delete-icon" onClick={() => {
                                            setEditCenterPhotos((prev) => {
                                                return prev.filter((el) => el !== item);

                                            });
                                        }} />
                                    </div>))
                                }
                            </div>










                            <Button      loading={centre == null?loading:editLoading} type='submit' label='Submit' className='p-button-success' />

                        </div>


                    </div>}
            </form>

        </>
    )
}

