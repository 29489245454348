import React from 'react'
import ReactPlayer from 'react-player'

export default function ViewPostDialog({ type, url }) {
    return (
        <div className='flex justify-content-center' style={{textAlign: 'center'}}>
            {
                type === 'video' ? <ReactPlayer  autoPlay={true}
                controls={true} url={"https://vz-8e778deb-1e3.b-cdn.net/aafbb806-25bd-490b-b683-d0dbef15e147/playlist.m3u8?v=1692290846"} /> : <img alt='Not found' src={url} width='100%' height='auto' />
            }


        </div>
    )
}
