import decryptData from './decryptData';
import encryptData from './encryptData';

import axios from "axios";
const axiosApi = axios.create();
axiosApi.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// Request interceptor for API calls
axiosApi.interceptors.request.use(
  async config => {
      const access_token=localStorage.getItem('accessToken')
    config.headers = { 
      'Authorization': `Bearer ${access_token}`,
      // 'Accept': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded'
    }
    // config.data=encryptData(config.data);

    // console.log(config.data);
    return config;
  },
  error => {
    Promise.reject(error)
});

// Response interceptor for API calls
axiosApi.interceptors.response.use((response) => {
  // console.log("response");
  // console.log(response.data);
  // response.data = decryptData(response.data.cipher);
  console.log(response.data);
  // console.log(response.data);
  return response;
}, async function (error) {
  // console.log("error");
  // console.log(error);
  error.response.data=decryptData(error.response.data);
  // console.log(error.response.data);
  const originalRequest = error.config;
  // if (error.response.status === 401 && !originalRequest._retry) {
  //   originalRequest._retry = true;
  //   const access_token = await refreshAccessToken();            
  //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
  //   return axiosApi(originalRequest);
  // }
  return Promise.reject(error);
});


const refreshAccessToken=async()=>{
    const refreshToken=localStorage.getItem('refreshToken');
    if(refreshToken){
        const {data}=await axios.post('http://192.168.4.135:5000/auth/refreshToken',{
            refreshToken:refreshToken
        });
        localStorage.setItem('accessToken',data.accessToken)
        
        return data.accessToken
    }else{
        return '';
    }
}




export {axiosApi}


