
import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import { FILE_BASE_URL } from '../../../config';
import { DataTable } from 'primereact/datatable';
export default function ImagesCarousel({ images }) {

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];




    const imageTemplate = (image) => {
        return (
            <div style={{textAlign: 'center'}}>
                <img width={"auto"} height="300px" src={`${FILE_BASE_URL}${image}`} />
            </div>
        );
    }

    return (
        <div className="carousel-demo">
            <div className="card">
                <Carousel value={images} numVisible={1} numScroll={1} 
                    itemTemplate={imageTemplate} header={<h5>Gallery</h5>} />
            </div>

          



        </div>
    );
}
