import React, { useEffect } from 'react'
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { editCenterData } from '../../../actions/centers_actions';


export default function ServicesTab({ services }) {

    const editCenterDataReducer = useSelector((state) => state.editCenterData);

    const centerDetails = useSelector((state) => state.centerDetails);
    const { center } = centerDetails;
    const { loading,} = editCenterDataReducer;

    const dispatch = useDispatch();

    useEffect(() => {
        formik.setFieldValue("groomingSmall", services.groomingService.dogGroomingService.small);
        formik.setFieldValue("groomingMedium", services.groomingService.dogGroomingService.medium);
        formik.setFieldValue("groomingLarge", services.groomingService.dogGroomingService.large);
        formik.setFieldValue("groomingCat", services.groomingService.catGroomingService.cat);
        formik.setFieldValue("groomingKitten", services.groomingService.catGroomingService.kitten);
        formik.setFieldValue("dogGrooming", services.groomingService.dogGroomingService.enable);
        formik.setFieldValue("catGrooming", services.groomingService.catGroomingService.enable);
        formik.setFieldValue("grooming", services.groomingService.enable);

        formik.setFieldValue("boardingSmall", services.boardingSerivce.dogBoardingSerivce.small);
        formik.setFieldValue("boardingMedium", services.boardingSerivce.dogBoardingSerivce.medium);
        formik.setFieldValue("boardingLarge", services.boardingSerivce.dogBoardingSerivce.large);
        formik.setFieldValue("boardingCat", services.boardingSerivce.catBoardingSerivce.cat);
        formik.setFieldValue("boardingKitten", services.boardingSerivce.catBoardingSerivce.kitten);
        formik.setFieldValue("dogBoarding", services.boardingSerivce.dogBoardingSerivce.enable);
        formik.setFieldValue("catBoarding", services.boardingSerivce.catBoardingSerivce.enable);
        formik.setFieldValue("boarding", services.boardingSerivce.enable);



        return () => {

        }
    }, [])


    const formik = useFormik({
        initialValues: {
            dogGrooming: false,
            catGrooming: false,
            groomingSmall: false,
            groomingMedium: false,
            groomingLarge: false,
            groomingKitten: false,
            groomingCat: false,
            grooming: false,
            boardingSmall: false,
            boardingMedium: false,
            boardingLarge: false,
            boardingKitten: false,
            boardingCat: false,
            boarding: false,
            dogBoarding: false,
            catBoarding: false


        },
        onSubmit: async (data) => {
       const service=     {
                "groomingService": {
                    "dogGroomingService": {
                        "small": data.groomingSmall,
                        "medium": data.groomingMedium,
                        "large": data.groomingLarge,
                        "enable": data.dogGrooming
                    },
                    "catGroomingService": {
                        "kitten": data.groomingKitten,
                        "cat": data.groomingCat,
                        "enable": data.catGrooming
                    },
                    "enable": data.grooming
                },
                "boardingSerivce": {
                    "dogBoardingSerivce": {
                        "small": data.boardingSmall,
                        "medium": data.boardingMedium,
                        "large": data.boardingLarge,
                        "enable": data.dogBoarding
                    },
                    "catBoardingSerivce": {
                        "kitten": data.boardingKitten,
                        "cat": data.boardingCat,
                        "enable": data.catBoarding
                    },
                    "enable": data.boarding
                }
            }
            dispatch(editCenterData({ services: service, centerId: center.centre._id }));


        }


    });

    return (
        <>
            <div>
                <form onSubmit={formik.handleSubmit}>
                  
                    <div className='flex flex-row'>
                        <h3>
                            Grooming
                        </h3> <InputSwitch name="grooming" onChange={formik.handleChange} checked={formik.values.grooming} />
                    </div>
                    <div className='flex flex-row'>
                        <h5>Dog grooming</h5> <InputSwitch name="dogGrooming" onChange={formik.handleChange} checked={formik.values.dogGrooming} />
                    </div>
                    <div className='flex flex-row  align-items-center align-content-center'>
                        <h5 className='pl-2'>Small Dog</h5> <InputSwitch name="groomingSmall" onChange={formik.handleChange} checked={formik.values.groomingSmall} />
                        <h5 className='pl-2'>Medium Dog</h5> <InputSwitch name="groomingMedium" onChange={formik.handleChange} checked={formik.values.groomingMedium} />
                        <h5 className='pl-2'>Large Dog</h5> <InputSwitch name="groomingLarge" onChange={formik.handleChange} checked={formik.values.groomingLarge} />
                    </div>
                    <div className='flex flex-row'>
                        <h5>Cat Grooming</h5> <InputSwitch name="catGrooming" onChange={formik.handleChange} checked={formik.values.catBoarding} />
                    </div>

                    <div className='flex flex-row align-items-center align-content-center'>
                        <h5 className='pl-2'>Kitten</h5> <InputSwitch name="groomingKitten" onChange={formik.handleChange} checked={formik.values.groomingKitten} />
                        <h5 className='pl-2'>Cat</h5> <InputSwitch name="groomingCat" onChange={formik.handleChange} checked={formik.values.groomingCat} />
                    </div>



                    <div className='flex flex-row'>
                        <h3>Boarding</h3> <InputSwitch name="boarding" onChange={formik.handleChange} checked={formik.values.boarding} />
                    </div>
                    <div className='flex flex-row'>
                        <h5>Dog Boarding</h5> <InputSwitch name="dogBoarding" onChange={formik.handleChange} checked={formik.values.dogBoarding} />
                    </div>
                    <div className='flex flex-row  align-items-center align-content-center'>
                        <h5 className='pl-2'>Small Dog</h5> <InputSwitch name="boardingSmall" onChange={formik.handleChange} checked={formik.values.boardingSmall} />
                        <h5 className='pl-2'>Medium Dog</h5> <InputSwitch name="boardingMedium" onChange={formik.handleChange} checked={formik.values.boardingMedium} />
                        <h5 className='pl-2'>Large Dog</h5> <InputSwitch name="boardingLarge" onChange={formik.handleChange} checked={formik.values.boardingLarge} />
                    </div>
                    <div className='flex flex-row'>
                        <h5>Cat Boarding</h5> <InputSwitch name="catBoarding" onChange={formik.handleChange} checked={formik.values.catBoarding} />
                    </div>

                    <div className='flex flex-row align-items-center align-content-center'>
                        <h5 className='pl-2'>Kitten</h5> <InputSwitch name="boardingKitten" onChange={formik.handleChange} checked={formik.values.boardingKitten} />
                        <h5 className='pl-2'>Cat</h5> <InputSwitch name="boardingCat" onChange={formik.handleChange} checked={formik.values.boardingCat} />
                    </div>
                    <Button type='submit'  loading={loading} label="Update" className="p-button-secondary" />



                </form>

            </div>
        </>
    );
}
