import React, { useEffect, useState } from 'react'
import { useUpdateDoctorPaymentRequestStatusMutation } from '../manager/doctors_services';
import { Button } from 'primereact/button';
import CustomInputField from '../../../app/components/custom_input_field';
import appService from '../../../app/services/app_service';
import toastService from '../../../app/services/toast_service';

export default function UpdatePaymentStatusDialog({status,id,onHide}) {
    const [updateStatus, { isLoading: addLoading, error: addError, status: addStatus }] = useUpdateDoctorPaymentRequestStatusMutation();

    const [updatedStatus,setUpdatedStatus] = useState("");
    const [transactionId,setTransactionId] = useState("");
const options = [
    {
        "name": "Completed",
        "value": "completed"
    },
    {
        "name": "Cancelled",
        "value": "cancelled"
    }
];

const onSubmit=(e)=>{
    e.preventDefault();
    if(updatedStatus.length == 0){
        toastService.error("Please choose a status");
        return;
    }
    updateStatus({
        "logId":id,
        "status":updatedStatus,
        //status will be by default pending,cancelled,in_progress and completed
        "transactionId":transactionId,
   
    });

}

useEffect(() => {

    appService.showApiResponseStatus(
      {
        status: addStatus,
        msg: "Added successfully!",
        error: addError,
        successCB: () => {

          onHide();
        }
      }
    );



  }, [addStatus]);
  return (
    <div>
        <form onSubmit={onSubmit}>
        {<div className='field'>
          <div className="col-12 md:col-6\8">
            <div className=" p-fluid">
            <CustomInputField
                                type='dropdown'
                                label="Statu"
                                iden='name'
                                options={options}
                                value={updatedStatus}
                            
                                onChange={(e)=>setUpdatedStatus(e.target.value)}
                                
                                placeHolder="Status" />

                                   <CustomInputField
                                type='text'
                                label="Transaction Id"
                                iden='name'
                                
                                value={transactionId}
                                onChange={(e)=>setTransactionId(e.target.value)}
                              
                                placeHolder="Transaction Id" />
            </div></div>

        </div>}
        {
          <Button
         
            type='submit'
            label='Update'
            className='p-button-success'

          />}
        </form>

    </div>
  )
}
