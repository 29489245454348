import React from 'react';

export const AppFooter = (props) => {

    return (
        <div className="layout-footer">
            <img src={props.layoutColorMode === 'light' ? 'icons/logo.svg' : 'icons/logo.svg'} alt="Logo" height="50" className="mr-2" />
        
            <span className="font-medium ml-2">Pet Parenting</span>
        </div>
    );
}
