import React from 'react'
import { Image } from 'primereact/image';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDate } from '@fullcalendar/core';
import { FILE_BASE_URL } from '../../../config';


export default function BoardingDetailsDialog({ boarding, onHide,isCentered }) {



    function checkInBody(row){
        return formatDate(row.checkIn);
    }
    function checkoutBody(row){
        return formatDate(row.checkOut);
        
    }
    function paymentStatusBody(row){
        return <div>{row.payment.paid===true?'Paid':'Not paid'}</div>;
        
    }
    function centerProfileImageBody(row){
        return <Image width='50px' height='50px' src={`${FILE_BASE_URL}${row.centre.profileImage}`} alt='Profile Image' template='Preview' />;
        
    }

    return (
        <>

            <h3>
                Pet
            </h3>
            <DataTable value={[boarding]} responsiveLayout="scroll">
                <Column field="pet.petName" header="Name"></Column>
                <Column field="pet.petAge" header="Age"></Column>
                <Column field="pet.petWeight" header="Weight"></Column>
                <Column field="pet.vaccinationDate" header="Vaccination date"></Column>
                <Column field="pet.medicalHistory" header="Medical History"></Column>
                <Column field="pet.about" header="About"></Column>
                <Column field="contact.guardian" header="Guardian Contact"></Column>
                <Column field="contact.emergency" header="Emergency Contact"></Column>
            </DataTable>

            <h3>
                User info
            </h3>
            <DataTable value={[boarding]} responsiveLayout="scroll">
                <Column field="user.firstName" header="First name"></Column>
                <Column field="user.lastName" header="Last name"></Column>
                <Column field="user.phone" header="Phone"></Column>
        
            </DataTable>

            <h3>
                Boarding details
            </h3>
            <DataTable value={[boarding]} responsiveLayout="scroll">
                <Column field="package.title" header="Package title"></Column>
                <Column field="package.description" header="Package desc"></Column>
                <Column field="package.size" header="Package size"></Column>
                <Column field="package.price" header="Package price"></Column>
                <Column field="totalDays" header="Total days"></Column>
                <Column field="amount" header="Total amount"></Column>
                <Column field="" header="Checkin" body={checkInBody}></Column>
                <Column field="" header="checkout" body={checkoutBody}></Column>
                <Column field="" header="Payment Status" body={paymentStatusBody}></Column>
                <Column field="payment.method" header="Method"></Column>
        
            </DataTable>

        {isCentered===true&& <h3>
            Center

            </h3>}
      {isCentered===true&&      <DataTable value={[boarding]} responsiveLayout="scroll">
                <Column  header="Profile" body={centerProfileImageBody}></Column>
                <Column field="centre.name" header="Name"></Column>
                <Column field="centre.phone" header="Phone"></Column>
               
        
            </DataTable>}

        </>
    )
}
