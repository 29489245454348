import React, { useEffect, useState } from 'react'
import Previews from '../components/drop_zone'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useGetPostCategoriesQuery } from '../manager/posts_service';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { bunnyService } from '../../../app/services/bunny_service';
import { getUniqueId } from '../../../app/services/uuid_serivce';

export default function AddPostsScreen() {

    const [files, setFiles] = useState([]);
    const [posts, setPosts] = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);

    const { data: categories } = useGetPostCategoriesQuery();


    const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 200,
        height: 100,
        padding: 4,
        boxSizing: 'border-box',
        textAlign: 'center'

    };
    const img = {
        display: 'block',
        width: 'auto',
        height: '100%'
    };
    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };


    const handleUploadClick = async () => {

        setUploadLoading(true);
        for (var i = 0; i < posts.length; i++) {

            let type = classifyFile(posts[i].file.type);
            var post;
            if (type == 'video') {
                try {
                    const createdVideo = await bunnyService.createVideo();
                    const uploadVideo = await bunnyService.uploadVideo(
                        {
                            guid: createdVideo.data.guid,
                            data: posts[i].file
                        }
                    );
                    let link = `https://vz-8e778deb-1e3.b-cdn.net/${createdVideo.data.guid}/playlist.m3u8?v=1692290846`;
                    let thumbnail = `https://vz-8e778deb-1e3.b-cdn.net/${createdVideo.data.guid}/thumbnail.jpg?v=1692290846`;

                    post = {
                        "title": posts[i].title,
                        "categories": posts[i].category,
                        "tags": [],
                        "type": type,
                        "links": [link],
                        "user": "64ce8ca3c3c21976e9dbc912",
                        "adDate": "",
                        "thumbnail": thumbnail
                    };

                } catch (error) {
                    continue;
                }


            } else {
                try {
                    let fileName = posts[i].file.name;
                    const fileExtension = fileName.split('.').pop()
                    let uniqueName = `${getUniqueId()}.${fileExtension}`;
                    await bunnyService.uploadImage({ data: posts[i].file, fileName: uniqueName });

                    post = {
                        "title": posts[i].title,
                        "categories": posts[i].category,
                        "tags": [],
                        "type": type,
                        "links": [`https://copet.b-cdn.net/images/${uniqueName}`],
                        "user": "64ce8ca3c3c21976e9dbc912",
                        "adDate": "",
                        "thumbnail": ""
                    };
                } catch (error) {
                    continue;
                }
            }



            console.log(post);

            try {
                const addPost = await bunnyService.addPost({
                    post: post,

                })

                setPosts((prev) => {
                    prev[i].is_uploaded = true;
                    return [...prev];
                })
            } catch (error) {
                continue;
            }
        }

        setUploadLoading(false);

    }

    const classifyFile = (mimeType) => {
        if (mimeType.startsWith('image/')) {
            return 'photo';
        } else if (mimeType.startsWith('video/')) {
            return 'video';
        } else {
            return 'unknown';
        }
    };
    const readFileAsArrayBuffer = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = (error) => reject(error);

            reader.readAsBinaryString(file);
        });
    };




    useEffect(() => {
        var count = 1;
        setPosts(files.map((item) => {

            return {
                "file": item,
                "is_uploaded": false,
                "title": "",
                "category": [],
                "progress": 0,
                "id": count++
            };
        }))
    }, [files])

    const previewBody = (body) => {
        console.log(data);
        var data = body;
        return <div style={thumb} key={data.file.name}>
            <div style={thumbInner}>
                {data.file.type.startsWith('image') ? (
                    <img src={data.file.preview} style={img} onLoad={() => URL.revokeObjectURL(data.file.preview)} alt="Preview" />
                ) : (
                    <video src={data.file.preview} style={img} controls />
                )}
            </div>
        </div>;
    }

    const returnTitleBody = (rowData, props) => {
        return <div>
            <InputText onChange={(e) => {
                setPosts((prev) => {
                    prev[props.rowIndex].title = e.target.value;
                    return [...prev];
                })
            }} value={rowData.title} />
        </div>
    }

    const returnCategoryBody = (rowData, props) => {
        return <div>


            <MultiSelect placeholder="Category" options={categories?.data ?? []} optionLabel="category" optionValue="_id" value={rowData.category} onChange={(e) => {
                setPosts((prev) => {
                    prev[props.rowIndex].category = e.target.value;
                    return [...prev];
                })
            }} />

        </div>
    }

    const returnUploadStatusBody = (rowData) => {
        return <div>
            {rowData.is_uploaded ? "Uploaded" : "Not uploaded"}
        </div>
    }


    return (
        <div>
            <div className='card flex justify-content-between'>
                <Previews files={files} setFiles={setFiles} />
                <Button label='Upload' loading={uploadLoading} onClick={handleUploadClick} />
            </div>
            <div className='card'>
                <DataTable value={posts} >
                    <Column field='id' header="#" />
                    <Column header="Preview" body={previewBody} />
                    <Column field='title' body={returnTitleBody} header="Title" />
                    <Column field='category' body={returnCategoryBody} header="Category" />
                    <Column field='is_uploaded' body={returnUploadStatusBody} header="Upload status" />

                </DataTable>
            </div>
        </div>
    )
}
