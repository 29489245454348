// dateFormatService.js
import moment from 'moment';

// Function to format a date to a specific format
export const formatDate = (date, format = 'YYYY-MM-DD') => {
  let formated = moment(date).format(format);
  return formated;
};
export const formatDateTime = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(date).format(format);
};

export const formatTimeOnly = (date, format = 'HH:mm:ss') => {
  return moment(date).format(format);
};

// Function to convert a date to the local time zone
export const convertToLocalTimezone = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  const localTime = moment(date).local();
  return localTime.format(format);
};