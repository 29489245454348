import { ADD_CENTER_FAILURE, ADD_CENTER_REQUEST, ADD_CENTER_RESET, ADD_CENTER_SUCCESS, EDIT_CENTER_DATA_FAILURE, EDIT_CENTER_DATA_REQUEST, EDIT_CENTER_DATA_RESET, EDIT_CENTER_DATA_SUCCESS, EDIT_CENTER_FAILURE, EDIT_CENTER_REQUEST, EDIT_CENTER_RESET, EDIT_CENTER_SUCCESS, Get_CENTERS_LIST_FAILURE, Get_CENTERS_LIST_REQUEST, Get_CENTERS_LIST_SUCCESS, GET_CENTER_DETAILS_FAILURE, GET_CENTER_DETAILS_REQUEST, GET_CENTER_DETAILS_SUCCESS } from "../constants/centers_constants";

function getCentersReducer(state = {}, action) {
  switch (action.type) {
    case Get_CENTERS_LIST_REQUEST:
      return { loading: true };
    case Get_CENTERS_LIST_SUCCESS:
      return { loading: false, centers: action.payload };
    case Get_CENTERS_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default: return state;
  }
}
function getCenterDetailsReducer(state = {}, action) {
  switch (action.type) {
    case GET_CENTER_DETAILS_REQUEST:
      return { loading: true };
    case GET_CENTER_DETAILS_SUCCESS:
      return { loading: false, center: action.payload };
    case GET_CENTER_DETAILS_FAILURE:
      return { loading: false, error: action.payload };
    default: return state;
  }
}


function addCenterReducer(state = {}, action) {
  switch (action.type) {
    case ADD_CENTER_REQUEST:
      return { loading: true };
    case ADD_CENTER_SUCCESS:
      return { loading: false, center: action.payload, success: true };
    case ADD_CENTER_FAILURE:
      return { loading: false, error: action.payload, success: false };
    case ADD_CENTER_RESET:
      return {};
    default: return state;
  }
}

function editCenterReducer(state = {}, action) {
  switch (action.type) {
    case EDIT_CENTER_REQUEST:
      return { loading: true };
    case EDIT_CENTER_SUCCESS:
      return { loading: false, center: action.payload, success: true };
    case EDIT_CENTER_FAILURE:
      return { loading: false, error: action.payload, success: false };
    case EDIT_CENTER_RESET:
      return {};
    default: return state;
  }
}

function editCenterDataReducer(state = {}, action) {
  switch (action.type) {
    case EDIT_CENTER_DATA_REQUEST:
      return { loading: true };
    case EDIT_CENTER_DATA_SUCCESS:
      return { loading: false, center: action.payload, success: true };
    case EDIT_CENTER_DATA_FAILURE:
      return { loading: false, error: action.payload, success: false };
    case EDIT_CENTER_DATA_RESET:
      return {};
    default: return state;
  }
}

export { getCentersReducer, addCenterReducer, getCenterDetailsReducer, editCenterReducer, editCenterDataReducer }