import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';

import React, { useState } from 'react'
import EditCatBoardingDialog from './edit_cat_boarding_dialog';

export default function CatBoardingTab({boardings}) {

  const [boarding,setBoarding]=useState(null);
  const [editDialog,setEditDialog]=useState(false);



  const dialogFuncMap = {
    'editDialog': setEditDialog,
 
}
const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

  
}


const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
}


    const getActiveStatusBody=(row)=>{
        return <span>{row.enable===true?'Active':'Inactive'}</span>;
    }
   
    const getActionBody=(row)=>{
        return <div className='flex flex-row'>
            <Button icon="pi pi-user-edit" className="p-button-rounded p-button-primary" onClick={()=>{
                 setBoarding(row);
                 onClick('editDialog');
                         
                            }} aria-label="Edit" />
          
        </div>
    }

  return (

    <div>
      <Dialog header='Edit' visible={editDialog} onHide={() => onHide('editDialog')}  breakpoints={{ '960px': '40vw', '640px': '100vw' }} style={{ width: '70vw' }}>
                <EditCatBoardingDialog boarding={boarding} onHide={()=>onHide('editDialog')} />
            </Dialog>

            <DataTable value={boardings} paginator className="p-datatable-gridlines" showGridlines rows={10}
    dataKey="id" filterDisplay="menu" responsiveLayout="scroll"
    emptyMessage="No data found.">
    <Column field="title" header="Name" filter filterPlaceholder="Search by slot" style={{ minWidth: '12rem' }} />
    <Column field="description" header="Description" filter filterPlaceholder="Search by description" style={{ minWidth: '12rem' }} />
    <Column field="catPrice.kitten" header="Kitten price" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
    <Column field="catPrice.cat" header="Cat price" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
    <Column field="enable" header="Active" filter filterPlaceholder="Search by timing" body={getActiveStatusBody} style={{ minWidth: '12rem' }} />
    <Column  header="Actions" body={getActionBody}  style={{ minWidth: '12rem' }} />



</DataTable>
    </div>
  
   

  )
}
