
// Function to convert military time to Date object in UTC
function militaryTimeToUtcDate(militaryTime) {
  const hours = Math.floor(militaryTime / 100);
  const minutes = militaryTime % 100;

  const dateInUtc = new Date(Date.UTC(2023, 7, 3, hours, minutes));
  return dateInUtc;
}

// Function to convert UTC time to local time

  
function formatDateToAMPM(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    
    return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
  }
  
  const militaryTimeToLocal=(time)=>{
  // Example usage:
  console.log(time);
  const militaryTime = time; // 9:00 AM
  
  const normalTime = militaryTimeToUtcDate(militaryTime);
  console.log('Normal Time:', normalTime);

  return formatDateToAMPM(normalTime);
  

  }
  function formatTimeToHHMM(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}`;
  }
  const militaryTimeToLocalHHMM = (time) => {
    const militaryTime = time;
    const normalTime = militaryTimeToUtcDate(militaryTime);
  
    return formatTimeToHHMM(normalTime);
  };
  module.exports = {militaryTimeToLocal,militaryTimeToLocalHHMM}