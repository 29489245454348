import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';

import React, { useState } from 'react'
import EditDogBoardingDialog from './edit_dog_boarding_dialog';

export default function DogBoardingTab({boardings}) {

  const [boarding,setBoarding]=useState(null);
  const [editDialog,setEditDialog]=useState(false);


  const dialogFuncMap = {
    'editDialog': setEditDialog,
 
}
const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

  
}


const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
}

    const getActiveStatusBody=(row)=>{
        return <span>{row.enable===true?'Active':'Inactive'}</span>;
    }
   
    const getActionBody=(row)=>{
        return <div className='flex flex-row'>
            <Button icon="pi pi-user-edit" className="p-button-rounded p-button-primary" onClick={()=>{
                 setBoarding(row);
                 onClick('editDialog');
                         
                            }} aria-label="Edit" />
          
        </div>
    }

  return (
    <div>
        <Dialog header='Edit' visible={editDialog} onHide={() => onHide('editDialog')}  breakpoints={{ '960px': '40vw', '640px': '100vw' }} style={{ width: '70vw' }}>
                <EditDogBoardingDialog boarding={boarding} onHide={()=>onHide('editDialog')} />
            </Dialog>
            <DataTable value={boardings} paginator className="p-datatable-gridlines" showGridlines rows={10}
    dataKey="id" filterDisplay="menu" responsiveLayout="scroll"
    emptyMessage="No data found.">
    <Column field="title" header="Name" filter filterPlaceholder="Search by slot" style={{ minWidth: '12rem' }} />
    <Column field="description" header="Description" filter filterPlaceholder="Search by description" style={{ minWidth: '12rem' }} />
    <Column field="dogPrice.small" header="Small dog price" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
    <Column field="dogPrice.medium" header="Medium dog price" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
    <Column field="dogPrice.large" header="Large dog price" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
    <Column field="enable" header="Active" filter filterPlaceholder="Search by timing" body={getActiveStatusBody} style={{ minWidth: '12rem' }} />
    <Column  header="Actions" body={getActionBody}  style={{ minWidth: '12rem' }} />



</DataTable>
    </div>
  
 

  )
}


// {
//   "_id": "62e6660c7fcc11fd0907d858",
//   "category": "grooming",
//   "subCategory": "dog",
//   "centre": "62deda3a1d9d4346e1c49cb3",
//   "date": "2022-08-04T19:00:00.000Z",
//   "isRated": false,
//   "package": {
//       "title": "Basic Grooming + Conditioning",
//       "description": "Face hair shaping, ear cleaning, Nail clipping, Paw Pad cleaning & hair trimming, Sanitary side cleaning & Hair trimming, Shampooing, Conditioning & Blower dry",
//       "size": "large",
//       "price": "1950"
//   },
//   "paid": false,
//   "paymentMethod": "cash-on-arrival",
//   "size": "kitten",
//   "time": "12:30 PM",
//   "user": {
//       "_id": "62e62be7626d739f7c7384f7",
//       "firstName": "Abdul",
//       "lastName": "Malik",
//       "phone": "+923041160017",
//       "profileImage": "images/defaultImage.jpg"
//   },
//   "status": "pending",
//   "rating": 0,
//   "txnToken": null,
//   "createdAt": "2022-07-31T11:22:52.228Z",
//   "updatedAt": "2022-07-31T11:22:52.228Z",
//   "__v": 0
// },