export const Get_CENTERS_LIST_REQUEST = 'Get_CENTERS_LIST_REQUEST';
export const Get_CENTERS_LIST_SUCCESS = 'Get_CENTERS_LIST_SUCCESS';
export const Get_CENTERS_LIST_FAILURE = 'Get_CENTERS_LIST_FAILURE';


export const ADD_CENTER_REQUEST = 'ADD_CENTER_REQUEST';
export const ADD_CENTER_SUCCESS = 'ADD_CENTER_SUCCESS';
export const ADD_CENTER_FAILURE = 'ADD_CENTER_FAILURE';
export const ADD_CENTER_RESET = 'ADD_CENTER_RESET';


export const GET_CENTER_DETAILS_REQUEST = 'GET_CENTER_DETAILS_REQUEST';
export const GET_CENTER_DETAILS_SUCCESS = 'GET_CENTER_DETAILS_SUCCESS';
export const GET_CENTER_DETAILS_FAILURE = 'GET_CENTER_DETAILS_FAILURE';


export const EDIT_CENTER_REQUEST = 'EDIT_CENTER_REQUEST';
export const EDIT_CENTER_SUCCESS = 'EDIT_CENTER_SUCCESS';
export const EDIT_CENTER_FAILURE = 'EDIT_CENTER_FAILURE';
export const EDIT_CENTER_RESET = 'EDIT_CENTER_RESET';

export const EDIT_CENTER_DATA_REQUEST = 'EDIT_CENTER_DATA_REQUEST';
export const EDIT_CENTER_DATA_SUCCESS = 'EDIT_CENTER_DATA_SUCCESS';
export const EDIT_CENTER_DATA_FAILURE = 'EDIT_CENTER_DATA_FAILURE';
export const EDIT_CENTER_DATA_RESET = 'EDIT_CENTER_DATA_RESET';


