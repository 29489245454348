import React, { useRef }  from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Menu } from 'primereact/menu';
import { useDispatch } from 'react-redux';
import { LOGOUT_USER } from './constants/user_constants';
import { logout } from './actions/users_actions';

export const AppTopbar = (props) => {
    const menu = useRef(null);
    const dispatch=useDispatch();
    const items = [
        {
            label: 'Account',
            items: [
              
          {
                    label: 'Logout',
                    icon: 'pi pi-sign-out',
                    command: () => {
                        dispatch(logout())

                    }
                }
            ]
        }
    ];


    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
            <img style={{"width":'150px',height:'70px','objectFit':'contain'}} src={props.layoutColorMode === 'light' ? 'icons/logo.svg' : 'icons/logo.svg'} alt="logo"/>
                <span>Pet Parenting</span>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                    {/* <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-calendar"/>
                            <span>Events</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-cog"/>
                            <span>Settings</span>
                        </button>
                    </li> */}
                    <li>
                        <button className="p-link layout-topbar-button"onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup >
                            <i className="pi pi-user"/>
                            <span>Profile</span>
                        </button>
                        <Menu model={items} popup ref={menu} id="popup_menu" />

                    </li>
                </ul>
        </div>
    );
}
