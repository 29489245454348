import * as consts from './../constants/appointments_constants'
import { BASE_URL } from './../config';
import { axiosApi } from '../app/services/axios_api';
const getAllGroomingList = () => async (dispatch, getState) => {
    dispatch({ type: consts.GET_ALL_GROOMING_APPOINTMENTS_REQUEST });

    const url = BASE_URL + 'api/appointment/all';

    try {
        const { data } = await axiosApi.get(url,
        );
        dispatch({ type: consts.GET_ALL_GROOMING_APPOINTMENTS_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: consts.GET_ALL_GROOMING_APPOINTMENTS_FAILURE, payload: error.response && error.response.data.msg
                ? error.response.data.msg
                : error.message
        });
    }
}
const getAllBoardingList = () => async (dispatch, getState) => {
    dispatch({ type: consts.GET_ALL_BOARDING_APPOINTMENTS_REQUEST });

    const url = BASE_URL + 'api/boarding/all';

    try {
        const { data } = await axiosApi.get(url,
        );
        dispatch({ type: consts.GET_ALL_BOARDING_APPOINTMENTS_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: consts.GET_ALL_BOARDING_APPOINTMENTS_FAILURE, payload: error.response && error.response.data.msg
                ? error.response.data.msg
                : error.message
        });
    }
}

const getCenterGroomingList = (centerId) => async (dispatch, getState) => {
    dispatch({ type: consts.GET_CENTER_GROOMING_APPOINTMENTS_REQUEST });

    const url = BASE_URL + 'api/appointment/list?centre='+centerId;

    try {
        const { data } = await axiosApi.get(url,
        );
        dispatch({ type: consts.GET_CENTER_GROOMING_APPOINTMENTS_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: consts.GET_CENTER_GROOMING_APPOINTMENTS_FAILURE, payload: error.response && error.response.data.msg
                ? error.response.data.msg
                : error.message
        });
    }
}
const getCenterBoardingList = (centerId) => async (dispatch, getState) => {
    console.log("ehhlo");
    dispatch({ type: consts.GET_CENTER_BOARDING_APPOINTMENTS_REQUEST });

    const url = BASE_URL + 'api/boarding/list?centre='+centerId;
    console.log(url);

    try {
        const { data } = await axiosApi.get(url,
        );
        console.log(data.data);
        dispatch({ type: consts.GET_CENTER_BOARDING_APPOINTMENTS_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: consts.GET_CENTER_BOARDING_APPOINTMENTS_FAILURE, payload: error.response && error.response.data.msg
                ? error.response.data.msg
                : error.message
        });
    }
}

export {getAllGroomingList,getAllBoardingList,getCenterBoardingList,getCenterGroomingList}