import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'

export default function MulitiInputField({ id, name, onChange, placeHolder, schema, defaultValues, label }) {

    const [values, setValues] = useState([]);
    const [value, setValue] = useState("");

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setValues((prev) => [...prev, e.target.value]);
            onChange(values);
            console.log(values);
            setValue("");
        }
    };
    useEffect(() => {
    setValues(defaultValues);
    }, [])
    

    return (

        <div className='field'>
               <label className='' htmlFor="name1">{label}</label>
            <InputText
                autoComplete='off'
                autoSave='off'
                id={id}
                name={name}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={handleKeyDown}
                type='text' placeholder={placeHolder} />

            <ul>
                {
                    values?.map((item,index) => {
                        return <div key={`${item}-${index}`} className='flex flex-row justify-content-between'>
                            <div>
                                {item}
                            </div>
                            <div>
                                <Button 
                                onClick={()=>{
                                    setValues((prev)=>{
                                        prev.splice(index,1);
                                        return [...prev];
                                    })
                                }} icon="pi pi-times" 
                                className="p-button-rounded p-button-text" />

                            </div>
                        </div>
                    })
                }
            </ul>

        </div>
    )
}
