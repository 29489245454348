import { formatDate } from '@fullcalendar/core';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { getCenterBoardingList } from '../../../actions/appointments_actions';
import BoardingDetailsDialog from '../components/boarding_details_dialog';

export default function CenterBoardingAppointmentsTab() {
    const allBoardingList = useSelector((state) => state.centerBoardingList);
    const { loading, list } = allBoardingList;
    const dispatch = useDispatch();
    const params=useParams();
    const {id}=params;

    const [editBoardingDialog, setEditBoardingDialog] = useState(false);
    const [boarding, setBoarding] = useState(null);

    const dialogFuncMap = {
        'editBoardingDialog': setEditBoardingDialog,

    }
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);


    }


    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }




    useEffect(() => {
        dispatch(getCenterBoardingList(id));

    }, [dispatch,id])

    function checkInBody(row){
        return formatDate(row.checkIn);
    }
    function checkoutBody(row){
        return formatDate(row.checkOut);
        
    }



    const getActiveStatusBody = (row) => {
        return <span>{row.enable === true ? 'Active' : 'Inactive'}</span>;
    }

    const getActionBody = (row) => {
        return <div className='flex flex-row'>
            <Button icon="pi pi-arrow-up" className="p-button-rounded p-button-primary" onClick={() => {

                setBoarding(row);
                onClick('editBoardingDialog')
            }} aria-label="Edit" />

        </div>
    }

    return (
        <div>
                 <Dialog position='bottom' header='Boarding' visible={editBoardingDialog} onHide={() => onHide('editBoardingDialog')}  breakpoints={{ '960px': '90vw', '640px': '100vw' }} style={{ width: '80vw' }}>
                <BoardingDetailsDialog boarding={boarding} onHide={()=>onHide('editBoardingDialog')} />
            </Dialog>

            <div className='card'>
                <DataTable value={list} loading={loading} paginator className="p-datatable-gridlines" showGridlines rows={20}
                    dataKey="id" filterDisplay="menu" responsiveLayout="scroll"
                    emptyMessage="No data found.">
                    <Column field="petType" header="Pet" filter filterPlaceholder="Search by slot" style={{ minWidth: '12rem' }} />
                    <Column field="petSize" header="Size" filter filterPlaceholder="Search by description" style={{ minWidth: '12rem' }} />
                    <Column field="package.price" header="Price" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
                    <Column field="checkIn" body={checkInBody} header="Check In" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
                    <Column field="checkOut" body={checkoutBody} header="Check Out" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
                    <Column field="user.firstName" header="First Name" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
                    <Column field="user.lastName" header="Last Name" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
                    <Column field="user.phone" header="Phone" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
                    <Column field="status" header="Status" filter filterPlaceholder="Search by timing"  style={{ minWidth: '12rem' }} />
                    <Column header="Actions" body={getActionBody} style={{ minWidth: '12rem' }} />



                </DataTable>

            </div>
        </div>
    )
}
