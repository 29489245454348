import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SendNotificationDialog from '../components/SendNotificationDialog';
import { useEffect } from 'react';
import {  getNotifications } from '../../../slices/notification_slice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function NotificationsScreen() {


    const dispatch=useDispatch();

    const [showSendNotificationDialog,setShowEditNotificationDialog]=useState(false);

    const {notifications,notificationsLoading}=useSelector((state)=>state.notifications);
    const [editData,setEditData]=useState(null);

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    
    const dialogFuncMap = {
        'showSendNotificationDialog': setShowEditNotificationDialog,
     
    }
    useEffect(() => {
    
        dispatch(getNotifications());
    }, [dispatch])
    


  return (
    <div className='card'>

<Dialog header={'Send Notification'} visible={showSendNotificationDialog} onHide={() => onHide('showSendNotificationDialog')}  breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '70vw' }}>
                <SendNotificationDialog editData={editData} onHide={()=>onHide('showSendNotificationDialog')} />
            </Dialog>


            <div className='flex flex-row justify-content-between align-content-center align-items-center pb-3'>
                    <h5>Notifications</h5>
                    <Button label='Add new' className='p-button-success p-button-rounded p-button-sm' onClick={() =>{
                           setEditData(null);
                        onClick('showSendNotificationDialog')}} />


                </div>

                <DataTable value={notifications} paginator className="p-datatable-gridlines" showGridlines rows={10}
                    dataKey="id" filterDisplay="menu" loading={notificationsLoading} responsiveLayout="scroll"
                    emptyMessage="No topics found.">
                    <Column field="title" header="Title" style={{ minWidth: '12rem' }} />
                    <Column field="body" header="Body" style={{ minWidth: '12rem' }} />
            
                </DataTable>


    </div>
  )
}
