import { ADD_ADMIN_USER_FAILURE, ADD_ADMIN_USER_REQUEST, ADD_ADMIN_USER_SUCCESS, EDIT_ADMIN_USER_FAILURE, EDIT_ADMIN_USER_REQUEST, EDIT_ADMIN_USER_SUCCESS, GET_ADMIN_USERS_FAILURE, GET_ADMIN_USERS_REQUEST, GET_ADMIN_USERS_SUCCESS, GET_APP_USERS_FAILURE, GET_APP_USERS_REQUEST, GET_APP_USERS_SUCCESS, GET_ROLES_FAILURE, GET_ROLES_REQUEST, GET_ROLES_SUCCESS, LOGIN_USER_FAILURE, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGOUT_USER, RESET_ADD_ADMIN_USER, RESET_EDIT_ADMIN_USER } from "../constants/user_constants";

function getAdminUsersReducer(state = {adminUsers:[]}, action) {
  switch (action.type) {
    case GET_ADMIN_USERS_REQUEST:
      return { loading: true };
    case GET_ADMIN_USERS_SUCCESS:
      return { loading: false, adminUsers: action.payload };
    case GET_ADMIN_USERS_FAILURE:
      return { loading: false, error: action.payload };
    default: return state;
  }
}
function loginUserReducer(state = {}, action) {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return { loading: true };
    case LOGIN_USER_SUCCESS:
      return { loading: false, user: action.payload ,success:true};
    case LOGIN_USER_FAILURE:
      return { loading: false, error: action.payload,success:false };
      case LOGOUT_USER:
        return {};
    default: return state;
  }
}
function getRolesReduces(state = {roles:[]}, action) {
  switch (action.type) {
    case GET_ROLES_REQUEST:
      return { loading: true };
    case GET_ROLES_SUCCESS:
      return { loading: false, roles: action.payload };
    case GET_ROLES_FAILURE:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function addAdminUserReducer(state = {}, action) {
  switch (action.type) {
    case ADD_ADMIN_USER_REQUEST:
      return { loading: true };
    case ADD_ADMIN_USER_SUCCESS:
      return { loading: false, user: action.payload ,success:true};
    case ADD_ADMIN_USER_FAILURE:
      return { loading: false, error: action.payload,success:false };
      case RESET_ADD_ADMIN_USER:
        return {};
    default: return state;
  }
}
function editAdminUserReducer(state = {}, action) {
  switch (action.type) {
    case EDIT_ADMIN_USER_REQUEST:
      return { loading: true };
    case EDIT_ADMIN_USER_SUCCESS:
      return { loading: false, user: action.payload ,success:true};
    case EDIT_ADMIN_USER_FAILURE:
      return { loading: false, error: action.payload,success:false };
      case RESET_EDIT_ADMIN_USER:
        return {};
    default: return state;
  }
}

function getAppUsersReducer(state = {}, action) {
  switch (action.type) {
    case GET_APP_USERS_REQUEST:
      return { loading: true };
    case GET_APP_USERS_SUCCESS:
      return { loading: false, users: action.payload};
    case GET_APP_USERS_FAILURE:
      return { loading: false, error: action.payload};
    default: return state;
  }
}

// function userUpdateReducer(state = {}, action) {
//   switch (action.type) {
//     case USER_UPDATE_REQUEST:
//       return { loading: true };
//     case USER_UPDATE_SUCCESS:
//       return { loading: false, userInfo: action.payload };
//     case USER_UPDATE_FAIL:
//       return { loading: false, error: action.payload };
//     default: return state;
//   }
// }

// function userRegisterReducer(state = {}, action) {
//   switch (action.type) {
//     case USER_REGISTER_REQUEST:
//       return { loading: true };
//     case USER_REGISTER_SUCCESS:
//       return { loading: false, userInfo: action.payload };
//     case USER_REGISTER_FAIL:
//       return { loading: false, error: action.payload };
//     default: return state;
//   }
// }
export {
    getAdminUsersReducer,
    getRolesReduces,
    addAdminUserReducer,
    getAppUsersReducer,
    loginUserReducer,
    editAdminUserReducer
}