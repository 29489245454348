import { axiosApi } from '../app/services/axios_api';
import { BASE_URL } from '../config';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const countryCitySlice = createSlice({
    name: 'countryCitySlice',
    initialState: {},
    reducers: {
        resetCountryCitySlice(state, action) {
            return {
                ...state,

            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCountries.pending, (state, action) => {
                return { ...state, countriesError: true, countries: undefined }
            })
            .addCase(getCountries.fulfilled, (state, action) => {


                return { ...state, countriesError: false, countries: action.payload }
            })
            .addCase(getCountries.rejected, (state, action) => {
                return {
                    countriesError: false,
                    error: action.payload,
                    countries: undefined
                }
            });
        builder
            .addCase(getCities.pending, (state, action) => {
                return { ...state, loading: true, cities: undefined }
            })
            .addCase(getCities.fulfilled, (state, action) => {


                return { ...state, loading: false, cities: action.payload }
            })
            .addCase(getCities.rejected, (state, action) => {
                return {
                    loading: false,
                    citiesError: action.payload,
                    cities: undefined

                }
            });
    },
});

export default countryCitySlice.reducer;
export const { resetcountryCitySlice } = countryCitySlice.actions;


// Thunks
export const getCountries = createAsyncThunk('doctorsCategory/fetch', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        //TODO: add url
        const url = BASE_URL + '';
        const { data } = await axiosApi.get(url);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});
export const getCities = createAsyncThunk('cities/fetch', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        //TODO: 
        const url = BASE_URL + '';
        const { data } = await axiosApi.get(url, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});


