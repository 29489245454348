import React, { useState } from 'react'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { primeIcons } from '../../../app/services/icon_service';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PageHeader from '../../../app/components/page_header';
import { confirmDialog } from 'primereact/confirmdialog';
import { useDeletePostCategoryMutation, useGetPostCategoriesQuery } from '../manager/posts_service';
import AddEditPostCategoryDialog from '../components/add_post_category_dialog';
import { FILE_BASE_URL } from '../../../config';

export default function PostsCategoryScreen() {

    const [editData, setEditData] = useState(null);

    const [showAddPostCategoryDialog, useAddPostCategoryDialog] = useState(false);

    var deleteId;

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const dialogFuncMap = {
        'showAddPostCategoryDialog': useAddPostCategoryDialog,

    }
    const [deletePostCategory] = useDeletePostCategoryMutation();


    const accept = () => {
        deletePostCategory(deleteId);
    }

    const reject = () => {
    }

    const deleteConfirmation = () => {
        confirmDialog({
            message: 'Do you want to delete?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };
    const { data: list, isLoading } = useGetPostCategoriesQuery();

    const history = useHistory();
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon={primeIcons['pi-pencil']} className="p-button-rounded p-button-success mr-2" onClick={() => {
                    setEditData(rowData);

                    onClick("showAddPostCategoryDialog")
                }} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning  mr-2" onClick={() => {
                    deleteId = rowData._id;
                    deleteConfirmation();
                }} />

            </React.Fragment>
        );
    }

    const categoryImageBody = (data) => {

        return <div>
            <img height='50px' width="auto" src={`${FILE_BASE_URL}${data.image}`} />
        </div>
    }

    return (

        <div className='card'>
            <PageHeader buttonTitle={"Add Post Category"} onClick={onClick} onHide={onHide} showDialog={showAddPostCategoryDialog} dialogHeader={"Post Category"} title={"Post Categories"} dialogHook={"showAddPostCategoryDialog"} buttonOnClick={() => {
                setEditData(null);
            }}>
                

                <AddEditPostCategoryDialog editData={editData} onHide={() => onHide('showAddPostCategoryDialog')} />


            </PageHeader>

            <DataTable value={list?.data} paginator className="p-datatable-gridlines" showGridlines rows={10}
                dataKey="id" filterDisplay="menu" loading={isLoading} responsiveLayout="scroll"
                emptyMessage="No data found.">
                <Column field="category" header="Name" style={{ minWidth: '12rem' }} />
                <Column field="image" header="Image" body={categoryImageBody} style={{ minWidth: '12rem' }} />

                <Column header="Action" body={actionBodyTemplate} style={{ minWidth: '12rem' }} />

            </DataTable>
        </div>
    )
}
