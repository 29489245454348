import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import CustomInputField from '../../../app/components/custom_input_field';
import { useAddDoctorMutation, useGetDoctorCitiesQuery, useUpdateDoctorMutation } from '../manager/doctors_services';
import toastService from '../../../app/services/toast_service';
import { useGetSpecializationQuery } from '../manager/specialization_services';
import appService from '../../../app/services/app_service';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function AddEditDoctorDialog({ editData, onHide }) {
    console.log("editData: ", editData);



    const [addDoctor, { isLoading: addLoading, error: addError, status: addStatus }] = useAddDoctorMutation();
    const [updateDoctor, { isLoading: editLoading, error: editError, status: updateStatus }] = useUpdateDoctorMutation();
    const { data: specList } = useGetSpecializationQuery();
    const { data: cities } = useGetDoctorCitiesQuery();

    const [contact, setContact] = useState({
        "type": "",
        "number": ""
    })
    const [contacts, setContacts] = useState([]);


    useEffect(() => {

        appService.showApiResponseStatus(
            {
                status: addStatus,
                msg: "Added successfully!",
                error: addError,
                successCB: () => {
                    formik.resetForm();
                    onHide();
                }
            }
        );
        appService.showApiResponseStatus(
            {
                status: updateStatus,
                msg: "Updated successfully!",
                error: editError,
                successCB: () => {
                    formik.resetForm();
                    onHide();
                }
            }
        );


    }, [addStatus, updateStatus]);


    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("First name is required."),
        lastName: Yup.string().required("Last name is required."),
        experience: Yup.number().required("Experience is required").min(1),
        qualification: Yup.string().required("Qualification name is required."),
        email: Yup.string().email().required("Email name is required."),
        city: Yup.string().required("City is required."),

        address: Yup.string().required("Addressis required."),
        bio: Yup.string().required("Bio is required."),
        phone: Yup.string()
        .required('Phone number is required.')
        .matches(/^\+[0-9]+$/, 'Phone number must start with "+" and contain only digits.'),
        lat: Yup.number()
        .required('Latitude is required.')
        .min(-90, 'Latitude must be greater than or equal to -90.')
        .max(90, 'Latitude must be less than or equal to 90'),
      
      lng: Yup.number()
        .required('Longitude is required.')
        .min(-180, 'Longitude must be greater than or equal to -180.')
        .max(180, 'Longitude must be less than or equal to 180'),




    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            "firstName": "",
            "lastName": "",
            "specialization": [],
            "experience": 0,
            "qualification": "",
            "email": "",
            // "country": "",
            "city": "",
            "address": "",
            // "status": "ACTIVE",
            // "isHomeAppointmentAvailable": true,
            "bio": "",
            "phone": "",
            "lat": 0,
            "lng": 0
            // "pricing": [],
            // "availibility":[],
            // "image": ""
        },
        onSubmit: async (data) => {


            if (editData === null) {
                let newData = {
                    "firstName": data.firstName,
                    "lastName": data.lastName,
                    "specialization": data.specialization,
                    "experience": data.experience,
                    "qualification": data.qualification,
                    "email": data.email,
                    "contact": contacts,
                    "city": data.city._id,
                    "address": {
                        "coordinates": {
                            "latitude": data.lat,
                            "longitude": data.lng
                        },
                        "address": data.address
                    },
                    "isActive": false,
                    "bio": data.bio,
                    "phone": data.phone

                };
             
                addDoctor(newData);
            } else {
                updateDoctor({
                    data:{...data},
                    "id": editData._id
                })
            }

        },
    });
 

    useEffect(() => {
        if (editData != null) { 
            console.log(editData);
            formik.setFieldValue("firstName", editData.firstName);
            formik.setFieldValue("lastName", editData.lastName);
 if (editData.city) {
    console.log("city", editData.city.name);
   formik.setFieldValue("city", editData.city._id);
 }            
setContacts(editData.contact)
        }
    }, [])

    const handleAddContact = (e) => {
        e.preventDefault();
        if (contact.number.length === 0 || contact.type.length === 0) {
            toastService.info("Please provide all the values")
            return;
        }
        setContacts((prev) => {
            return [...prev, contact];
        })
        setContact({
            type: "",
            "number": ""
        })
    }

    const contactBodyAction = (rowData, props) => {
        return (<React.Fragment>

            <Button icon="pi pi-trash" className="p-button-rounded p-button-warning  mr-2" onClick={() => {
                setContacts((prev) => {
                    prev.splice(props.rowIndex, 1);

                    return [...prev];
                });
            }} />

        </React.Fragment>);
    }
    return (
      <>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <div className="col-12 md:col-6\8">
              <div className=" p-fluid">
                <CustomInputField
                  type="text"
                  iden="firstName"
                  formik={formik}
                  placeHolder="Enter first name"
                />
                <CustomInputField
                  type="text"
                  iden="lastName"
                  formik={formik}
                  placeHolder="Enter last name"
                />
                <CustomInputField
                  type="multiSelect"
                  iden="specialization"
                  formik={formik}
                  options={specList?.data}
                  optionValue="_id"
                  optionLabel="name"
                  placeHolder="Select specializations"
                />
                <CustomInputField
                  type="number"
                  iden="experience"
                  formik={formik}
                  placeHolder="Enter your experience in years"
                />
                <CustomInputField
                  type="textArea"
                  iden="qualification"
                  formik={formik}
                  placeHolder="Qualification"
                />
                <CustomInputField
                  type="textArea"
                  iden="bio"
                  formik={formik}
                  placeHolder="Bio"
                />
                <CustomInputField
                  type="email"
                  iden="email"
                  formik={formik}
                  placeHolder="Email"
                />
                <CustomInputField
                  type="text"
                  iden="phone"
                  formik={formik}
                  placeHolder="Phone number to login to app"
                />
                <CustomInputField
                  type="textArea"
                  iden="address"
                  formik={formik}
                  placeHolder="Address"
                />
                <CustomInputField
                  type="dropdown"
                  iden="city"
                  formik={formik}
                  placeHolder="Choose city"
                  options={[
                    { _id: "default", name: "Select a city" }, // You can customize this default option
                    ...(cities?.data || []).map((city) => ({
                      _id: city._id,
                      name: city.name,
                    })),
                  ]}
                  optionLabel="name"
                  optionValue="_id"
                />
                <CustomInputField
                  type="number"
                  iden="lat"
                  formik={formik}
                  placeHolder="Latitude"
                />
                <CustomInputField
                  type="number"
                  iden="lng"
                  formik={formik}
                  placeHolder="Longitude"
                />

                <h4>Contacts</h4>
                <div className="p-fluid formgrid grid  justify-content-center align-content-center align-items-center">
                  <CustomInputField
                    type="text"
                    iden=""
                    formik={formik}
                    placeHolder="Type"
                    value={contact.type}
                    onChange={(e) =>
                      setContact((prev) => {
                        return {
                          ...prev,
                          type: e.target.value,
                        };
                      })
                    }
                    className="col-12 md:col-5"
                  />

                  <CustomInputField
                    type="text"
                    iden=""
                    formik={formik}
                    placeHolder="Number"
                    value={contact.number}
                    onChange={(e) =>
                      setContact((prev) => {
                        return {
                          ...prev,
                          number: e.target.value,
                        };
                      })
                    }
                    className="col-12 md:col-5"
                  />
                  <Button
                    className="inline-block"
                    style={{ width: "100px", height: "40px" }}
                    label="Add"
                    type="button"
                    onClick={handleAddContact}
                  />
                </div>
                <DataTable value={contacts}>
                  <Column field="type" header="Type" />
                  <Column field="number" header="Number" />
                  <Column header="Action" body={contactBodyAction} />
                </DataTable>

                <Button
                  loading={editData === null ? addLoading : editLoading}
                  type="submit"
                  label="Submit"
                  className="p-button-success"
                />
              </div>
            </div>
          </form>
        </div>
      </>
    );
}
