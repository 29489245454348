import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllGroomingList } from '../../../actions/appointments_actions';
import GroomingDetailsDialog from '../components/grooming_details_dialog';

export default function AllGroomingScreen() {
    const allGroomingList=useSelector((state)=>state.allGroomingList);
    const {loading,list}=allGroomingList;
    const dispatch=useDispatch();

    const [editGroomingDialog, setEditGroomingDialog] = useState(false);
    const [grooming, setGrooming] = useState(null);

    const dialogFuncMap = {
        'editGroomingDialog': setEditGroomingDialog,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);


    }


    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    useEffect(() => {
        dispatch(getAllGroomingList());
     
    }, [dispatch])


    const getActionBody = (row) => {
        return <div className='flex flex-row'>
            <Button icon="pi pi-arrow-up" className="p-button-rounded p-button-primary" onClick={() => {

                setGrooming(row);
                onClick('editGroomingDialog')
            }} aria-label="Edit" />

        </div>
    }
    
  return (
    <div>
          <Dialog position='bottom' header='Grooming' visible={editGroomingDialog} onHide={() => onHide('editGroomingDialog')}  breakpoints={{ '960px': '90vw', '640px': '100vw' }} style={{ width: '80vw' }}>
                <GroomingDetailsDialog isCentered={true} grooming={grooming} onHide={()=>onHide('editGroomingDialog')} />
            </Dialog>

        <div className='card'>
        <DataTable value={list} loading={loading} paginator className="p-datatable-gridlines" showGridlines rows={20}
    dataKey="id" filterDisplay="menu" responsiveLayout="scroll"
    emptyMessage="No data found.">
    <Column field="subCategory" header="Pet" filter filterPlaceholder="Search by slot" style={{ minWidth: '12rem' }} />
    <Column field="size" header="Size" filter filterPlaceholder="Search by description" style={{ minWidth: '12rem' }} />
    <Column field="package.price" header="Price" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
    <Column field="time" header="Time" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
    <Column field="user.firstName" header="First Name" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
    <Column field="user.lastName" header="Last Name" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
    <Column field="user.phone" header="Phone" filter filterPlaceholder="Search by price" style={{ minWidth: '12rem' }} />
    <Column field="status" header="Status" filter filterPlaceholder="Search by timing"  style={{ minWidth: '12rem' }} />
    <Column  header="Actions" body={getActionBody}  style={{ minWidth: '12rem' }} />



</DataTable>

        </div>
    </div>
  )
}
