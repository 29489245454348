import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import CustomInputField from '../../../app/components/custom_input_field';
import toastService from '../../../app/services/toast_service';
import appService from '../../../app/services/app_service';
import { useAddPostCategoryMutation, useUpdatePostCategoryMutation } from '../manager/posts_service';
import { InputText } from 'primereact/inputtext';

export default function AddEditPostCategoryDialog({ editData, onHide }) {



    const [addPostCategory, { isLoading: addLoading, error: addError, status: addStatus }] = useAddPostCategoryMutation();
    const [updatePostCategory, { isLoading: editLoading, isError: editSuccess, error: editError, status: updateStatus }] = useUpdatePostCategoryMutation();

    const [image, setImage] = useState();

    useEffect(() => {
        appService.showApiResponseStatus(
            {
                status: addStatus,
                msg: "Added successfully!",
                error: addError,
                successCB: () => {
                    formik.resetForm();
                    onHide();
                }
            }
        );
        appService.showApiResponseStatus(
            {
                status: updateStatus,
                msg: "Updated successfully!",
                error: editError,
                successCB: () => {
                    formik.resetForm();
                    onHide();
                }
            }
        );



    }, [addError, editSuccess, editError, addStatus, updateStatus]);



    const validationSchema = Yup.object().shape({
        category: Yup.string().required("Category is required.")

    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {

            "category": "",




        },

        onSubmit: async (data) => {
            console.log(data);


            if (editData == null) {
                if (image == null) {
                    toastService.info("Please choose an image");
                    return;
                }
                let file = await readFileAsDataURL(image);
                addPostCategory({
                    ...data,
                    image: file
                });
                // dispatch(addCompany(data));

            } else {
                var file = undefined;
                if (image) {
                    file = await readFileAsDataURL(image);
                }

                updatePostCategory({
                    category: data.category,
                    "id": editData._id,
                    "image": file
                })
                // data['userId']=editUser._id;
                // dispatch(editCompany(data));

            }

        },
    });

    async function readFileAsDataURL(file) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });



        return result_base64;
    }


    useEffect(() => {
        console.log(editData);
        if (editData != null) {
            formik.setFieldValue("category", editData.category);
        }
    }, [])



    return (
        <>
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="col-12 md:col-6\8">
                        <div className=" p-fluid">
                            <CustomInputField type='text' placeHolder="Name" iden='category' formik={formik} />
                            <InputText
                                className='mb-3'
                                accept={"image/png, image/jpeg, image/jpg"}
                                type='file' 
                                onChange={(e) => setImage(e.target.files[0])} 
                                
                                placeHolder="Category Image" />
                            <Button loading={editData == null ? addLoading : editLoading} type='submit' label='Submit' className='p-button-success' />

                        </div>
                    </div>

                </form>
            </div>
        </>
    )
}
