const CryptoJS = require("crypto-js");

const cryptkey = CryptoJS.enc.Utf8.parse("43kl5kl34j5kl34j5lk43nn5k34n5lk34j5kl3j45");

const cryptiv = CryptoJS.enc.Utf8.parse("KL435K3L3JLKJCLJ5CJJCLK5LK6L456Kkjltl4k5j45l3");

// Encryption
// const data = JSON.stringify({ test: "123" });

module.exports = (data) => {
  const crypted = CryptoJS.enc.Base64.parse(data.toString()); //"Zt8VfHQqiKj/MToZGwWppw==");

  var decrypt = CryptoJS.AES.decrypt({ ciphertext: crypted }, cryptkey, {
    iv: cryptiv,
    mode: CryptoJS.mode.CTR,
  });
  const data2 = decrypt.toString(CryptoJS.enc.Utf8);
  if (data2) return JSON.parse(data2);
  return null;
};
