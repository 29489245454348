import React, { useState } from 'react'
import { useDeleteSpecializationMutation, useGetSpecializationQuery } from '../manager/specialization_services'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { primeIcons } from '../../../app/services/icon_service';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PageHeader from '../../../app/components/page_header';
import AddEditDoctorSpecializationDialog from '../components/add_edit_specilization_dialog';
import {  confirmDialog } from 'primereact/confirmdialog';

export default function DoctorSpecializationScreen() {

    const [editData, setEditData] = useState(null);

    const [showAddSpecializationDialog, setAddSpecializationDialog] = useState(false);

    var deleteId;

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const dialogFuncMap = {
        'showAddSpecializationDialog': setAddSpecializationDialog,

    }
    const [deleteSpecialization ] = useDeleteSpecializationMutation();
    // const [deleteSpecialization, { isLoading: deleteLoading, isError: deleteIsError, error: editError, status: deleteStatus }] = useDeleteSpecializationMutation();


    const accept = () => {
        deleteSpecialization(deleteId);
    }

    const reject = () => {
    }

    const deleteConfirmation = () => {
        confirmDialog({
            message: 'Do you want to delete?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };
    const { data : list, isLoading } = useGetSpecializationQuery();
 
    const history = useHistory();
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon={primeIcons['pi-pencil']} className="p-button-rounded p-button-success mr-2" onClick={()=>{
                     setEditData(rowData);
                 
                     onClick("showAddSpecializationDialog")
                }} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning  mr-2" onClick={() => {
                   deleteId = rowData._id;
                   deleteConfirmation();
                }} />
             
            </React.Fragment>
        );
    }

 
    return (
   
    <div className='card'>
          <PageHeader buttonTitle={"Add Specialization"} onClick={onClick} onHide={onHide} showDialog={showAddSpecializationDialog} dialogHeader={"Specializatoin"} title={"Specializatoin"} dialogHook={"showAddSpecializationDialog"} buttonOnClick={() => {
                setEditData(null);
            }}>

<AddEditDoctorSpecializationDialog editData={editData} onHide={() => onHide('showAddSpecializationDialog')} />


            </PageHeader>

    <DataTable value={list?.data} paginator className="p-datatable-gridlines" showGridlines rows={10}
        dataKey="id" filterDisplay="menu" loading={isLoading} responsiveLayout="scroll"
        emptyMessage="No data found.">
                    <Column field="name" header="Name" style={{ minWidth: '12rem' }} />

        <Column header="Action" body={actionBodyTemplate} style={{ minWidth: '12rem' }} />

    </DataTable>
</div>
  )
}
