import { ProgressSpinner } from 'primereact/progressspinner';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { getCenterDetails } from '../../../actions/users_actions';
import CatBoardingTab from '../componenets/cat_boarding_tab';
import CatGroomingTab from '../componenets/cat_grooming_tab';
import DogBoardingTab from '../componenets/dog_boarding_tab';
import DogGroomingTab from '../componenets/dog_grooming_tab';
import OffDaysTab from '../componenets/off_days_tab';
import ServicesTab from '../componenets/services_tab';
import TimingsTabs from '../componenets/timings_tab';
export default function CenterScreen(props) {

    const getCenterDetailsReducer=useSelector((state)=>state.centerDetails);
    const {center,loading}=getCenterDetailsReducer;

    const params=useParams();
    const {id}=params;
    const dispatch=useDispatch();


    useEffect(() => {
      
        dispatch(getCenterDetails(id));
    
      return () => {
     
      }
    }, [dispatch,id])
    
  return (
    
    
   <>
    {
        (loading===true||center===undefined)? <ProgressSpinner />:
        <div className="card">
                <h5>{center.centre.name}</h5>
                
                <TabView>
                    <TabPanel header="Off Days">
                      <OffDaysTab offDays={center.centre.offDays}/>
                    </TabPanel>
                    <TabPanel header="Services">
                   <ServicesTab services={center.centre.services}/>
                    </TabPanel>
                    <TabPanel header="Dog grooming">
                        <DogGroomingTab groomings={center.grooming.dogGrooming}/>
                     
                    </TabPanel>
                    <TabPanel header="Cat grooming">
                    <CatGroomingTab groomings={center.grooming.catGrooming}/>
                     
                    </TabPanel>
                    <TabPanel header="Dog boarding">
                        <DogBoardingTab boardings={center.boarding.dogBoarding}/>
                     
                    </TabPanel>
                    <TabPanel header="Cat boarding">
                    <CatBoardingTab boardings={center.boarding.catBoarding}/>
                     
                    </TabPanel>
                    <TabPanel header="Timings">
                        <TimingsTabs timings={center.centre.timeSlots}/>
                     
                    </TabPanel>
                </TabView>
            </div>
    }
   </>
   
  )
}
