import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useGetDoctorsAppointmentsQuery } from '../manager/doctors_services';
import { formatDate } from '../../../app/services/time_format_service';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
    import { Rating } from "primereact/rating";
import { Button } from 'primereact/button';
import { primeIcons } from '../../../app/services/icon_service';
import moment from 'moment';
import { identity } from '@fullcalendar/core';
import { Dialog } from "primereact/dialog";
import UserDetailsDialog from '../manager/user_details.dialog';
import PageHeader from '../../../app/components/page_header';
export default function DoctorAppointmentsScreen() {
  const currentDate =new Date();
  // Calculate 15 days before the current date
  const nextDate = new Date(currentDate);
  nextDate.setDate(currentDate.getDate() + 15);
  console.log("nextDate: ", nextDate);
  const prevDate=new Date(currentDate)
  prevDate.setDate(prevDate.getDate()-15)
  console.log("prevDate: ", prevDate);
    const [startDate, setStartDate] = useState(prevDate);
    const [endDate, setEndDate] = useState(nextDate);
     const [displayDialog, setDisplayDialog] = useState(false);
     const [selectedUser, setSelectedUser] = useState(null);
    const [showAddDoctorDialog, setShowAddEditDialog] = useState(false);
     const onClick = (name, position) => {
       dialogFuncMap[`${name}`](true);
     };
     const onHide = (name) => {
       dialogFuncMap[`${name}`](false);
     };

    const handleStartDateChange = (date) => {
        setStartDate(date);
      };
    
      const handleEndDateChange = (date) => {
        setEndDate(date);
      };
        const history = useHistory();
 const [showDetails, setShowDetails] = useState(false);

 const toggleDetails = () => {
   setShowDetails(!showDetails);
 };
    const params = useParams();
    const {id} = params;
const { data, isLoading, error, isError, refetch } =
  useGetDoctorsAppointmentsQuery({
    id: id,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  });
  const dialogFuncMap = {
    "showAddDoctorDialog": setShowAddEditDialog,
  };
    useEffect(() => {
      if (data && startDate && endDate) {
        refetch();
      }
    }, [startDate, endDate, data, refetch]);

function RatingTemplate({ value }) {
  return (
    <div className="card flex justify-content-center">
      <Rating
        value={value}
        cancel={false}
        onIcon={
          <img
            src="https://primefaces.org/cdn/primereact/images/rating/custom-icon-active.png"
            alt="custom-image-active"
            width="25px"
            height="25px"
          />
        }
        offIcon={
          <img
            src="https://primefaces.org/cdn/primereact/images/rating/custom-icon.png"
            alt="custom-image"
            width="25px"
            height="25px"
          />
        }
      />
    </div>
  );
}
const actionBodyTemplate = (appointment) => {
  return (
    <React.Fragment>
      <Button
        icon={primeIcons["pi-money-bill"]}
        tooltip="User Details"
        className="p-button-rounded p-button-warning m-1"
        onClick={() => {
          setSelectedUser(appointment);
          onClick("showAddDoctorDialog");
        }}
      />
    </React.Fragment>
  );
};
  const ratingBodyTemplate = (rowData) => {
    // Assuming your data has a 'rating' property
    const ratingValue = rowData["rating"];
    return (
      <RatingTemplate value={ratingValue} />
    );
    }

  return (
    <div className="card">
      <PageHeader
        buttonTitle={"User Details"}
        onClick={onClick}
        onHide={onHide}
        showDialog={showAddDoctorDialog}
        dialogHeader={"User Details"}
        title={"User"}
        dialogHook={"showAddDoctorDialog"}
        buttonOnClick={() => {
          setSelectedUser(null);
        }}
        showButton={false}
      >
        <UserDetailsDialog
          selectedUser={selectedUser}
          onHide={() => onHide("showAddDoctorDialog")}
        />
      </PageHeader>
      <h3>Appointments</h3>
      <div className="flex justify-content-end my-2">
        <div>
          <label>Start Date:</label>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
        </div>
        <div className="ml-2">
          <label>End Date:</label>
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </div>
      </div>

      <DataTable
        value={data?.data}
        paginator
        className="p-datatable-gridlines"
        showGridlines
        rows={10}
        dataKey="id"
        filterDisplay="menu"
        loading={isLoading}
        responsiveLayout="scroll"
        emptyMessage="No data found."
      >
        <Column
          field="petName"
          header="Pet Name"
          style={{ minWidth: "12rem" }}
        />
        <Column field="type" header="Type" style={{ minWidth: "12rem" }} />
        <Column field="amount" header="amount" style={{ minWidth: "12rem" }} />
        <Column
          field="appointmentType"
          header="appointmentType"
          style={{ minWidth: "12rem" }}
        />
        <Column field="status" header="Status" style={{ minWidth: "12rem" }} />
        <Column
          field="rating"
          header="Rating"
          body={ratingBodyTemplate}
          style={{ minWidth: "16rem" }}
        />
        <Column
          header="Action"
          body={actionBodyTemplate}
          style={{ minWidth: "8rem" }}
        />
      </DataTable>
    </div>
  );
  }

