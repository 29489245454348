import { axiosApi } from "../app/services/axios_api";
import { ADD_CENTER_FAILURE, ADD_CENTER_REQUEST, ADD_CENTER_SUCCESS, EDIT_CENTER_DATA_FAILURE, EDIT_CENTER_DATA_REQUEST, EDIT_CENTER_DATA_SUCCESS, EDIT_CENTER_FAILURE, EDIT_CENTER_REQUEST, EDIT_CENTER_SUCCESS, Get_CENTERS_LIST_FAILURE, Get_CENTERS_LIST_REQUEST, Get_CENTERS_LIST_SUCCESS } from "../constants/centers_constants";
import { BASE_URL } from './../config';
const getCentersList = () => async (dispatch, getState) => {
    dispatch({ type: Get_CENTERS_LIST_REQUEST });

    const url = BASE_URL + 'api/centre/web/list';

    
    try {
        const { data } = await axiosApi.get(url,
            );
        dispatch({ type: Get_CENTERS_LIST_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: Get_CENTERS_LIST_FAILURE, payload: error.response && error.response.data.msg
                ? error.response.data.msg
                : error.message
        });
    }
}
const addCenter = (body) => async (dispatch, getState) => {
    dispatch({ type: ADD_CENTER_REQUEST });

    const url = BASE_URL + 'api/centre';

    try {
        const { data } = await axiosApi.post(url,
            body
        );
        dispatch({ type: ADD_CENTER_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: ADD_CENTER_FAILURE, payload: error.response && error.response.data.msg
                ? error.response.data.msg
                : error.message
        });
    }
}
const editCenter = (body) => async (dispatch, getState) => {
    dispatch({ type: EDIT_CENTER_REQUEST });

    const url = BASE_URL + 'api/centre';

    try {
        const { data } = await axiosApi.put(url,
            body
        );
        dispatch({ type: EDIT_CENTER_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: EDIT_CENTER_FAILURE, payload: error.response && error.response.data.msg
                ? error.response.data.msg
                : error.message
        });
    }
}
const editCenterData = (body) => async (dispatch, getState) => {
    dispatch({ type: EDIT_CENTER_DATA_REQUEST });

    const url = BASE_URL + 'api/centre/data';

    try {
        const { data } = await axiosApi.put(url,
            body
        );
        dispatch({ type: EDIT_CENTER_DATA_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: EDIT_CENTER_DATA_FAILURE, payload: error.response && error.response.data.msg
                ? error.response.data.msg
                : error.message
        });
    }
}

export {getCentersList,addCenter,editCenter,editCenterData}